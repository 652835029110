import { Injectable} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginModalComponent } from './login-modal/login-modal.component';

@Injectable({
    providedIn: 'root'
  })
  export class LoginModalService {
    bsModalRef: BsModalRef;

    constructor(public modalService: BsModalService) { }

    viewLoginModal() {
       this.modalService.show(LoginModalComponent, { class: 'modal-lg', ignoreBackdropClick: true });
      }
  }
