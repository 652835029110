import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { faEdit  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-table-edit-button',
  templateUrl: './table-edit-button.component.html',
  styleUrls: ['./table-edit-button.component.scss']
})
export class TableEditButtonComponent implements OnInit {
  faEdit = faEdit;
  @Output() click: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
