import { Component, OnInit } from '@angular/core';
import { faCheck, faTimes, faDownload, faMoneyCheck, faTasks } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../../auth/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DebtorsTransactionService } from '../../../../api/services/debtors-transaction.service';
import * as FileSaver from 'file-saver';
import { AppSettingsService } from '../../../../services/app-settings.service';
import { JobService } from '../../../../api/services/job.service';
import { NavigationService } from '../../../../services/navigation.service';
import { DebtorsTransactionPagedSettings } from '../../../../api/models/debtors-transaction-paged-settings';

@Component({
  selector: 'app-transactionDetails',
  templateUrl: './transactionDetails.component.html',
  styleUrls: ['./transactionDetails.component.scss']
})
export class TransactionDetailsComponent implements OnInit {
  adminAccess = false;
  faMoneyCheck = faMoneyCheck;
  faCheck = faCheck;
  faTimes = faTimes;
  faTasks = faTasks;
  faDownload = faDownload;
  transactions;
  debtorRef = 0;
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  transactionRef = null;
  logoUrl = '';
  jobRef = 0;
  jobResponse;
  jobId = 0;

  constructor(
    private debtorService: DebtorsTransactionService, // debtorTransactions Service
    private authService: AuthService,
    private toastr: ToastrService,
    private navigateTo: NavigationService,
    private activatedRoute: ActivatedRoute,
    private appSettings: AppSettingsService,
    private jobService: JobService
  ) {

  }

  ngOnInit(): void {
    this.listenForRouteParams();
  }

  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.transactionRef = params.id;
        this.loadTransactionDetails(params.id);
      }
    });
  }

  loadTransactionDetails(transactionRef: string) {
    const params: DebtorsTransactionService.GetDebtorsTransactionsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      transactionRef: transactionRef
    };
    this.debtorService.GetDebtorsTransactionsPagedAsync(params).subscribe(response => {
      this.transactions = response.items;
    });
  }

  downloadTransactionDetailsPDF() {
    const params: DebtorsTransactionService.GenerateDebtorsTransactionPDFParams = {
      Authorization: '',
      modelIn: this.transactions,
      logoUrl: this.appSettings.getLynchLogo,
    };
    this.debtorService.GenerateDebtorsTransactionPDF(params).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
      FileSaver.saveAs(blob, `Transaction-${this.transactionRef}.pdf`);
      this.toastr.success('Transaction PDF ' + this.transactionRef, 'Download Successful');
    });
  }

  loadJobByJobRef(jobRef: string) {
    const params: JobService.GetJobByJobRefAsyncParams = {
      Authorization: '',
      jobRef: jobRef,
    };
    this.jobService.GetJobByJobRefAsync(params).subscribe(response => {
      this.jobResponse = response;
      this.jobId = response.id;
      this.jobDetailsForInvoice(this.jobId);
    });
  }

  jobDetailsForInvoice(jobId: number) {
    this.navigateTo.viewJobDetails(jobId);
  }
}
