import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { AccountService } from '../../../api/services/account.service';
import { UpdatePasswordModelIn } from '../../../api/models/update-password-model-in';
import { Validators } from '@angular/forms';
import { CustomValidators } from '@allsop/angular-common';
import { ConfirmPasswordValidator } from '../../../common/validators/mustMatchValidator';

export class UpdatePasswordForm extends AllsopBaseForm {

  constructor() {
    super();

    this.setUpForm();
  }
  public setUpForm() {
    this.resetForm();

    const model: any = {
      password: '',
      confirmPassword: '',
    };

    this.setUpFormForEdit(model);
  }

  public setUpFormForEdit(value: UpdatePasswordModelIn) {
    this.resetForm();

    this.formGroup = this.fb.group<UpdatePasswordModelIn>({
      password: this.getControl(value.password),
      confirmPassword: this.getControl(value.confirmPassword),
    },
      { validator: ConfirmPasswordValidator('password', 'confirmPassword') }
    );


  }

  getSaveModel(userId: string): AccountService.UpdatePasswordAsyncParams {
    const value: UpdatePasswordModelIn = this.formGroup.value;
    return {
      Authorization: '',
      userId: userId,
      model: {
        password: value.password,
        confirmPassword: value.confirmPassword
      }
    };
  }
}
