import { Component, OnInit } from '@angular/core';
import { faCheck, faTimes, faDownload, faMoneyCheck, faTasks } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../auth/services/auth.service';
import { DebtorsTransactionModelOut } from '../../../api/models/debtors-transaction-model-out';
import { DebtorsTransactionService } from '../../../api/services/debtors-transaction.service';
import { NavigationService } from '../../../services/navigation.service';
import { PropertyModelOut } from '../../../api/models/property-model-out';

@Component({
  selector: 'app-financial-details',
  templateUrl: './financial-details.component.html',
  styleUrls: ['./financial-details.component.scss']
})
export class FinancialDetailsComponent implements OnInit {

  adminAccess = false;
  faMoneyCheck = faMoneyCheck;
  faCheck = faCheck;
  faTimes = faTimes;
  faTasks = faTasks;
  faDownload = faDownload;
  debtorTransactionsReference = 0;
  jobRef = '';
  financialInformation: Array<DebtorsTransactionModelOut>;
  outstandingInvoices:  Array<DebtorsTransactionModelOut>;
  paidInvoices:  Array<DebtorsTransactionModelOut>;
  job;
  financiaId = 0;
  jobs;
  jobId = '';
  property: PropertyModelOut;
  debtorTransactionsId = '';
  transactionId = '';
  debtorId = 0;
  debtorRef = '';
  transactionRef = '';
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  name = '';
  reference = '';
  propertyRef = null;
  allChecked = false;
  debtorTransactions;
  constructor(
    private debtorService: DebtorsTransactionService,

    private authService: AuthService,

    private navigateTo: NavigationService ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
  }
  ngOnInit() { }

  loadFinancialDetails(debtorId: number) {
    const params: DebtorsTransactionService.GetDebtorsTransactionByIdAsyncParams = {
      Authorization: '',
      debtorsTransactionId: debtorId,
    };
    this.debtorService.GetDebtorsTransactionByIdAsync(params).subscribe(response => {
      this.debtorTransactions = response;
      this.debtorRef = response.debtorRef;
    });
  }

  viewTransactionDetails(transactionRef: number) {
    this.navigateTo.transactionDetails(transactionRef);
  }
}
