import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { faCheck, faTimes, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import { PropertyService } from './../../../api/services/property.service';
import { ActivatedRoute } from '@angular/router';
import { PropertyModelOut } from '../../../api/models/property-model-out';
import { DebtorsTransactionModelOut } from '../../../api/models/debtors-transaction-model-out';
import { DebtorsTransactionService } from '../../../api/services/debtors-transaction.service';
import { JobService } from '../../../api/services/job.service';


@Component({
  selector: 'app-property-financial-info',
  templateUrl: './property-financial-info.component.html',
  styleUrls: ['./property-financial-info.component.scss']
})
export class PropertyFinancialInfoComponent implements OnInit {
  faMoneyCheck = faMoneyCheck;
  faCheck = faCheck;
  faTimes = faTimes;
  propertyId = 0;
  financeList = [];
  jobRef = 0;
  jobId = 0;
  projectRef = '';
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  name = '';
  jobResponse;
  reference = '';
  property: PropertyModelOut;
finance: DebtorsTransactionModelOut;
  constructor(
    private debtorService: DebtorsTransactionService,
    private propertyService: PropertyService,
    private jobService: JobService,
    private navigateTo: NavigationService,
    private activatedRoute: ActivatedRoute
  ) { }
  ngOnInit() {
    this.listenForRouteParams();
}


listenForRouteParams() {
  this.activatedRoute.params.subscribe(params => {
    this.propertyId = params.id;
    this.loadPropertyReference(params.id);
  });
}
  resetFilters() {
    this.name = '';
    this.loadDebtorsTransations(this.projectRef);
  }
  search(name) {
    this.name = name;
    this.loadDebtorsTransations(this.projectRef);
  }

  loadPropertyReference(projectRef: string) {
    const params: PropertyService.GetPropertyByProjectRefAsyncParams = {
      Authorization: '',
      projectRef : projectRef,
    };
    this.propertyService.GetPropertyByProjectRefAsync(params).subscribe(response => {
      this.property = response;
      this.projectRef = response.projectRef;
    });
}

loadDebtorsTransations(projectRef) {
      const params: DebtorsTransactionService.GetDebtorsTransactionsPagedAsyncParams = {
        Authorization: '',
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        projectRef: projectRef
      };
      this.debtorService.GetDebtorsTransactionsPagedAsync(params).subscribe(response => {
        this.totalRecordCount = response.totalRecordCount;
        this.financeList = response.items;
      });
  }

  loadJobByJobRef(jobRef: string) {
    const params: JobService.GetJobByJobRefAsyncParams = {
      Authorization: '',
      jobRef: jobRef,
    };
    this.jobService.GetJobByJobRefAsync(params).subscribe(response => {
      this.jobResponse = response;
      this.jobId = response.id;
      this.jobDetailsForInvoice(this.jobId);
    });
  }

  jobDetailsForInvoice(jobId: number) {

    this.navigateTo.viewJobDetails(jobId);
  }

  transactionDetails(transactionRef: number) {
    this.navigateTo.transactionDetails(transactionRef);
  }

  pageChanged(page) {
    this.currentPage = page;
    this.loadDebtorsTransations(this.projectRef);
  }
}
