import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { PropertyFilters } from '../models/property-filters';

export class PropertyFiltersForm extends AllsopBaseForm {
    constructor() {
        super();

        this.setUpForm();
    }
    public setUpForm() {
        const model: PropertyFilters = {
            keywords: '',
            properties: '',
        };

        this.setupForm(model);
    }

    public setupForm(value: PropertyFilters) {
        this.resetForm();
        this.formGroup = this.fb.group<PropertyFilters>({
            keywords: this.getControl(value.keywords, false),
            properties: this.getControl(value.properties, false),
        },
        );
    }
}


