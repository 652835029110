import { Component, Inject } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { DependentConfigSettings } from './dependent-config';
import { APP_CONFIG, ConfigSettings } from './config';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  globalSettings: ConfigSettings ;
  dependentSettings: DependentConfigSettings;
  constructor(@Inject(APP_CONFIG) settings: ConfigSettings,
    private appInsights: ApplicationInsights) {

    this.globalSettings = settings;

    this.appInsights = new ApplicationInsights( {
      config: {
        instrumentationKey: this.globalSettings.applicationInsightsApiKey,
        enableAutoRouteTracking: true
      }
    });

    this.appInsights.loadAppInsights();
  }
}
