import * as moment from 'moment';
import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { EstimateFilters } from '../models/estimate-filters';

export class EstimateFiltersForm extends AllsopBaseForm {
    constructor() {
        super();

        this.setUpForm();
    }

    public setUpForm() {
        const model: EstimateFilters = {
            startDate: null,
            endDate: null
        };

        this.setupForm(model);
    }

    public setupForm(value: EstimateFilters) {
        this.resetForm();
        let sDate = new Date();
        sDate = (moment(sDate).subtract(1, 'years')).toDate();

        this.formGroup = this.fb.group<EstimateFilters>({
            startDate: this.getControl(sDate, false),
            endDate: this.getControl(new Date().getDate(), false)
        },
        );
    }
}
