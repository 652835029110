import { Injectable, OnInit, Inject } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { HttpErrorResponse } from '@angular/common/http';
import { AppSettingsService } from './app-settings.service';
import { DependentConfigSettings } from '../dependent-config';
import { ConfigSettings, APP_CONFIG } from '../config';
import { AlertsService } from './alert.service';

interface WebApiError {
  ErrorCode: string;
  Message: string;
}

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  erroPrefix = 'WEB ';
  logToConsole = true;

  globalSettings: ConfigSettings;
  dependentSettings: DependentConfigSettings;

  constructor(@Inject(APP_CONFIG) settings: ConfigSettings,
    private alertService: AlertsService,
    private appInsights: ApplicationInsights,
    dependentSettings: DependentConfigSettings) {


    this.globalSettings = settings;
    this.dependentSettings = dependentSettings;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.globalSettings.applicationInsightsApiKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.logPageNotFound('tresetse');
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }
  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name, average }, properties);
  }
  logWebException(apiError: WebApiError, severityLevel?: number) {
    apiError.Message = this.getErrorMessage(apiError.Message);
    this.alertService.error(apiError.Message);
    const error = new Error(apiError.Message);
    this.appInsights.trackException({ error, severityLevel });
  }
  logHttpException(message: string, exception: HttpErrorResponse, severityLevel?: number) {
    message = this.getErrorMessage(message);
    this.alertService.error(message);
    console.log(message);
    this.appInsights.trackException({ exception, severityLevel });
  }
  logException(exception: Error, severityLevel?: number) {
    exception.message = this.getErrorMessage(exception.message);
    this.alertService.error(exception.message);
    this.writeToConsole(exception.message);
    this.appInsights.trackException({ exception, severityLevel });
  }
  logTrace(message: string, properties?: { [key: string]: any }) {
    message = this.getErrorMessage(message);
    this.writeToConsole(message);
    this.appInsights.trackTrace({ message }, properties);
  }
  logPageNotFound(pageName: string) {
    this.appInsights.trackEvent({ name }, { key: pageName });
  }

  getErrorMessage(message) {
    return this.erroPrefix + ' ' + message;
  }
  writeToConsole(message: string) {
    if (this.logToConsole) {
      console.log(message);
    }
  }
}
