import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../../api/services/account.service';
import { NavigationService } from '../../../../services/navigation.service';
import { DropdownModelOutString } from '../../../../api/models/dropdown-model-out-string';
import { AlertsService } from '../../../../services/alert.service';
import { CreateUserForm } from '../createUsersForm';
import { noop, Observable, Observer, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { AnalysisCodesPagedModelOut } from '../../../../api/models/analysis-codes-paged-model-out';
import { DropdownModelOutInt32 } from '../../../../api/models/dropdown-model-out-int-32';


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  userForm: CreateUserForm;
  isSubmitted = false;
  roles: Array<DropdownModelOutString>;
  reportGroupIds: Array<DropdownModelOutInt32>;
  suggestions$: Observable<AnalysisCodesPagedModelOut[]>;
  errorMessage: string;
  hideCodeList = true;
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;

  constructor(
    private userService: AccountService,
    private analysisService: AccountService, // Analysis Code service held in Account Controller on API
    private navigateTo: NavigationService,
    private alertService: AlertsService) {

    this.userForm = new CreateUserForm();
    this.userForm.setUpForm();
  }

  ngOnInit() {
    this.loadRoles();
    this.loadReportGroups();

    this.suggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.userForm.form.value.analysisCode);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          const params: AccountService.GetAnalysisCodesPagedAsyncParams = {
            Authorization: '',
            pageNo: this.currentPage,
            pageSize: this.pageSize,
            code: this.userForm.form.value.analysisCode

          };

          return this.analysisService.GetAnalysisCodesPagedAsync(params)
            .pipe(
              map((data: AnalysisCodesPagedModelOut) => data.items.map(
                (s) => ({ analysisCode: s.code })) || []),
              tap(() => noop, err => {
                this.errorMessage = err && err.message || 'Unable to find matching analysis code';
              })
            );
        }

        this.alertService.error('Unable to find matching analysis code');

        return of([]);
      })
    );
  }
  loadRoles() {
    this.userService.GetRoles('').subscribe(response => {
      this.roles = response;
    });
  }

  loadReportGroups() {
    this.userService.GetReportGroupsDropdownAsync('').subscribe(response => {
      this.reportGroupIds = response;
    });
  }

  onSelect(event: TypeaheadMatch): void {
    this.userForm.form.value.analysisCode = event.item.analysisCode;
  }

  roleChange(e) {
    this.displayCodes(e.target.value);
  }

  displayCodes(rolename) {
    if (rolename === 'Customer') {
      this.hideCodeList = false;
    } else {
      this.hideCodeList = true;
    }
  }

  save() {
    this.userForm.isSubmitted = true;

    if (this.userForm.form.valid) {

      if (this.userForm.form.value.roleId === 'Customer' && this.userForm.form.value.analysisCode === null) {
        this.alertService.error('Please select a matching Analysis Code');
      } else {
        const saveModel = this.userForm.getSaveModel();

        this.userService.CreateUserAsync(saveModel).subscribe(response => {
          this.alertService.success('');
          this.navigateTo.users();
        });
      }
    }
  }
  back() {
    this.navigateTo.users();
  }
}
