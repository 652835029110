import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { DebtorTransationFilters } from '../finance-filters/models/debtor-finance-filters';

export class DebtorFiltersForm extends AllsopBaseForm {
    constructor() {
        super();

        this.setUpForm();
    }
    public setUpForm() {
        const model: DebtorTransationFilters = {
            keywords: '',
            transactionStatus: '',
            startDate: null,
            endDate: null
        };

        this.setupForm(model);
    }

    public setupForm(value: DebtorTransationFilters) {
        this.resetForm();
        this.formGroup = this.fb.group<DebtorTransationFilters>({
            keywords: this.getControl(value.keywords, false),
            transactionStatus: this.getControl(value.transactionStatus, false),
            startDate: this.getControl(null, false),
            endDate: this.getControl(null, false)
        },
        );
    }
}


