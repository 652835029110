import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalSettingsService  implements OnInit  {
  _accessTokenKey = 'access_token';
  _refreshTokenKey = 'refresh_token';


  constructor() { }
  ngOnInit() {
    console.log('hello component init : name');
  }
  get AccessToken(): string {
    const value = localStorage.getItem(this._accessTokenKey);
    return (value) ? value : '';
  }

  set AccessToken(val) {
    localStorage.setItem(this._accessTokenKey, val);
  }

  get RefreshToken() {
    const value = localStorage.getItem(this._refreshTokenKey);
    return (value) ? value : '';
  }

  set RefreshToken(val) {
    localStorage.setItem(this._refreshTokenKey, val);
  }
  setTokens(accessToken: string, refreshToken: string) {
    this.AccessToken = accessToken;
    this.RefreshToken = refreshToken;
  }
  clearTokens() {
    this.AccessToken = '';
    this.RefreshToken = '';
  }
}
