import { Component, OnInit } from '@angular/core';
import { AssetService } from '../../../api/services/asset.service';
import { faCheck, faTimes, faFlag } from '@fortawesome/free-solid-svg-icons';
import { PropertyService } from './../../../api/services/property.service';
import { ActivatedRoute } from '@angular/router';
import { PropertyModelOut } from '../../../api/models/property-model-out';
import { AssetFilters } from '../../assets/models/asset-filters';
import { AssetModelOut } from '../../../api/models/asset-model-out';
import { AssetModalService } from '../../assets/asset.modal.services';
@Component({
  selector: 'app-property-assets',
  templateUrl: './property-assets.component.html',
  styleUrls: ['./property-assets.component.scss']
})
export class PropertyAssetsComponent implements OnInit {
  showPropertyRefFilter = false;
  assetsAvailable = false;
  faFlag = faFlag;
  faCheck = faCheck;
  faTimes = faTimes;
  propertyId = 0;
  assetsList: Array<AssetModelOut>;
  projectRef = '';
  totalAssetRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  reference = '';
  property: PropertyModelOut;
  assetFilters: AssetFilters = {
    keywords: '',
    projectRef: '',
    completedDate: null,
    assetCategory: '',
  };
  constructor(
    private propertyService: PropertyService,
    private assetService: AssetService,
    private activatedRoute: ActivatedRoute,
    private modalService: AssetModalService,
  ) { }

  ngOnInit() {
    this.listenForRouteParams();
  }

  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      this.projectRef = params.id;
      this.loadPropertyReference(params.id);
    });
  }

  filterAssets(assetFilters: AssetFilters) {
    this.assetFilters = assetFilters;
    this.loadAssetData(this.projectRef);
  }
  loadPropertyReference(projectRef: string) {
    const params: PropertyService.GetPropertyByProjectRefAsyncParams = {
      Authorization: '',
      projectRef: projectRef,
    };
    this.propertyService.GetPropertyByProjectRefAsync(params).subscribe(response => {
      this.property = response;
      this.projectRef = response.projectRef;
      this.loadAssetData(this.projectRef);
    });
  }

  loadAssetData(projectRef) {
    const params: AssetService.GetAssetsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      projectRef: projectRef,
      keywords: this.assetFilters.keywords,
      completedDate: this.assetFilters.completedDate != null ? this.assetFilters.completedDate.toDateString() : null,
      assetCategory: this.assetFilters.assetCategory
    };
    this.assetService.GetAssetsPagedAsync(params).subscribe(response => {
      this.totalAssetRecordCount = response.totalRecordCount;
      this.assetsList = response.items;
      if (this.assetsList.length > 0) {
        this.assetsAvailable = true;
      }
    });
  }

  viewAssetDetails(id: number) {
    this.modalService.viewAssetDetails(id);
  }

  pageAssetChanged(pageAsset) {
    this.currentPage = pageAsset;
    if (this.projectRef) {
      this.loadAssetData(this.projectRef);
    }
  }
}
