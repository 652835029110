import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { CustomerFilters } from '../models/customer-filters';

@Component({
  selector: 'app-customer-filters',
  templateUrl: './customer-filters.component.html',
  styleUrls: ['./customer-filters.component.scss']
})
export class CustomerFiltersComponent implements OnInit {
  @Output() search: EventEmitter<CustomerFilters> = new EventEmitter();
  filters: CustomerFilters;
  accountManagerList =  [{ id: 1, name: 'Kevin R' }, { id: 2, name: 'Stephen McI' }];
  adminAccess = false;
  constructor(
    private authService: AuthService,
  ) {

  }

  ngOnInit() {
    this.resetFilters();
  }
  onSearchClick() {
    this.search.emit(this.filters);
  }
  resetFilters() {
    this.filters = {
      name: '',
      reference: 0,
      accountManager: ''
    };
    this.search.emit(this.filters);
  }
}
