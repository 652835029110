import { Injectable} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignAccountmanagerComponent } from '../properties/assign-accountmanager/assign-accountmanager.component';
import { EditCustomersComponent } from './edit-customers/edit-customers.component';
import { UnassignAccountmanagerComponent } from '../properties/unassign-accountmanager/unassign-accountmanager.component';

@Injectable({
    providedIn: 'root'
  })
  export class CustomerModalService {
    bsModalRef: BsModalRef;

    constructor(public modalService: BsModalService) { }

    viewCustomerDetails(customerId: number) {
        const initialState = {
          customerId: customerId,
        };
       this.modalService.show(EditCustomersComponent, { initialState: initialState, class: 'modal-lg', ignoreBackdropClick: true });
      }

      assignAccountManagers(propertyIds) {
        const initialState = {
          propertyIds: propertyIds,
        };
       this.modalService.show(AssignAccountmanagerComponent, { initialState: initialState, class: 'modal-lg', ignoreBackdropClick: true });
      }

      unassignAccountManagers(propertyIds) {
        const initialState = {
          propertyIds: propertyIds,
        };
       this.modalService.show(UnassignAccountmanagerComponent,
         { initialState: initialState, class: 'modal-lg', ignoreBackdropClick: true });
      }
  }
