import { Component, OnInit } from '@angular/core';
import { CustomerModelOut } from '../../api/models/customer-model-out';
import { NavigationService } from '../../services/navigation.service';
import { CustomerService } from '../../api/services/customer.service';
import { AlertsService } from '../../services/alert.service';
import { AuthService } from '../../auth/services/auth.service';
import { CustomerFilters } from './models/customer-filters';
import { CustomerModalService } from '../customers/customer.modal.services';
import { AccountService } from '../../api/services/account.service';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  customers: Array<CustomerModelOut>;
  adminAccess = false;
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  filters: CustomerFilters;
  name = '';
  reference = 0;
  constructor(
    private customerService: CustomerService,
    private accountService: AccountService,
    private navigateTo: NavigationService,
    private alertsService: AlertsService,
    private authService: AuthService,
    private modalService: CustomerModalService,
  ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
  }

  ngOnInit() {
    this.loadData();
  }
  filter(filters: CustomerFilters) {
    this.filters = filters;
    this.loadData();
  }
  loadData() {
    const params: CustomerService.GetCustomersPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      name: this.filters.name,
      reference: this.filters.reference,

    };
    this.customerService.GetCustomersPagedAsync(params).subscribe(response => {
      this.totalRecordCount = response.totalRecordCount;
      this.customers = response.items;
    });
  }

  pageChanged(page) {
    this.currentPage = page;
    this.loadData();
  }
  viewCustomerInfo(customerId: number) {
    this.modalService.viewCustomerDetails(customerId);
  }

  // viewDebtorStatement(debtorRef: string) {
  //   this.navigateTo.debtorStatement(debtorRef);
  // }
}
