import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EstimateFilters } from '../models/estimate-filters';
import { EstimateFiltersForm } from '../estimate-filters/estimateFiltersForm';
import * as moment from 'moment';

@Component({
  selector: 'app-est-filter',
  templateUrl: './est-filter.component.html',
  styleUrls: ['./est-filter.component.scss']
})
export class EstFilterComponent implements OnInit {
  @Output() search: EventEmitter<EstimateFilters> = new EventEmitter();
  filters: EstimateFilters = {
    startDate: null,
    endDate: null
  };
  filterForm: EstimateFiltersForm;
  accountManagerList = [{ id: 1, name: 'Kevin R' }, { id: 2, name: 'Stephen McI' }];
  adminAccess = false;
  constructor() {
    this.filterForm = new EstimateFiltersForm();
  }

  ngOnInit() {

  }
  onSearchClick() {
    this.search.emit(this.filters);
  }
  resetFilters() {
    let sDate = new Date();
    sDate = (moment(sDate).subtract(1, 'years')).toDate();

    this.filters = {
      startDate: sDate,
      endDate: new Date()
    };
    this.search.emit(this.filters);
    this.filterForm.setUpForm();
  }

  setStartDate(date) {
    this.filters.startDate = date;
  }

  setEndDate(date) {
    this.filters.endDate = date;
  }
}
