import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from '../../services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  api_calls_count = 0;
  exemptApiCalls = [
    'bulkdownload'
  ];
  constructor(public loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(req.url)) {
      this.api_calls_count++;
      this.loaderService.show();
      return next.handle(req).pipe(catchError((error: any) => {
        this.api_calls_count = 0;
        this.loaderService.hide();
        return next.handle(req);
      }),

        finalize(() => {
          this.api_calls_count--;
          if (req.url.toLowerCase().includes('account/login')) {
            this.api_calls_count = 0;
          }
          if (this.api_calls_count <= 0) {
            this.loaderService.hide();
          }
        }));
    } else {
      return next.handle(req);
    }
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    for (const url of this.exemptApiCalls) {
      if (requestUrl.includes(url)) {
        return false;
      }
    }
    return true;
  }
}

