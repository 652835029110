import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class PropertyFilterService {
    private _selectedPropertyRef = null;

    constructor() { }

    public get getPropertyRef() {
        return this._selectedPropertyRef;
      }
      public set setPropertyRef(ref) {
        this._selectedPropertyRef = ref;
      }
}
