import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  constructor(private toastr: ToastrService) { }

  success(message) {
    this.toastr.success(message, 'SUCCESSFUL');
  }
  error(message) {
    this.toastr.error(message, 'ERROR');
  }
  Warning(message) {
    this.toastr.warning(message, 'Warning');
  }
  Info(message) {
    this.toastr.info('info', message);
  }
  loginSuccessful() {
    this.success('Log in Successful');
  }
  loginUnSuccessful() {
    this.success('Please enter correct username and password');
  }
  forbidden() {
    this.error('You are not alllowed to perform this operation');
  }
  cancelled() {
    this.Warning('No changes were saved');
  }
  passwordChangedSuccessfully() {
    this.success('Password Changed Successfully');
  }
  recordDeleted() {
    this.Warning('Record Successfully Deleted');
  }

  fileUploaded() {
    this.success('File Uploaded Successfully');
  }

  fileValidation() {
    this.error('Please choose a file with a supported file type and max size 2MB');
  }

  noChanges() {
    this.Warning('No changes applied');
  }

}
