import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {  faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-table-delete-button',
  templateUrl: './table-delete-button.component.html',
  styleUrls: ['./table-delete-button.component.scss']
})
export class TableDeleteButtonComponent implements OnInit {
  faTimesCircle = faTimesCircle;
  @Output() click: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
