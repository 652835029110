import { AllsopBaseForm } from '../../common/allsopBaseForm';
import { AssetModelOut } from '../../api/models/asset-model-out';
import { AssetService } from '../../api/services/asset.service';

export class AssetEditForm extends AllsopBaseForm {
  constructor() {
    super();

    this.setUpForm();
  }
  public setUpForm() {
    this.resetForm();

    const model: any = {
      id: '0',
      assetCategory: '',
      assetCategoryCode: '',
      assetDescription: '',
      assetRef: '',
      callout: '0',
      issue: '0',
      propertyName: '',
      projectRef: '',
      serviced: '0',
    };
    this.setUpFormForEdit(model);
  }

  public setUpFormForEdit(modelOut: AssetModelOut) {
    this.resetForm();
    this.formGroup = this.fb.group<AssetModelOut>({
      id: this.getControl(modelOut.id),
      assetCategory: this.getControl(modelOut.assetCategory),
      assetCategoryCode: this.getControl(modelOut.assetCategoryCode),
      assetDescription: this.getControl(modelOut.assetDescription),
      assetRef: this.getControl(modelOut.assetRef),
      callout: this.getControl(modelOut.callout),
      propertyName: this.getControl(modelOut.projectName),
      projectRef: this.getControl(modelOut.projectRef),
      serviced: this.getControl(modelOut.serviced),
      issues: this.getControl(modelOut.issues)
    });
   this.formGroup.disable();
  }
}
