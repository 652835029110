/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CustomersPagedModelOut } from '../models/customers-paged-model-out';
import { CustomerModelOut } from '../models/customer-model-out';
import { CustomerNamesPagedModelOut } from '../models/customer-names-paged-model-out';
@Injectable({
  providedIn: 'root',
})
class CustomerService extends __BaseService {
  static readonly GetCustomersPagedAsyncPath = '/api/customers/paged';
  static readonly GetCustomerByIdAsyncPath = '/api/customers/{customerId}';
  static readonly GetCustomersNamesPagedAsyncPath = '/api/customers/customerNames';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Customers Paged
   * @param params The `CustomerService.GetCustomersPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `reference`:
   *
   * - `name`:
   *
   * - `keywords`:
   *
   * - `active`:
   *
   * @return Success
   */
  GetCustomersPagedAsyncResponse(params: CustomerService.GetCustomersPagedAsyncParams): __Observable<__StrictHttpResponse<CustomersPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.reference != null) __params = __params.set('reference', params.reference.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    if (params.active != null) __params = __params.set('active', params.active.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/customers/paged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomersPagedModelOut>;
      })
    );
  }
  /**
   * Get Customers Paged
   * @param params The `CustomerService.GetCustomersPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `reference`:
   *
   * - `name`:
   *
   * - `keywords`:
   *
   * - `active`:
   *
   * @return Success
   */
  GetCustomersPagedAsync(params: CustomerService.GetCustomersPagedAsyncParams): __Observable<CustomersPagedModelOut> {
    return this.GetCustomersPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as CustomersPagedModelOut)
    );
  }

  /**
   * @param params The `CustomerService.GetCustomerByIdAsyncParams` containing the following parameters:
   *
   * - `customerId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetCustomerByIdAsyncResponse(params: CustomerService.GetCustomerByIdAsyncParams): __Observable<__StrictHttpResponse<CustomerModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/customers/${encodeURIComponent(params.customerId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerModelOut>;
      })
    );
  }
  /**
   * @param params The `CustomerService.GetCustomerByIdAsyncParams` containing the following parameters:
   *
   * - `customerId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetCustomerByIdAsync(params: CustomerService.GetCustomerByIdAsyncParams): __Observable<CustomerModelOut> {
    return this.GetCustomerByIdAsyncResponse(params).pipe(
      __map(_r => _r.body as CustomerModelOut)
    );
  }

  /**
   * @param params The `CustomerService.GetCustomersNamesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `reference`:
   *
   * - `name`:
   *
   * - `keywords`:
   *
   * - `active`:
   *
   * @return Success
   */
  GetCustomersNamesPagedAsyncResponse(params: CustomerService.GetCustomersNamesPagedAsyncParams): __Observable<__StrictHttpResponse<CustomerNamesPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.reference != null) __params = __params.set('reference', params.reference.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    if (params.active != null) __params = __params.set('active', params.active.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/customers/customerNames`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerNamesPagedModelOut>;
      })
    );
  }
  /**
   * @param params The `CustomerService.GetCustomersNamesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `reference`:
   *
   * - `name`:
   *
   * - `keywords`:
   *
   * - `active`:
   *
   * @return Success
   */
  GetCustomersNamesPagedAsync(params: CustomerService.GetCustomersNamesPagedAsyncParams): __Observable<CustomerNamesPagedModelOut> {
    return this.GetCustomersNamesPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as CustomerNamesPagedModelOut)
    );
  }
}

module CustomerService {

  /**
   * Parameters for GetCustomersPagedAsync
   */
  export interface GetCustomersPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    reference?: number;
    name?: string;
    keywords?: string;
    active?: string;
  }

  /**
   * Parameters for GetCustomerByIdAsync
   */
  export interface GetCustomerByIdAsyncParams {
    customerId: number;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for GetCustomersNamesPagedAsync
   */
  export interface GetCustomersNamesPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    reference?: number;
    name?: string;
    keywords?: string;
    active?: string;
  }
}

export { CustomerService }
