import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private _home = 'home';
  private _login = 'login';
  private _user = 'user';
  private _customReport = 'customReport';
  private _customer = 'customer';
  private _customerList = 'customers';
  private _users = 'users';
  private _jobs = 'jobs';
  private _properties = 'properties';
  private _financial = 'financial';
  private _healthSafetyDocuments = 'hsDocuments';
  private _asset = 'asset';
  private _assetDetails = 'assetDetails';
  private _transaction = 'invoice';
  private _debtorStatement = 'statement';
  private _estimates = 'estimates';
  private _estimate = 'estimate';
  private _quotes = 'quotes';

  constructor(private router: Router) { }

  home() {
    this.navigateToPath(this._home);
  }

  login() {
    this.navigateToPath(this._login);
  }

  reDirectionLogin(reDirectUrl: string) {
    if (reDirectUrl === '/' || reDirectUrl.includes('login')) {
      this.login();
    } else {
      this.navigateToPathWithParams(this._login, { reDirectUrl: reDirectUrl });
    }
  }

  customReport(id: string, userId: string) {
    this.navigateToPathWithParams(this._customReport, {id, userId});
  }

  editUser(userId: string) {
    this.navigateToPathWithId(this._user, userId);
  }
  createUser() {
    this.navigateToPath(this._user);
  }
  users() {
    this.navigateToPath(this._users);
  }

  customers() {
    this.navigateToPath(this._customerList);
  }

  editCustomer(customerId: number) {
    this.navigateToPathWithId(this._customer, customerId);
  }


  viewJobDetails(jobId: number) {
    this.navigateToPathWithId(this._jobs, jobId);
  }

  jobsList() {
    this.navigateToPath(this._jobs);
  }

  propertyDetails(projectRef: string) {
    this.navigateToPathWithId(this._properties, projectRef);
  }

  properties() {
    this.navigateToPath(this._properties);
  }

  hsDocuments() {
    this.navigateToPath(this._healthSafetyDocuments);
  }

  assetList() {
    this.navigateToPath(this._asset);
  }

  assets(assetId: number) {
    this.navigateToPathWithId(this._assetDetails, assetId);
  }

  debtorStatement(debtorRef: string) {
    this.navigateToPathWithId(this._debtorStatement, debtorRef);
  }

  estimates() {
    this.navigateToPath(this._estimates);
  }

  viewEstimateDataDetails(estimateId: number) {
    this.navigateToPathWithId(this._estimate, estimateId);
  }

  quotes() {
    this.navigateToPath(this._quotes);
  }

  quoteDetails(quoteId: number) {
    this.navigateToPathWithId(this._quotes, quoteId);
  }


  financialDetails(transactionRef: number) {
    this.navigateToPathWithId(this._financial, transactionRef);
  }

 transactionDetails(transactionRef: number) {
    this.navigateToPathWithId(this._transaction, transactionRef);
  }

  deleteUser(userId: number) {
    this.navigateToPathWithId(this._user, userId);
  }

  propertyDebtoStatement(propertyRef: number) {
    this.navigateToPathWithId(this._debtorStatement, propertyRef);
  }

  redirectionPath(path: string) {
    this.navigateToPath(path);
  }

  // private methods
  private navigateToPath(path) {
    this.router.navigateByUrl(path);
  }
  private navigateToPathWithId(path, id) {
    this.navigateToPath(`${path}/${id}`);
  }

  private navigateToPathWithParams(path, params) {
    this.router.navigate([path], {queryParams: params});
  }
}
