import { Component, OnInit, ViewChild, ElementRef, AfterContentInit, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { faBars, faHome, faUsers, faCalendar, faLock, faTh, faBuilding, faFile, faUserCircle,
         faBookMedical, faBriefcase, faSign, faHardHat, faChartBar} from '@fortawesome/free-solid-svg-icons';
import * as pbi from 'powerbi-client';
import { NgxPowerBiService } from 'ngx-powerbi';
import { PowerBiService } from '../../api/services/power-bi.service';
import { AuthService } from '../../auth/services/auth.service';
import { AccountService } from '../../api/services/account.service';
import { UserModelOut } from '../../api/models/user-model-out';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, AfterContentInit, AfterContentChecked, AfterViewChecked {
  @ViewChild('embed') embed: ElementRef;
  private pbiContainerElement: HTMLElement;
  isLoggedIn = true;
  customDashboard: any [] = [];
  countReportGroupIds = 0;
  userId = '';
  faBars = faBars;
  faUserCircle = faUserCircle;
  faHome = faHome;
  faBriefcase = faBriefcase;
  faUsers = faUsers;
  faChartBar = faChartBar;
  faCalendar = faCalendar;
  faTh = faTh ;
  faBuilding = faBuilding;
  faLock = faLock;
  faSign = faSign;
  faFile = faFile;
  faHardhat = faHardHat;
  faBookMedical = faBookMedical;
  reportGroupId = null;
  reports:  Array<UserModelOut>;
  details: any;
  embedConfig = {
    type: 'report',
    id: '',
    embedUrl: '',
    tokenType: pbi.models.TokenType.Embed,
    accessToken: ''
  };
  reportGroups: Array<UserModelOut>;
  adminAccess = false;
  allAccountsAccess = false;
  constructor(private menuService: MenuService,
    private authService: AuthService,
    private userService: AccountService,
    private powerBiService: PowerBiService,
    private navigateTo: NavigationService,
    private ngxPowerBiService: NgxPowerBiService) {
      this.adminAccess = this.authService.roleMatch(['Administrator']);
      this.allAccountsAccess = this.authService.roleMatch(['AllAccounts']);
     }

  ngOnInit() {
    this.userId = this.authService.userId;
    this.loadData (this.userId);
  }
  ngAfterContentInit() {

  }
  ngAfterViewChecked(): void {

  }

  ngAfterContentChecked() {

  }
  toggleMenu() {
    this.menuService.toggleMenu();
  }

  loadData(userId) {
    const params: AccountService.GetUserByIdAsyncCustomerParams = {
      Authorization: '',
      id: userId
    };
    this.userService.GetUserByIdAsyncCustomer(params).subscribe(response => {
      this.reportGroupId = response.reportGroupId;
      this.details = response.reports;
      if (this.userId === response.id) {
        for (const item of this.details) {
          if (item.reportGroupId || (this.adminAccess || this.allAccountsAccess)) {
            this.customDashboard.push({
              'name': item.name,
              'reportGroupId': item.reportGroupId,
              'reportId': item.reportId
            });
        }
      }
    }
    });
  }

  loadCustomReport(reportId: string, userId: string) {
      this.navigateTo.customReport(reportId, userId);
    }
}
