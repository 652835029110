/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AssetPagedModelOut } from '../models/asset-paged-model-out';
import { AssetModelOut } from '../models/asset-model-out';
import { OkResult } from '../models/ok-result';
import { AssetModelIn } from '../models/asset-model-in';
import { AssetNamesPagedModelOut } from '../models/asset-names-paged-model-out';
@Injectable({
  providedIn: 'root',
})
class AssetService extends __BaseService {
  static readonly GetAssetsPagedAsyncPath = '/api/asset/paged';
  static readonly GetAssetByIdAsyncPath = '/api/asset/{assetId}';
  static readonly UpdateAssetLifecyleByAssetIdPath = '/api/asset/updateAsset';
  static readonly GetAssetNamesPagedAsyncPath = '/api/asset/assetNames';
  static readonly GenerateCsvReportPath = '/api/asset/export';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get All Assets
   * @param params The `AssetService.GetAssetsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `completedDate`:
   *
   * - `assetName`:
   *
   * - `assetCategory`:
   *
   * @return Success
   */
  GetAssetsPagedAsyncResponse(params: AssetService.GetAssetsPagedAsyncParams): __Observable<__StrictHttpResponse<AssetPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.propertyName != null) __params = __params.set('propertyName', params.propertyName.toString());
    if (params.projectRef != null) __params = __params.set('projectRef', params.projectRef.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    if (params.completedDate != null) __params = __params.set('completedDate', params.completedDate.toString());
    if (params.assetName != null) __params = __params.set('assetName', params.assetName.toString());
    if (params.assetCategory != null) __params = __params.set('assetCategory', params.assetCategory.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/asset/paged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AssetPagedModelOut>;
      })
    );
  }
  /**
   * Get All Assets
   * @param params The `AssetService.GetAssetsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `completedDate`:
   *
   * - `assetName`:
   *
   * - `assetCategory`:
   *
   * @return Success
   */
  GetAssetsPagedAsync(params: AssetService.GetAssetsPagedAsyncParams): __Observable<AssetPagedModelOut> {
    return this.GetAssetsPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as AssetPagedModelOut)
    );
  }

  /**
   * Get Asset By Id
   * @param params The `AssetService.GetAssetByIdAsyncParams` containing the following parameters:
   *
   * - `assetId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetAssetByIdAsyncResponse(params: AssetService.GetAssetByIdAsyncParams): __Observable<__StrictHttpResponse<AssetModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/asset/${encodeURIComponent(params.assetId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AssetModelOut>;
      })
    );
  }
  /**
   * Get Asset By Id
   * @param params The `AssetService.GetAssetByIdAsyncParams` containing the following parameters:
   *
   * - `assetId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetAssetByIdAsync(params: AssetService.GetAssetByIdAsyncParams): __Observable<AssetModelOut> {
    return this.GetAssetByIdAsyncResponse(params).pipe(
      __map(_r => _r.body as AssetModelOut)
    );
  }

  /**
   * @param params The `AssetService.UpdateAssetLifecyleByAssetIdParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * - `assetId`:
   *
   * @return Success
   */
  UpdateAssetLifecyleByAssetIdResponse(params: AssetService.UpdateAssetLifecyleByAssetIdParams): __Observable<__StrictHttpResponse<OkResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.modelIn;
    if (params.assetId != null) __params = __params.set('assetId', params.assetId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/asset/updateAsset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OkResult>;
      })
    );
  }
  /**
   * @param params The `AssetService.UpdateAssetLifecyleByAssetIdParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * - `assetId`:
   *
   * @return Success
   */
  UpdateAssetLifecyleByAssetId(params: AssetService.UpdateAssetLifecyleByAssetIdParams): __Observable<OkResult> {
    return this.UpdateAssetLifecyleByAssetIdResponse(params).pipe(
      __map(_r => _r.body as OkResult)
    );
  }

  /**
   * @param params The `AssetService.GetAssetNamesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `completedDate`:
   *
   * - `assetName`:
   *
   * - `assetCategory`:
   *
   * @return Success
   */
  GetAssetNamesPagedAsyncResponse(params: AssetService.GetAssetNamesPagedAsyncParams): __Observable<__StrictHttpResponse<AssetNamesPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.propertyName != null) __params = __params.set('propertyName', params.propertyName.toString());
    if (params.projectRef != null) __params = __params.set('projectRef', params.projectRef.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    if (params.completedDate != null) __params = __params.set('completedDate', params.completedDate.toString());
    if (params.assetName != null) __params = __params.set('assetName', params.assetName.toString());
    if (params.assetCategory != null) __params = __params.set('assetCategory', params.assetCategory.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/asset/assetNames`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AssetNamesPagedModelOut>;
      })
    );
  }
  /**
   * @param params The `AssetService.GetAssetNamesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `completedDate`:
   *
   * - `assetName`:
   *
   * - `assetCategory`:
   *
   * @return Success
   */
  GetAssetNamesPagedAsync(params: AssetService.GetAssetNamesPagedAsyncParams): __Observable<AssetNamesPagedModelOut> {
    return this.GetAssetNamesPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as AssetNamesPagedModelOut)
    );
  }

  /**
   * @param params The `AssetService.GenerateCsvReportParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `models`:
   *
   * @return Success
   */
  GenerateCsvReportResponse(params: AssetService.GenerateCsvReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.models;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/asset/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `AssetService.GenerateCsvReportParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `models`:
   *
   * @return Success
   */
  GenerateCsvReport(params: AssetService.GenerateCsvReportParams): __Observable<Blob> {
    return this.GenerateCsvReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module AssetService {

  /**
   * Parameters for GetAssetsPagedAsync
   */
  export interface GetAssetsPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    propertyName?: string;
    projectRef?: string;
    keywords?: string;
    completedDate?: string;
    assetName?: string;
    assetCategory?: string;
  }

  /**
   * Parameters for GetAssetByIdAsync
   */
  export interface GetAssetByIdAsyncParams {
    assetId: number;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UpdateAssetLifecyleByAssetId
   */
  export interface UpdateAssetLifecyleByAssetIdParams {

    /**
     * Access Token
     */
    Authorization: string;
    modelIn?: AssetModelIn;
    assetId?: number;
  }

  /**
   * Parameters for GetAssetNamesPagedAsync
   */
  export interface GetAssetNamesPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    propertyName?: string;
    projectRef?: string;
    keywords?: string;
    completedDate?: string;
    assetName?: string;
    assetCategory?: string;
  }

  /**
   * Parameters for GenerateCsvReport
   */
  export interface GenerateCsvReportParams {

    /**
     * Access Token
     */
    Authorization: string;
    models?: Array<AssetModelOut>;
  }
}

export { AssetService }
