import { Component, OnInit } from '@angular/core';
import { faInfoCircle, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { PropertyService } from '../../../api/services/property.service';
import { PropertyModelOut } from '../../../api/models/property-model-out';
import { PropertyViewServiceService } from '../services/property-view-service.service';

@Component({
  selector: 'app-property-info-tab',
  templateUrl: './property-info-tab.component.html',
  styleUrls: ['./property-info-tab.component.scss']
})
export class PropertyInfoTabComponent implements OnInit {
  faInfoCircle = faInfoCircle;
  faSearchLocation = faSearchLocation;
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  adminAccess = false;
  name = '';
  projectRef = null;
  property: PropertyModelOut;
  constructor(
    private propertyService: PropertyService,
    private activatedRoute: ActivatedRoute,
    private propertyViewService: PropertyViewServiceService
    ) { }

    ngOnInit(): void {
      this.listenForRouteParams();
    }

    listenForRouteParams() {
      this.activatedRoute.params.subscribe(params => {
        if (params.id) {
          this.projectRef = params.id;
          this.loadProperty(params.id);
        }
      });
    }

    loadProperty(projectRef: string) {
    const params: PropertyService.GetPropertyByProjectRefAsyncParams = {
      Authorization: '',
      projectRef : projectRef,
    };
    this.propertyService.GetPropertyByProjectRefAsync(params).subscribe(response => {
      this.property = response;
      this.propertyViewService.propertySelected(response);
    });
}
}
