import { AllsopBaseForm } from '../../common/allsopBaseForm';
import { EstimateModelOut } from '../../api/models/estimate-model-out';
import { EstimateStatusModelIn } from '../../api/models/estimate-status-model-in';
import { EstimateStatusService } from '../../api/services/estimate-status.service';

export class EstimateForm extends AllsopBaseForm {
    constructor() {
        super();

        this.setUpForm();
    }
    public setUpForm() {
        this.resetForm();

        const model: any = {
            comments: '',
            poNumber: ''
        };
        this.setUpFormForEdit(model);
    }

    public setUpFormForEdit(modelIn: EstimateStatusModelIn) {
        this.resetForm();

        this.formGroup = this.fb.group<EstimateStatusModelIn>({
            comment: this.getControl(''),
            poNumber: this.getControl('', false)
        });
    }

    getSaveModel(formResultKey: string): EstimateStatusService.PostAsyncParams {
        const value: EstimateStatusModelIn = this.formGroup.value;
        return {
            Authorization: '',
            modelIn: {
                comment: value.comment,
                formResultKey: formResultKey,
                poNumber: value.poNumber
            }
        };
    }
}
