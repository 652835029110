import { AllsopBaseForm } from '../../common/allsopBaseForm';
import { JobUploadForm } from './models/job-upload-form';
import { JobService } from '../../api/services/job.service';


export class JobFileUploadForm extends AllsopBaseForm {

    constructor() {
        super();
        this.setUpForm();
    }
    public setUpForm() {
      this.resetForm();

      const model: any = {
        propertyId: 0,
        description: '',
        file: File = null
      };
      this.setUpFormForEdit(model);
    }

    public setUpFormForEdit(value: JobUploadForm) {
        this.resetForm();

        this.formGroup = this.fb.group<JobUploadForm>({
            jobId: this.getControl(value.jobId, false),
            description: this.getControl(value.description, false),
            file: this.getControl(value.file, true)
        });
    }

    getSaveModel(jobId: number, file: File): JobService.AddDocumentToJobsParams {
        const value: JobUploadForm = this.formGroup.value;
        return {
            Authorization: '',
            jobId: jobId,
            file0: file,
            description: value.description

        };
    }
}
