import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as pbi from 'powerbi-client';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxPowerBiService } from 'ngx-powerbi';
import { PowerBiService } from '../../../api/services/power-bi.service';
import { ReportModelOut } from '../../../api/models/report-model-out';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-paginated-report',
  templateUrl: './paginated-report.component.html',
  styleUrls: ['./paginated-report.component.scss']
})
export class PaginatedReportComponent implements OnInit {
  @ViewChild('embed') embed: ElementRef;
  private pbiContainerElement: HTMLElement;
  selectedReport = 0;
  userId = '';
  reports: Array<ReportModelOut>;
  embedConfig = {
    type: 'report',
    id: '',
    embedUrl: '',
    tokenType: pbi.models.TokenType.Embed,
    accessToken: ''
  };
  report: any;
  reportId: '';
  propertyRef: '';
  queryParams: Params;
  constructor(
    private ngxPowerBiService: NgxPowerBiService,
    private activatedRoute: ActivatedRoute,
    private powerBiService: PowerBiService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.userId = this.authService.userId;
    this.pbiContainerElement = <HTMLElement>(document.getElementById('pbi-container'));
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    this.activatedRoute.params.subscribe(params => { // Setting report ID
      this.loadReport(params.reportId);
    });
  }

  loadReport(reportId: string ) {
    let reportParams = '';
    Object.keys(this.queryParams).forEach(key => {
       reportParams += '&' + key + '=' + this.queryParams[key];
    });
    reportParams += '&' + 'rp:USERID' + '=' + this.userId;
    const params: PowerBiService.GetReportByIdAsyncParams = {
      Authorization: '',
      reportId: reportId
    };
    this.powerBiService.GetReportByIdAsync(params).subscribe((response) => {
      this.embedConfig.accessToken = response.embedToken;
      this.embedConfig.id = response.reportId;
      this.embedConfig.embedUrl = response.embedUrl + reportParams;
      this.report = this.ngxPowerBiService.embed(this.pbiContainerElement, this.embedConfig);
    });
  }
}
