/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OAuthModelOut } from '../models/oauth-model-out';
import { LoginModelIn } from '../models/login-model-in';
import { RefreshTokenModelIn } from '../models/refresh-token-model-in';
import { DropdownModelOutString } from '../models/dropdown-model-out-string';
import { DropdownModelOutInt32 } from '../models/dropdown-model-out-int-32';
import { OkResult } from '../models/ok-result';
import { UpdateUserModelIn } from '../models/update-user-model-in';
import { UpdatePasswordModelIn } from '../models/update-password-model-in';
import { UsersPagedModelOut } from '../models/users-paged-model-out';
import { UserModelOut } from '../models/user-model-out';
import { RegisterModelIn } from '../models/register-model-in';
import { AccountManagersModelOut } from '../models/account-managers-model-out';
import { AnalysisCodesPagedModelOut } from '../models/analysis-codes-paged-model-out';
@Injectable({
  providedIn: 'root',
})
class AccountService extends __BaseService {
  static readonly AdminLoginAsyncPath = '/api/account/login';
  static readonly LogoutAsyncPath = '/api/account/logout';
  static readonly RefreshTokenAsyncPath = '/api/account/refreshToken';
  static readonly GetRolesPath = '/api/account/roles';
  static readonly GetReportGroupsDropdownAsyncPath = '/api/account/allreportgroups';
  static readonly UpdateUserAsyncPath = '/api/account/{userId}';
  static readonly UpdatePasswordAsyncPath = '/api/account/updatePassword/{userId}';
  static readonly GetUsersAsyncPath = '/api/account/users';
  static readonly GetUserByIdAsyncPath = '/api/account/users/{id}';
  static readonly GetUserByIdAsyncCustomerPath = '/api/account/users/cust/{id}';
  static readonly CreateUserAsyncPath = '/api/account';
  static readonly DeleteUserPath = '/api/account';
  static readonly GetAccountManagersPath = '/api/account/properties';
  static readonly GetAnalysisCodesAsyncPath = '/api/account/analysiscodes';
  static readonly GetAnalysisCodesPagedAsyncPath = '/api/account/customerNames';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Login
   * @param login
   * @return Success
   */
  AdminLoginAsyncResponse(login: LoginModelIn): __Observable<__StrictHttpResponse<OAuthModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = login;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/account/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OAuthModelOut>;
      })
    );
  }
  /**
   * Login
   * @param login
   * @return Success
   */
  AdminLoginAsync(login: LoginModelIn): __Observable<OAuthModelOut> {
    return this.AdminLoginAsyncResponse(login).pipe(
      __map(_r => _r.body as OAuthModelOut)
    );
  }

  /**
   * Logout
   * @param Authorization Access Token
   */
  LogoutAsyncResponse(Authorization: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/account/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Logout
   * @param Authorization Access Token
   */
  LogoutAsync(Authorization: string): __Observable<null> {
    return this.LogoutAsyncResponse(Authorization).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Generate refresh token
   * @param tokenModel
   * @return Success
   */
  RefreshTokenAsyncResponse(tokenModel: RefreshTokenModelIn): __Observable<__StrictHttpResponse<OAuthModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tokenModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/account/refreshToken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OAuthModelOut>;
      })
    );
  }
  /**
   * Generate refresh token
   * @param tokenModel
   * @return Success
   */
  RefreshTokenAsync(tokenModel: RefreshTokenModelIn): __Observable<OAuthModelOut> {
    return this.RefreshTokenAsyncResponse(tokenModel).pipe(
      __map(_r => _r.body as OAuthModelOut)
    );
  }

  /**
   * Get a list of roles.
   * @param Authorization Access Token
   * @return Success
   */
  GetRolesResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<DropdownModelOutString>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/account/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DropdownModelOutString>>;
      })
    );
  }
  /**
   * Get a list of roles.
   * @param Authorization Access Token
   * @return Success
   */
  GetRoles(Authorization: string): __Observable<Array<DropdownModelOutString>> {
    return this.GetRolesResponse(Authorization).pipe(
      __map(_r => _r.body as Array<DropdownModelOutString>)
    );
  }

  /**
   * Get a list of Report Groups.
   * @param Authorization Access Token
   * @return Success
   */
  GetReportGroupsDropdownAsyncResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<DropdownModelOutInt32>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/account/allreportgroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DropdownModelOutInt32>>;
      })
    );
  }
  /**
   * Get a list of Report Groups.
   * @param Authorization Access Token
   * @return Success
   */
  GetReportGroupsDropdownAsync(Authorization: string): __Observable<Array<DropdownModelOutInt32>> {
    return this.GetReportGroupsDropdownAsyncResponse(Authorization).pipe(
      __map(_r => _r.body as Array<DropdownModelOutInt32>)
    );
  }

  /**
   * Update users details.
   * @param params The `AccountService.UpdateUserAsyncParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `model`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  UpdateUserAsyncResponse(params: AccountService.UpdateUserAsyncParams): __Observable<__StrictHttpResponse<OkResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/account/${encodeURIComponent(params.userId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OkResult>;
      })
    );
  }
  /**
   * Update users details.
   * @param params The `AccountService.UpdateUserAsyncParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `model`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  UpdateUserAsync(params: AccountService.UpdateUserAsyncParams): __Observable<OkResult> {
    return this.UpdateUserAsyncResponse(params).pipe(
      __map(_r => _r.body as OkResult)
    );
  }

  /**
   * @param params The `AccountService.UpdatePasswordAsyncParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `model`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  UpdatePasswordAsyncResponse(params: AccountService.UpdatePasswordAsyncParams): __Observable<__StrictHttpResponse<OkResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/account/updatePassword/${encodeURIComponent(params.userId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OkResult>;
      })
    );
  }
  /**
   * @param params The `AccountService.UpdatePasswordAsyncParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `model`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  UpdatePasswordAsync(params: AccountService.UpdatePasswordAsyncParams): __Observable<OkResult> {
    return this.UpdatePasswordAsyncResponse(params).pipe(
      __map(_r => _r.body as OkResult)
    );
  }

  /**
   * Get users
   * @param params The `AccountService.GetUsersAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `keywords`:
   *
   * @return Success
   */
  GetUsersAsyncResponse(params: AccountService.GetUsersAsyncParams): __Observable<__StrictHttpResponse<UsersPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/account/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsersPagedModelOut>;
      })
    );
  }
  /**
   * Get users
   * @param params The `AccountService.GetUsersAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `keywords`:
   *
   * @return Success
   */
  GetUsersAsync(params: AccountService.GetUsersAsyncParams): __Observable<UsersPagedModelOut> {
    return this.GetUsersAsyncResponse(params).pipe(
      __map(_r => _r.body as UsersPagedModelOut)
    );
  }

  /**
   * Get a user by GUID Id
   * @param params The `AccountService.GetUserByIdAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetUserByIdAsyncResponse(params: AccountService.GetUserByIdAsyncParams): __Observable<__StrictHttpResponse<UserModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/account/users/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserModelOut>;
      })
    );
  }
  /**
   * Get a user by GUID Id
   * @param params The `AccountService.GetUserByIdAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetUserByIdAsync(params: AccountService.GetUserByIdAsyncParams): __Observable<UserModelOut> {
    return this.GetUserByIdAsyncResponse(params).pipe(
      __map(_r => _r.body as UserModelOut)
    );
  }

  /**
   * Get a user by GUID Id (Customer Policy)
   * @param params The `AccountService.GetUserByIdAsyncCustomerParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetUserByIdAsyncCustomerResponse(params: AccountService.GetUserByIdAsyncCustomerParams): __Observable<__StrictHttpResponse<UserModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/account/users/cust/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserModelOut>;
      })
    );
  }
  /**
   * Get a user by GUID Id (Customer Policy)
   * @param params The `AccountService.GetUserByIdAsyncCustomerParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetUserByIdAsyncCustomer(params: AccountService.GetUserByIdAsyncCustomerParams): __Observable<UserModelOut> {
    return this.GetUserByIdAsyncCustomerResponse(params).pipe(
      __map(_r => _r.body as UserModelOut)
    );
  }

  /**
   * Register a new user.
   * @param params The `AccountService.CreateUserAsyncParams` containing the following parameters:
   *
   * - `model`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  CreateUserAsyncResponse(params: AccountService.CreateUserAsyncParams): __Observable<__StrictHttpResponse<OkResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.model;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OkResult>;
      })
    );
  }
  /**
   * Register a new user.
   * @param params The `AccountService.CreateUserAsyncParams` containing the following parameters:
   *
   * - `model`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  CreateUserAsync(params: AccountService.CreateUserAsyncParams): __Observable<OkResult> {
    return this.CreateUserAsyncResponse(params).pipe(
      __map(_r => _r.body as OkResult)
    );
  }

  /**
   * @param params The `AccountService.DeleteUserParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `userId`:
   *
   * @return Success
   */
  DeleteUserResponse(params: AccountService.DeleteUserParams): __Observable<__StrictHttpResponse<OkResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OkResult>;
      })
    );
  }
  /**
   * @param params The `AccountService.DeleteUserParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `userId`:
   *
   * @return Success
   */
  DeleteUser(params: AccountService.DeleteUserParams): __Observable<OkResult> {
    return this.DeleteUserResponse(params).pipe(
      __map(_r => _r.body as OkResult)
    );
  }

  /**
   * @param Authorization Access Token
   * @return Success
   */
  GetAccountManagersResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<AccountManagersModelOut>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/account/properties`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AccountManagersModelOut>>;
      })
    );
  }
  /**
   * @param Authorization Access Token
   * @return Success
   */
  GetAccountManagers(Authorization: string): __Observable<Array<AccountManagersModelOut>> {
    return this.GetAccountManagersResponse(Authorization).pipe(
      __map(_r => _r.body as Array<AccountManagersModelOut>)
    );
  }

  /**
   * @param Authorization Access Token
   * @return Success
   */
  GetAnalysisCodesAsyncResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<DropdownModelOutInt32>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/account/analysiscodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DropdownModelOutInt32>>;
      })
    );
  }
  /**
   * @param Authorization Access Token
   * @return Success
   */
  GetAnalysisCodesAsync(Authorization: string): __Observable<Array<DropdownModelOutInt32>> {
    return this.GetAnalysisCodesAsyncResponse(Authorization).pipe(
      __map(_r => _r.body as Array<DropdownModelOutInt32>)
    );
  }

  /**
   * @param params The `AccountService.GetAnalysisCodesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `code`:
   *
   * @return Success
   */
  GetAnalysisCodesPagedAsyncResponse(params: AccountService.GetAnalysisCodesPagedAsyncParams): __Observable<__StrictHttpResponse<AnalysisCodesPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/account/customerNames`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AnalysisCodesPagedModelOut>;
      })
    );
  }
  /**
   * @param params The `AccountService.GetAnalysisCodesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `code`:
   *
   * @return Success
   */
  GetAnalysisCodesPagedAsync(params: AccountService.GetAnalysisCodesPagedAsyncParams): __Observable<AnalysisCodesPagedModelOut> {
    return this.GetAnalysisCodesPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as AnalysisCodesPagedModelOut)
    );
  }
}

module AccountService {

  /**
   * Parameters for UpdateUserAsync
   */
  export interface UpdateUserAsyncParams {
    userId: string;
    model: UpdateUserModelIn;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UpdatePasswordAsync
   */
  export interface UpdatePasswordAsyncParams {
    userId: string;
    model: UpdatePasswordModelIn;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for GetUsersAsync
   */
  export interface GetUsersAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    keywords?: string;
  }

  /**
   * Parameters for GetUserByIdAsync
   */
  export interface GetUserByIdAsyncParams {
    id: string;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for GetUserByIdAsyncCustomer
   */
  export interface GetUserByIdAsyncCustomerParams {
    id: string;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for CreateUserAsync
   */
  export interface CreateUserAsyncParams {
    model: RegisterModelIn;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for DeleteUser
   */
  export interface DeleteUserParams {

    /**
     * Access Token
     */
    Authorization: string;
    userId?: string;
  }

  /**
   * Parameters for GetAnalysisCodesPagedAsync
   */
  export interface GetAnalysisCodesPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    code?: string;
  }
}

export { AccountService }
