import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DebtorFiltersForm } from '../../financial/finance-filters/filterFormDebtor';
import { DebtorTransationFilters } from '../../financial/finance-filters/models/debtor-finance-filters';

@Component({
  selector: 'app-paid-invoices-filters',
  templateUrl: './paid-invoices-filters.component.html',
  styleUrls: ['./paid-invoices-filters.component.scss']
})
export class PaidInvoicesFiltersComponent implements OnInit {
  @Output() search: EventEmitter<DebtorTransationFilters> = new EventEmitter();
  filters: DebtorTransationFilters = {
    keywords: '',
    transactionStatus: 'PD',
    startDate: null,
    endDate: null
  };
  filterForm: DebtorFiltersForm;
  transactionStatusList = ['PD'];
  adminAccess = false;
  constructor() {
    this.filterForm = new DebtorFiltersForm();
  }

  ngOnInit() {
  }
  onSearchClick() {
    this.search.emit(this.filters);
  }
  resetFilters() {
    const sDate = new Date();
    sDate.setDate(sDate.getDate() - 3);

    this.filters = {
      keywords: '',
      transactionStatus: 'PD',
      startDate: sDate,
      endDate: new Date()
    };
    this.search.emit(this.filters);
    this.filterForm.setUpForm();
  }

  setStartDate(date) {
    this.filters.startDate = date;
  }

  setEndDate(date) {
    this.filters.endDate = date;
  }
}
