import { AllsopBaseForm } from '../../common/allsopBaseForm';
import { PropertyUploadForm } from './models/property-upload-form';
import { PropertyService } from '../../api/services/property.service';


export class PropertyFileUploadForm extends AllsopBaseForm {

    constructor() {
        super();
        this.setUpForm();
    }
    public setUpForm() {
      this.resetForm();

      const model: any = {
        propertyId: 0,
        description: '',
        file: File = null
      };
      this.setUpFormForEdit(model);
    }

    public setUpFormForEdit(value: PropertyUploadForm) {
        this.resetForm();

        this.formGroup = this.fb.group<PropertyUploadForm>({
            propertyId: this.getControl(value.propertyId, false),
            description: this.getControl(value.description, false),
            file: this.getControl(value.file, true)
        });
    }

    getSaveModel(propertyId: number, file: File): PropertyService.AddDocumentToPropertyParams {
        const value: PropertyUploadForm = this.formGroup.value;
        return {
            Authorization: '',
            propertyId: propertyId,
            file0: file,
            description: value.description

        };
    }
}
