import { Component, Inject, OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { AssetService } from '../../../api/services/asset.service';
import { faFile, faTimes, faFilePdf, faDownload } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AssetEditForm } from '../assetForm';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DOCUMENT } from '@angular/common';
import * as FileSaver from 'file-saver';
import { AssetLifecycleForm } from './assetLifecycleForm';
import { AlertsService } from '../../../services/alert.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BulkDownloadService } from '../../../api/services/bulk-download.service';
import { BulkDownloadTypeEnum } from '../../../common/enums/bulkdownloadtype.enum';
import { FilesModelIn } from '../../../api/models/files-model-in';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss']
})
export class AssetDetailsComponent implements OnInit {
  faFilePdf = faFilePdf;
  faTimes = faTimes;
  faFile = faFile;
  faDownload = faDownload;
  assetForm: AssetEditForm;
  assetLifecycleForm: AssetLifecycleForm;
  isSubmitted = false;
  isReadOnly = true;
  assetId = 0;
  assetDocuments = [];
  allChecked = false;
  selectedDocuments = [];
  isCustomer = false;
  route = '';
  currentYear = 0;
  today = new Date();
  yearsList = [];
  bulkDownloadType = BulkDownloadTypeEnum;
  createdDate = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private assetService: AssetService,
    private navigateTo: NavigationService,
    private toastr: ToastrService,
    private alertsService: AlertsService,
    private authService: AuthService,
    public bsModalRef: BsModalRef,
    private router: Router,
    private bulkDownloadService: BulkDownloadService
  ) {
    this.isCustomer = this.authService.roleMatch(['Customer']);
  }

  ngOnInit() {
    this.route = '/asset';
    this.loadData(this.assetId);
    this.today = new Date();
    this.currentYear = moment(this.today).year();
    this.dateRanges();
  }

  loadData(assetId: number) {
    const params: AssetService.GetAssetByIdAsyncParams = {
      Authorization: '',
      assetId: assetId
    };
    this.assetService.GetAssetByIdAsync(params).subscribe(response => {
      this.assetForm = new AssetEditForm();
      this.assetLifecycleForm = new AssetLifecycleForm();
      this.assetForm.setUpFormForEdit(response);
      this.assetLifecycleForm.setUpFormForEdit(response);
      this.assetDocuments = response.assetDocuments;
      this.createdDate = response.createdDate;
    });
  }

  viewDocument(url: string) {
    this.document.location.href = url;
  }

  back() {
    this.toastr.warning('NO CHANGES MADE', 'CANCELLED');
    this.close();
    window.history.back();
  }

  close() {
    this.bsModalRef.hide();
    this.router.navigateByUrl('/users', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.route]);
    });
  }

  download() {
    const filesModelIn = this.getFilesModelInParam();
    this.bulkDownload(filesModelIn);
    this.selectedDocuments = [];
    this.allChecked = false;
    for (let i = 0; i < this.assetDocuments.length; i++) {
      this.assetDocuments[i].selected = false;
    }
  }

  // multi select
  checkUncheckAll() {
    for (let i = 0; i < this.assetDocuments.length; i++) {
      this.assetDocuments[i].selected = this.allChecked;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.allChecked = this.assetDocuments.every(function (item: any) {
      return item.selected === true;
    });
    this.getCheckedItemList();
  }

  preventDecimals(event) {
    return event.keyCode !== 190;
  }

  dateRanges() {
    this.yearsList.push(this.currentYear);
    for (let i = 1; i < 50; i++) {
      this.yearsList.push(this.currentYear - i
      );
    }
    return this.yearsList;

  }

  calculateCostForRemainingLife() {
    const costOfRepairsPA = Number(this.assetLifecycleForm.form.value.costOfRepairsPA);
    const yearOfInstallation = Number(this.assetLifecycleForm.form.value.yearOfInstallation);
    const projectedLifeCycle = Number(this.assetLifecycleForm.form.value.projectedLifeCycle);
    const remainingProjectedLife = Math.max(0, projectedLifeCycle - (this.currentYear - yearOfInstallation));

    const calculatedCost = (costOfRepairsPA * remainingProjectedLife);
    this.assetLifecycleForm.form.value.costForRemainingLife = calculatedCost.toFixed(2);
  }

  updateAssetLifecycle() {
    const saveModel = this.assetLifecycleForm.getEditSaveModel(this.assetId);

    this.assetService.UpdateAssetLifecyleByAssetId(saveModel).subscribe(() => {
      this.alertsService.success('Asset Updated');
    });
  }

  getCheckedItemList() {
    this.selectedDocuments = [];
    for (let i = 0; i < this.assetDocuments.length; i++) {
      if (this.assetDocuments[i].selected) {
        this.selectedDocuments.push(this.assetDocuments[i]);
      }
    }
  }

  selectedLength() {
    return this.selectedDocuments.length > 0 ? `(${this.selectedDocuments.length})` : '';
  }

  bulkDownload(filesModelIn: FilesModelIn[]) {
    const params: BulkDownloadService.BulkDownloadSelectedAsyncParams = {
      Authorization: '',
      modelIn: {
        files: filesModelIn,
        type: this.bulkDownloadType.asset
      }
    };
    this.alertsService.success('Bulk download running in background. This might take a few mins to download.');
    this.bulkDownloadService.BulkDownloadSelectedAsync(params).subscribe(response => {
      const blob: any = new Blob([response], { type: 'application/zip' });
      const fileName = this.getZipFileName();
      FileSaver.saveAs(blob, fileName);
    });
  }

  bulkDownloadAll() {
    if (confirm(`Are you sure to download all these files? (${this.assetDocuments.length})`)) {
      const params: BulkDownloadService.BulkDownloadAllAsyncParams = {
        Authorization: '',
        modelIn: {
          assetId: this.assetId,
          assetRef: `${this.assetForm.formGroup.value.assetRef}`,
          type: this.bulkDownloadType.asset
        }
      };
      this.alertsService.success('Bulk download running in background. This might take a few mins to download.');
      this.bulkDownloadService.BulkDownloadAllAsync(params).subscribe(response => {
        const blob: any = new Blob([response], { type: 'application/zip' });
        const fileName = this.getZipFileName();
        FileSaver.saveAs(blob, fileName);
      });
    }
  }

  getFilesModelInParam() {
    return this.selectedDocuments.map(doc => ({
      id: doc.id,
      originalFileName: doc.name,
      convertedFileName: `${this.assetForm.formGroup.value.assetDescription.toString().trim()}-${moment(doc.createdDate).format('DDMMyyyy')}${moment().format('HHmmss')}-${doc.documentRef}`,
      url: doc.url,
    }));
  }

  getZipFileName() {
    return `Asset Documents-${this.assetForm.formGroup.value.assetDescription.toString().trim()}-${this.assetForm.formGroup.value.assetRef}-${moment().format('DDMMyyyy')}${moment().format('HHmmss')}`;
  }
}
