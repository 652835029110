import { Component, OnInit } from '@angular/core';
import { DebtorsTransactionService } from '../../api/services/debtors-transaction.service';
import { faDownload, faTasks } from '@fortawesome/free-solid-svg-icons';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import { AppSettingsService } from '../../services/app-settings.service';
import { DebtorsTransactionPagedSettings } from '../../api/models/debtors-transaction-paged-settings';

@Component({
  selector: 'app-debtor-statement',
  templateUrl: './debtor-statement.component.html',
  styleUrls: ['./debtor-statement.component.scss']
})
export class DebtorStatementComponent implements OnInit {
  adminAccess = false;
  debtorTransAvailable = false;
  faDownload = faDownload;
  faTasks = faTasks;
  financialAttachment = [];
  selectedDocuments = [];
  allChecked = false;
  financeReference = 0;
  debtorTransactions;
  totalRecordCount = 0;
  totalOutstanding = 0;
  days30 = 0;
  days60 = 0;
  days90 = 0;
  currentTotal = 0;
  currentPage = 1;
  pageSize = 20;
  projectRef = '';
  outstanding = [];
  debtorRef = '';
  custId = 0;
  sum = 0;
  customerInformation;
  todaysDate = new Date();
  logoUrl = '';

  constructor(
    private debtorService: DebtorsTransactionService,
    private navigateTo: NavigationService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private appSettings: AppSettingsService
  ) { }

  ngOnInit() {
    this.listenForRouteParams();
  }

  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      this.projectRef = params.id;
      this.loadDebtorsTransations(this.projectRef);
    });
  }

  loadDebtorsTransations(propertyRef: string) {
    const params: DebtorsTransactionService.GetOutstandingDebtorsTransactionsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      projectRef: propertyRef
    };
    this.debtorService.GetOutstandingDebtorsTransactionsPagedAsync(params).subscribe(response => {
      this.totalRecordCount = response.totalRecordCount;
      this.totalOutstanding = response.totalOutstanding;
      this.currentTotal = response.currentTotal;
      this.days30 = response.day30Total;
      this.days60 = response.day60Total;
      this.days90 = response.day90Total;
      this.debtorTransactions = response.items;
    });
  }

  back() {
    window.history.back();
  }

  transactionDetails(transactionRef: number) {
    this.navigateTo.transactionDetails(transactionRef);
  }

  pageChanged(page) {
    if (this.projectRef) {
      this.currentPage = page;
      this.loadDebtorsTransations(this.projectRef);
    }
  }

  downloadDebtorStatementPDF() {
    const params: DebtorsTransactionService.GenerateDebtorsStatementPDFParams = {
      Authorization: '',
      logoUrl: this.appSettings.getLynchLogo,
      debtorsTransactionPagedSettings: this.pdfModelIn()
    };
    this.debtorService.GenerateDebtorsStatementPDF(params).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
      FileSaver.saveAs(blob, 'Property:' + this.projectRef + `_DebtorStatement.pdf` );
      this.toastr.success('Property Debtor PDF ' + this.projectRef, 'Download Successful');
    });
  }

  pdfModelIn() {
    const modelIn: DebtorsTransactionPagedSettings = {
      projectRef: this.projectRef,
      debtorRef: this.debtorRef,
      pageNo: this.currentPage,
      pageSize: this.pageSize
    };
    return modelIn;
  }
}
