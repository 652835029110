import { Component, OnInit, Input } from '@angular/core';
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../auth/services/auth.service';
import { PropertyService } from '../../../api/services/property.service';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { AlertsService } from '../../../services/alert.service';
import { PropertyFileUploadForm } from '../propertyFileUploadForm';

@Component({
  selector: 'app-property-documentation',
  templateUrl: './property-documentation.component.html',
  styleUrls: ['./property-documentation.component.scss']
})
export class PropertyDocumentationComponent implements OnInit {
  @Input() jobId = 0;
  projectRef = '';
  propertyId = 0;
  docsAvailable = false;
  propertyAttachments = [];
  showUploadControl = false;
  faFile = faFile;
  faDownload = faDownload;
  adminAccess = false;
  uploadConfig = {};
  selectedDocuments = [];
  allChecked = false;
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  fileUploadForm: PropertyFileUploadForm;
  acceptedFileTypes = ['.jpg', '.png', '.pdf', '.xlsx', '.docx', '.xlt'];
  fileTypeInvalid = false;
  fileTypeErrorMsg = 'Supported file types - ' + this.acceptedFileTypes.toString();
  fileSizeInvalid = false;
  fileSizeErrorMsg = 'Max. file size - 2MB';
  private file: File | null = null;
  constructor(
    private authService: AuthService,
    private propertyUploadsService: PropertyService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private alertService: AlertsService
    ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
    this.fileUploadForm = new PropertyFileUploadForm();
  }

  ngOnInit() {
    this.listenForRouteParams();
  }
  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      this.projectRef = params.id;
      this.loadProperty(this.projectRef);
      this.loadAttachments();
    });
  }

  loadAttachments() {
    const params: PropertyService.GetPropertyDocumentsPagedAsyncParams = {
      Authorization: '',
      projectRef: this.projectRef,
      pageNo: this.currentPage,
      pageSize: this.pageSize
    };
    this.propertyUploadsService.GetPropertyDocumentsPagedAsync(params).subscribe(response => {
      this.propertyAttachments = response.items;
      if (this.propertyAttachments.length > 0) {
        this.docsAvailable = true;
      }
      this.totalRecordCount = response.totalRecordCount;
    });
  }

  loadProperty(projectRef: string) {
    const params: PropertyService.GetPropertyByProjectRefAsyncParams = {
      Authorization: '',
      projectRef : projectRef,
    };
    this.propertyUploadsService.GetPropertyByProjectRefAsync(params).subscribe(response => {
      this.propertyId = response.id;
    });
  }

  pageChanged(page) {
    this.currentPage = page;
    this.loadAttachments();
  }

  uploadComplete(e) {
    this.loadAttachments();
  }


  isPDF(attachmentURL: string) {
    return attachmentURL.includes('.pdf');
  }

  selectAll() {
    for (let i = 0; i < this.propertyAttachments.length; i++) {
      if (!this.propertyAttachments[i].selected) {
        this.propertyAttachments[i].selected = true;
        this.selectedDocuments.push(this.propertyAttachments[i]);
      } else {
        this.propertyAttachments[i].selected = false;
        const index = this.selectedDocuments.indexOf(this.propertyAttachments[i]);
        this.selectedDocuments.splice(index, 1);
      }
    }
  }

  isChecked(attachment, event) {
    const index = this.selectedDocuments.indexOf(attachment);
    if (index === -1) {
      if (event.target.checked) {
        this.selectedDocuments.push(attachment);
      }
    } else {
      this.selectedDocuments.splice(index, 1);
    }
  }

  download() {
    this.selectedDocuments.forEach(function (doc) {
      if (doc.selected) {
        FileSaver.saveAs(doc.url, doc.name);
      }
    });
    this.selectedDocuments = [];
    this.allChecked = false;
    for (let i = 0; i < this.propertyAttachments.length; i++) {
      this.propertyAttachments[i].selected = false;
    }
    this.toastr.success('Download Successful');
  }

  handleFile(event) {
    const file = event && event.target.files[0];
    this.file = file;
    this.checkFileType(file);
    this.checkFileSize(file);
  }

  checkFileType( file: File) {
        const extensionStart = file.name.lastIndexOf('.');
        const extension = file.name.substring(extensionStart);
        if ( !this.acceptedFileTypes.includes(extension.toLowerCase()) ) {
            this.fileTypeInvalid = true;
        } else {
          this.fileTypeInvalid = false;
        }
  }

  checkFileSize( file: File) {
    const sizeInBytes = file.size;
    const sizeInMB = sizeInBytes / (1024 * 1024);
    if (sizeInMB > 2) {
      this.fileSizeInvalid = true;
    } else {
      this.fileSizeInvalid = false;
    }
  }

  upload(event) {
    this.fileUploadForm.isSubmitted = true;
    if (this.fileUploadForm.form.valid && !this.fileSizeInvalid && !this.fileTypeInvalid) {

      const saveModel = this.fileUploadForm.getSaveModel(this.propertyId, this.file);
      this.propertyUploadsService.AddDocumentToProperty(saveModel).subscribe(response => {
        if (response) {
          this.alertService.fileUploaded();
          this.fileUploadForm.setUpForm();
          this.file = null;
          this.loadAttachments();
        }

      });
    } else {
      this.alertService.fileValidation();
    }
  }
}
