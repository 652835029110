/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BulkDownloadModelIn } from '../models/bulk-download-model-in';
import { BulkDownloadAllModelIn } from '../models/bulk-download-all-model-in';
@Injectable({
  providedIn: 'root',
})
class BulkDownloadService extends __BaseService {
  static readonly BulkDownloadSelectedAsyncPath = '/api/bulkdownload/selected';
  static readonly BulkDownloadAllAsyncPath = '/api/bulkdownload/all';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Bulk Download Selected Files
   * @param params The `BulkDownloadService.BulkDownloadSelectedAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  BulkDownloadSelectedAsyncResponse(params: BulkDownloadService.BulkDownloadSelectedAsyncParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.modelIn;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/bulkdownload/selected`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Bulk Download Selected Files
   * @param params The `BulkDownloadService.BulkDownloadSelectedAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  BulkDownloadSelectedAsync(params: BulkDownloadService.BulkDownloadSelectedAsyncParams): __Observable<Blob> {
    return this.BulkDownloadSelectedAsyncResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Bulk Download All Files
   * @param params The `BulkDownloadService.BulkDownloadAllAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  BulkDownloadAllAsyncResponse(params: BulkDownloadService.BulkDownloadAllAsyncParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.modelIn;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/bulkdownload/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Bulk Download All Files
   * @param params The `BulkDownloadService.BulkDownloadAllAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  BulkDownloadAllAsync(params: BulkDownloadService.BulkDownloadAllAsyncParams): __Observable<Blob> {
    return this.BulkDownloadAllAsyncResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module BulkDownloadService {

  /**
   * Parameters for BulkDownloadSelectedAsync
   */
  export interface BulkDownloadSelectedAsyncParams {

    /**
     * Access Token
     */
    Authorization: string;
    modelIn?: BulkDownloadModelIn;
  }

  /**
   * Parameters for BulkDownloadAllAsync
   */
  export interface BulkDownloadAllAsyncParams {

    /**
     * Access Token
     */
    Authorization: string;
    modelIn?: BulkDownloadAllModelIn;
  }
}

export { BulkDownloadService }
