import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss']
})
export class PagingComponent implements OnInit {
  @Input() totalRecordCount = 0;
  @Input() currentPage = 0;
  @Input() pageSize = 0;
  @Input() maxSize = 0;
  @Output() pageChanged: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  pageChange(page) {
    this.pageChanged.emit(page);
  }
}
