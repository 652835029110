import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { AuthService } from '../../auth/services/auth.service';
import { DebtorsTransactionModelOut } from '../../api/models/debtors-transaction-model-out';
import { DebtorsTransactionService } from '../../api/services/debtors-transaction.service';
import { ToastrService } from 'ngx-toastr';
import { PropertyService } from '../../api/services/property.service';
import { ActivatedRoute } from '@angular/router';
import { DebtorTransationFilters } from '../financial/finance-filters/models/debtor-finance-filters';
import { JobService } from '../../api/services/job.service';
import { JobModelOut } from '../../api/models/job-model-out';
import { PropertyFilterService } from '../../services/property-filter-service';
@Component({
  selector: 'app-paid-invoices',
  templateUrl: './paid-invoices.component.html',
  styleUrls: ['./paid-invoices.component.scss']
})
export class PaidInvoicesComponent implements OnInit {
  adminAccess = false;
  financialAttachment = [];
  jobs: Array<JobModelOut>;
  jobRef = 0;
  selectedDocuments = [];
  jobResponse;
  allChecked = false;
  filters: DebtorTransationFilters = {
    keywords: '',
    transactionStatus: 'PD',
    startDate: new Date(),
    endDate: new Date(),
  };
  financeReference = 0;
  financialInformation: Array<DebtorsTransactionModelOut>;
  property;
  projectRef = '0';
  totalRecordCount = 0;
  jobId = 0;
  currentPage = 1;
  propertyFilterRef = false;
  pageSize = 20;
  constructor(
    private debtorService: DebtorsTransactionService, // Finance Service
    private jobService: JobService,
    private toastr: ToastrService,
    private authService: AuthService,
    private propertyFilterService: PropertyFilterService,
    private navigateTo: NavigationService,
    private propertyService: PropertyService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
  }
  ngOnInit() {
    this.setFilterDateRange();

    if (this.propertyFilterService.getPropertyRef != null ) {
      this.propertyFilterRef = true;
    } else {
      this.propertyFilterRef = false;
    }
  }

  setFilterDateRange() {
    const sDate = new Date();
    sDate.setDate(sDate.getDate() - 3);

    this.filters = {
      startDate: null,
      endDate: null,
      transactionStatus: 'PD',
      keywords: '',
    };
  }

  filter(filters: DebtorTransationFilters) {
    this.filters = filters;
    this.loadDebtorsTransations();
  }

  pageChanged(page) {
    this.currentPage = page;
    this.loadDebtorsTransations();
  }


  loadDebtorsTransations() {
    const params: DebtorsTransactionService.GetDebtorsTransactionsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      projectRef: this.propertyFilterService.getPropertyRef,
      transactionStatus: this.filters.transactionStatus,
      keywords: this.filters.keywords,
      startDate: this.filters.startDate != null ? this.filters.startDate.toDateString() : null,
      endDate: this.filters.endDate != null ? this.filters.endDate.toDateString() : null,

    };
    this.debtorService.GetDebtorsTransactionsPagedAsync(params).subscribe(response => {
      this.totalRecordCount = response.totalRecordCount;
      this.financialInformation = response.items;
    });
  }

  view(transactionRef: number) {
    this.navigateTo.transactionDetails(transactionRef);
  }

  viewDebtorStatement() {
    this.navigateTo.debtorStatement(this.propertyFilterService.getPropertyRef);
  }

  loadJobByJobRef(jobRef: string) {
    const params: JobService.GetJobByJobRefAsyncParams = {
      Authorization: '',
      jobRef: jobRef,
    };
    this.jobService.GetJobByJobRefAsync(params).subscribe(response => {
      this.jobResponse = response;
      this.jobId = response.id;
      this.jobDetailsForInvoice(this.jobId);
    });
  }

  jobDetailsForInvoice(jobId: number) {
    this.navigateTo.viewJobDetails(jobId);
  }

  loadProperty(projectRef: string) {
    const params: PropertyService.GetPropertyByProjectRefAsyncParams = {
      Authorization: '',
      projectRef: projectRef,
    };
    this.propertyService.GetPropertyByProjectRefAsync(params).subscribe(response => {
      this.property = response;
    });
  }
}
