import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { JobFilters } from '../models/job-filters';

export class JobFiltersForm extends AllsopBaseForm {
    constructor() {
        super();

        this.setUpForm();
    }
    public setUpForm() {
        const model: JobFilters = {
            keywords: '',
            assets: '',
            properties: '',
            jobType: '',
            jobStatus: '',
            startDate: null,
            endDate: null,
            jobRef: ''
        };

        this.setupForm(model);
    }

    public setupForm(value: JobFilters) {
        this.resetForm();
        this.formGroup = this.fb.group<JobFilters>({
            keywords: this.getControl(value.keywords, false),
            jobType: this.getControl(value.jobType, false),
            assets: this.getControl(value.assets, false),
            properties: this.getControl(value.properties, false),
            jobStatus: this.getControl(value.jobStatus, false),
            startDate: this.getControl(value.startDate, false),
            endDate: this.getControl(value.endDate, false),
            jobRef: this.getControl(value.jobRef, false),
        },
        );
    }
}


