import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { AuthService } from '../../auth/services/auth.service';
import { Router, NavigationStart, NavigationEnd, NavigationError, Event } from '@angular/router';
import { PropertyFilterService } from '../../services/property-filter-service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menuClose = false;
  fullName = '';
  tokenExp: any;
  route = '';
  propertyRef = '';
  hidePropertyFilter = false;
  constructor(
    private authService: AuthService,
    private navigateTo: NavigationService,
    private router: Router,
    private propertyFilterService: PropertyFilterService
  ) {

    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {

        this.route = this.router.url;
        if (this.route.includes('user') || this.route.includes('statement') || this.route.includes('/estimate/') || this.route.includes('jobs/') || this.route.includes('properties/') || this.route.includes('invoice') || this.route.includes('customers') || this.route.includes('/customReport')) {
          this.hidePropertyFilter = true;
        } else {
          this.hidePropertyFilter = false;
        }

      }
    });
  }

  ngOnInit() {
    this.fullName = this.authService.fullName;
  }
  async logout() {
    await this.authService.logout();
    this.propertyFilterService.setPropertyRef = '';
    this.navigateTo.login();
  }
  home() {
    this.navigateTo.home();
  }
}
