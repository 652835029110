/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReportModelOut } from '../models/report-model-out';
import { EmbedTokenModelOut } from '../models/embed-token-model-out';
@Injectable({
  providedIn: 'root',
})
class PowerBiService extends __BaseService {
  static readonly GetUserReportsAsyncPath = '/api/powerbi/reports';
  static readonly GetReportByIdAsyncPath = '/api/powerbi/reports/{reportId}';
  static readonly GetJobReportByJobIdAsyncPath = '/api/powerbi/jobreport/{jobId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a list of power bi reports for a user.
   * @param Authorization Access Token
   * @return Success
   */
  GetUserReportsAsyncResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<ReportModelOut>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/powerbi/reports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReportModelOut>>;
      })
    );
  }
  /**
   * Get a list of power bi reports for a user.
   * @param Authorization Access Token
   * @return Success
   */
  GetUserReportsAsync(Authorization: string): __Observable<Array<ReportModelOut>> {
    return this.GetUserReportsAsyncResponse(Authorization).pipe(
      __map(_r => _r.body as Array<ReportModelOut>)
    );
  }

  /**
   * @param params The `PowerBiService.GetReportByIdAsyncParams` containing the following parameters:
   *
   * - `reportId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetReportByIdAsyncResponse(params: PowerBiService.GetReportByIdAsyncParams): __Observable<__StrictHttpResponse<EmbedTokenModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/powerbi/reports/${encodeURIComponent(params.reportId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmbedTokenModelOut>;
      })
    );
  }
  /**
   * @param params The `PowerBiService.GetReportByIdAsyncParams` containing the following parameters:
   *
   * - `reportId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetReportByIdAsync(params: PowerBiService.GetReportByIdAsyncParams): __Observable<EmbedTokenModelOut> {
    return this.GetReportByIdAsyncResponse(params).pipe(
      __map(_r => _r.body as EmbedTokenModelOut)
    );
  }

  /**
   * @param params The `PowerBiService.GetJobReportByJobIdAsyncParams` containing the following parameters:
   *
   * - `jobId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetJobReportByJobIdAsyncResponse(params: PowerBiService.GetJobReportByJobIdAsyncParams): __Observable<__StrictHttpResponse<EmbedTokenModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/powerbi/jobreport/${encodeURIComponent(params.jobId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmbedTokenModelOut>;
      })
    );
  }
  /**
   * @param params The `PowerBiService.GetJobReportByJobIdAsyncParams` containing the following parameters:
   *
   * - `jobId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetJobReportByJobIdAsync(params: PowerBiService.GetJobReportByJobIdAsyncParams): __Observable<EmbedTokenModelOut> {
    return this.GetJobReportByJobIdAsyncResponse(params).pipe(
      __map(_r => _r.body as EmbedTokenModelOut)
    );
  }
}

module PowerBiService {

  /**
   * Parameters for GetReportByIdAsync
   */
  export interface GetReportByIdAsyncParams {
    reportId: string;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for GetJobReportByJobIdAsync
   */
  export interface GetJobReportByJobIdAsyncParams {
    jobId: number;

    /**
     * Access Token
     */
    Authorization: string;
  }
}

export { PowerBiService }
