import { Component, OnInit } from '@angular/core';
import { faFile, faCamera, faBriefcase, faCheck, faEdit, faTimes, faFlag } from '@fortawesome/free-solid-svg-icons';
import { JobService } from '../../api/services/job.service';
import { JobModelOut } from '../../api/models/job-model-out';
import { NavigationService } from '../../services/navigation.service';
import { AlertsService } from '../../services/alert.service';
import { AuthService } from '../../auth/services/auth.service';
import { JobFilters } from './models/job-filters';
import { NgxSpinnerService } from 'ngx-spinner';
import { PropertyFilterService } from '../../services/property-filter-service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  adminAccess = false;
  jobs: Array<JobModelOut>;
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  filters: JobFilters  = {
    keywords: '',
    jobType: '',
    assets: '',
    properties: '',
    jobStatus: '',
    startDate: null,
    endDate: null,
    jobRef: ''
  };
  keywords = '';
  jobType = '';
  asset = '';
  jobStatus = '';
  selectedJobStatus = '';
  startDate = '';
  endDate = '';
  properties = '';
  faCamera = faCamera;
  faEdit = faEdit;
  faFile = faFile;
  faBriefcase = faBriefcase;
  faCheck = faCheck;
  faTimes = faTimes;
  faFlag = faFlag;
  constructor(
    private jobService: JobService,
    private navigateTo: NavigationService,
    private spinner: NgxSpinnerService,
    private propertyFilterService: PropertyFilterService,
    private authService: AuthService) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
  }

  ngOnInit() {
    this.spinner.show();
  }

  filter(filters: JobFilters) {
    this.filters = filters;
    this.loadData();
  }
  loadData() {
    const params: JobService.GetJobsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      keywords: this.filters.keywords,
      jobTypeRef: this.filters.jobType,
      jobRef: this.filters.jobRef,
      assets: this.filters.assets,
      jobStatusRef: this.filters.jobStatus,
      projectRef: this.propertyFilterService.getPropertyRef,
      startDate: this.filters.startDate != null ? this.filters.startDate.toDateString() : null,
      endDate: this. filters.endDate != null ? this.filters.endDate.toDateString() : null
    };
    this.jobService.GetJobsPagedAsync(params).subscribe(response => {
      this.totalRecordCount = response.totalRecordCount;
      this.jobs = response.items;
      this.spinner.hide();
    });
  }
  details(jobId: number) {
    this.navigateTo.viewJobDetails(jobId);
  }
  pageChanged(page) {
    this.currentPage = page;
    this.loadData();
  }
}
