import { AllsopBaseForm } from '../../common/allsopBaseForm';
import { CustomerModelOut} from '../../api/models/customer-model-out';

export class CustomerEditForm extends AllsopBaseForm {
    constructor() {
        super();

        this.setUpForm();
    }
    public setUpForm() {
      this.resetForm();

      const model: any = {
        id: '0',
        customerName: '',
        billingAddressLine1: '',
        billingAddressLine2: '',
        billingAddressLine3: '',
        country: '',
        townCity: '',
        postCode: '',
        shortName: '',
        customerEmail: '',
        remittanceEmail: '',
        remittanceType: '',
        onHold: '',
        currencyCode: '',
      };
      this.setUpFormForEdit(model);
    }

    public setUpFormForEdit(modelOut: CustomerModelOut) {
        this.resetForm();

        this.formGroup = this.fb.group<CustomerModelOut>({
            id: this.getControl(modelOut.id),
            customerName: this.getControl(modelOut.customerName),
            billingAddressLine1: this.getControl(modelOut.billingAddressLine1),
            billingAddressLine2: this.getControl(modelOut.billingAddressLine2),
            billingAddressLine3: this.getControl(modelOut.billingAddressLine3),
            country: this.getControl(modelOut.country),
            townCity: this.getControl(modelOut.townCity),
            postCode: this.getControl(modelOut.postCode),
            shortName: this.getControl(modelOut.shortName),
            customerEmail: this.getControl(modelOut.customerEmail),
            remittanceEmail: this.getControl(modelOut.remittanceEmail),
            remittanceType: this.getControl(modelOut.remittanceType),
            onHold: this.getControl(modelOut.onHold),
            currencyCode: this.getControl(modelOut.currencyCode),
        });
    }
  }
