import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DebtorsTransactionModelOut } from '../../../api/models/debtors-transaction-model-out';
import { DebtorsTransactionService } from '../../../api/services/debtors-transaction.service';
import { faDownload, faTasks } from '@fortawesome/free-solid-svg-icons';
import { NavigationService } from '../../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { AppSettingsService } from '../../../services/app-settings.service';
import { DebtorsTransactionPagedSettings } from '../../../api/models/debtors-transaction-paged-settings';
import { PropertyService } from '../../../api/services/property.service';
import { PropertyModelOut } from '../../../api/models/property-model-out';
import { AlertsService } from '../../../services/alert.service';

@Component({
  selector: 'app-debtor-summary',
  templateUrl: './debtor-summary.component.html',
  styleUrls: ['./debtor-summary.component.scss']
})
export class DebtorSummaryComponent implements OnInit {
  adminAccess = false;
  debtorTransAvailable = false;
  faDownload = faDownload;
  faTasks = faTasks;
  financeReference = 0;
  totalOutstanding = 0;
  days30 = 0;
  days60 = 0;
  days90 = 0;
  currentTotal = 0;
  debtorTransactions: Array<DebtorsTransactionModelOut>;
  property: PropertyModelOut;
  totalDebtorRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  debtorRef = '';
  projectRef = '';
  propertyId = 0;
  custId = 0;
  customerInformation;
  logoUrl = '';
  todaysDate = new Date();
  constructor(
    private debtorService: DebtorsTransactionService,
    private propertyService: PropertyService,
    private navigateTo: NavigationService,
    private alert: AlertsService,
    private activatedRoute: ActivatedRoute,
    private appSettings: AppSettingsService
  ) { }

  ngOnInit() {
    this.listenForRouteParams();
  }

  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
     this.propertyId = params.id;
      this.loadDebtorProperty(params.id);
    });
  }


  loadDebtorProperty(projectRef: string) {
    const params: PropertyService.GetPropertyByProjectRefAsyncParams = {
      Authorization: '',
      projectRef : projectRef,
    };
    this.propertyService.GetPropertyByProjectRefAsync(params).subscribe(response => {
      this.property = response;
      this.projectRef = response.projectRef;
      this.loadDebtorsTransations();
    });
}


loadDebtorsTransations() {
  const params: DebtorsTransactionService.GetOutstandingDebtorsTransactionsPagedAsyncParams = {
    Authorization: '',
    pageNo: this.currentPage,
    pageSize: this.pageSize,
    projectRef: this.projectRef,
  };
  this.debtorService.GetOutstandingDebtorsTransactionsPagedAsync(params).subscribe(response => {
    this.totalDebtorRecordCount = response.totalRecordCount;
    this.totalOutstanding = response.totalOutstanding;
    this.currentTotal = response.currentTotal;
    this.days30 = response.day30Total;
    this.days60 = response.day60Total;
    this.days90 = response.day90Total;
    this.debtorTransactions = response.items;
  });
}


  transactionDetails(transactionRef: number) {
    this.navigateTo.transactionDetails(transactionRef);

  }

  pageDebtorChanged(debtorPage) {
    if (this.projectRef) {
      this.currentPage = debtorPage;
      this.loadDebtorsTransations();
    }
  }

  downloadDebtorStatementPDF() {
    const params: DebtorsTransactionService.GenerateDebtorsStatementPDFParams = {
      Authorization: '',
      logoUrl: this.appSettings.getLynchLogo,
      debtorsTransactionPagedSettings: this.debtorPdfModelIn()
    };
    this.debtorService.GenerateDebtorsStatementPDF(params).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
      FileSaver.saveAs(blob, 'Property:' + this.projectRef + `_DebtorStatement.pdf` );
    });
  }

  debtorPdfModelIn() {
    const modelIn: DebtorsTransactionPagedSettings = {
      projectRef: this.projectRef,
      debtorRef: this.debtorRef,
      pageNo: this.currentPage,
      pageSize: this.pageSize,
    };
    return modelIn;
  }
}
