import { Component, OnInit } from '@angular/core';
import { EstimateModelOut } from '../../api/models/estimate-model-out';
import { EstimateService } from '../../api/services/estimate.service';
import { NavigationService } from '../../services/navigation.service';
import { AuthService } from '../../auth/services/auth.service';
import { EstimateFilters } from './models/estimate-filters';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { AppSettingsService } from '../../services/app-settings.service';
import * as FileSaver from 'file-saver';
import { EstimateGroupByKeyModelOut } from '../../api/models/estimate-group-by-key-model-out';
import { PropertyFilterService } from '../../services/property-filter-service';
import * as moment from 'moment';

@Component({
  selector: 'app-estimates',
  templateUrl: './estimates.component.html',
  styleUrls: ['./estimates.component.scss']
})
export class EstimatesComponent implements OnInit {
  estimates: Array<EstimateModelOut>;
  adminAccess = false;
  faDownload = faDownload;
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 10;
  filters: EstimateFilters;
  keywords = '';
  logoUrl = '';


  constructor(
    private estimateService: EstimateService,
    private navigateTo: NavigationService,
    private authService: AuthService,
    private propertyFilterService: PropertyFilterService,
    private appSettings: AppSettingsService
  ) {
    this.adminAccess = this.authService.roleMatch(['Estimate']);
  }


  ngOnInit() {
    this.setFilterDateRange();
  }

  setFilterDateRange() {
    let sDate = new Date();
    sDate = (moment(sDate).subtract(1, 'years')).toDate();

    this.filters = {
      startDate: sDate,
      endDate: new Date()
    };
  }

  filter(filters: EstimateFilters) {
    this.filters = filters;
    this.loadEstimateData();
  }

  loadEstimateData() {
    const params: EstimateService.GetEstimatesPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      propertyRef: this.propertyFilterService.getPropertyRef,
      startDate: this.filters.startDate != null ? this.filters.startDate.toDateString() : null,
      endDate: this.filters.endDate != null ? this.filters.endDate.toDateString() : null

    };
    this.estimateService.GetEstimatesPagedAsync(params).subscribe(response => {
      this.totalRecordCount = response.totalRecordCount;
      this.estimates = response.items;
    });
  }

  pageChanged(page) {
    this.currentPage = page;
    this.loadEstimateData();
  }

  printPdf(estimate: EstimateGroupByKeyModelOut) {
    const params: EstimateService.GenerateSingleEstimatePDFParams = {
      Authorization: '',
      logoUrl: this.appSettings.getLynchLogo,
      modelIn: estimate
    };
    this.estimateService.GenerateSingleEstimatePDF(params).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
      FileSaver.saveAs(blob, `Estimate.pdf`);
    });
  }

  viewStatusDetails(formResultKey: number) {
    this.navigateTo.viewEstimateDataDetails(formResultKey);
  }

}
