import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerModelOut } from '../../../api/models/customer-model-out';
import { NavigationService } from '../../../services/navigation.service';
import { CustomerService } from '../../../api/services/customer.service';
import { CustomerEditForm } from '../customerForm';
import { MonitoringService } from '../../../services/monitoring.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-edit-customers',
  templateUrl: './edit-customers.component.html',
  styleUrls: ['./edit-customers.component.scss']
})
export class EditCustomersComponent implements OnInit {
  customerForm: CustomerEditForm;
  customerId = 0;
  isSubmitted = false;
  isReadOnly = true;
  title = 'Create Customer';
  constructor( private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private navigateTo: NavigationService,
    private toastr: ToastrService,
    private monitoringService: MonitoringService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef
    ) { }

  ngOnInit() {
    this.loadData(this.customerId);
  }

  loadData(id: number) {
    const params: CustomerService.GetCustomerByIdAsyncParams = {
      Authorization: '',
      customerId: id
    };
    this.customerService.GetCustomerByIdAsync(params).subscribe(response => {
      this.customerForm = new CustomerEditForm();
      this.customerForm.setUpFormForEdit(response);

    }, error => {
      this.monitoringService.logWebException(error.error, 1);
    });
  }

  back() {
    this.toastr.warning('No changes saved', 'CANCELLED');
    this.close();
    this.navigateTo.customers();
  }

  close() {
    this.bsModalRef.hide();
  }
}
