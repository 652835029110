import { Component, OnInit, Input } from '@angular/core';
import { faFile, faInfoCircle, faDownload, faCheck, faTimes, faFlag } from '@fortawesome/free-solid-svg-icons';
import { Router, NavigationEnd, Event, ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileUploadForm } from '../scFileUploadForm';
import { AlertsService } from '../../../services/alert.service';
import { SubContractorService } from '../../../api/services/sub-contractor.service';
import { JobDocumentationHeaderModel } from './jobDocumentationMode';
import { JobService } from '../../../api/services/job.service';
import { JobFileUploadForm } from '../jobFileUploadForm';
import { MonitoringService } from '../../../services/monitoring.service';
import { JobModelOut } from '../../../api/models/job-model-out';
import { JobDetailsViewService } from '../../../services/job-details-view-service';
import { JobForm } from '../jobForm';
import { AuthService } from '../../../auth/services/auth.service';
import { BulkDownloadService } from '../../../api/services/bulk-download.service';
import { BulkDownloadTypeEnum } from '../../../common/enums/bulkdownloadtype.enum';
import { FilesModelIn } from '../../../api/models/files-model-in';
import * as moment from 'moment';
import { JobDocumentModelOut } from '../../../api/models/job-document-model-out';
import { SubContractorDocumentModelOut } from '../../../api/models/sub-contractor-document-model-out';
@Component({
  selector: 'app-view-jobs',
  templateUrl: './view-jobs.component.html',
  styleUrls: ['./view-jobs.component.scss']
})
export class ViewJobsComponent implements OnInit {
  @Input() jobId = 0;

  /* -- Icons -- */
  faInfoCircle = faInfoCircle;
  faFile = faFile;
  faDownload = faDownload;
  faCheck = faCheck;
  faTimes = faTimes;
  faFlag = faFlag;

  /* -- Variables -- */
  Authorization: '';
  subcontractor = '';
  description = '';
  jobType = '';

  /* -- Paging Settings (Jobs) -- */
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;

  /* -- Paging Settings (SubContractor) -- */
  totalRecordCountSubContractor = 0;
  currentPageSubContractor = 1;
  pageSizeSubContractor = 20;

  /* -- Job Documentation Variables -- */
  documents: Array<JobDocumentationHeaderModel>;
  jobAttachments = [];
  subcontractorDocuments = [];
  selectedJobDocuments = [];
  selectedSubContractorDocuments = [];

  /* -- Forms -- */
  fileUploadFormJobs: JobFileUploadForm; // Job upload form
  fileUploadForm: FileUploadForm; // Sub Contactor upload form
  jobsForm: JobForm;
  job: JobModelOut;

  /*--  Auth/Permissions -- */
  allChecked = false;
  allCheckedJobs = false;
  adminAccess = false;
  jobIssueFound = false;
  finished = false;

  /* -- File Upload Settings -- */
  progress: any;
  acceptedFileTypes = ['.jpg', '.png', '.pdf', '.xlsx', '.docx', '.xlt'];
  fileTypeInvalid = false;
  fileTypeErrorMsg = 'Supported file types - ' + this.acceptedFileTypes.toString();
  fileSizeInvalid = false;
  fileSizeErrorMsg = 'Max. file size - 2MB';
  private file: File | null = null;

  /* -- Bulk Download -- */
  bulkDownloadType = BulkDownloadTypeEnum;

  constructor(
    private router: Router,
    private subContractorService: SubContractorService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private alertService: AlertsService,
    private jobService: JobService,
    private monitoringService: MonitoringService,
    private jobDetailsViewService: JobDetailsViewService,
    private bulkDownloadService: BulkDownloadService
  ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
    this.fileUploadForm = new FileUploadForm();
    this.fileUploadFormJobs = new JobFileUploadForm();
    this.activatedRoute.params.subscribe(params => {
      this.jobDetailsViewService.LoadCustomerRelatedData(params.id);
      this.loadJobInformation(params.id);
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
    this.listenForRouteParams();
    this.loadData();
  }

  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      this.jobId = params.id;
    });
  }

  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
      if (this.finished !== true) {
        this.spinner.show();
      }
    }, 5000);
  }


  loadJobInformation(id: number) {
    const params: JobService.GetJobByIdAsyncParams = {
      Authorization: '',
      jobId: id
    };

    this.jobService.GetJobByIdAsync(params).subscribe(response => {
      this.job = response;
      if (response.jobType === 'Sch Maintenance Visit') {
        this.jobIssueFound = true;
      } else {
        this.jobIssueFound = false;
      }
    }, error => {
      this.monitoringService.logWebException(error.error, 1);
    });
  }

  loadSubContractorAttachments() { // Load Sub Contractor Documents
    const params: SubContractorService.GetSubContractorDocumentsByJobIdPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      subContractorName: null,
      jobRef: null,
      jobId: this.jobId,
      dateOfUpload: null,
    };
    this.subContractorService.GetSubContractorDocumentsByJobIdPagedAsync(params).subscribe(response => {
      this.totalRecordCountSubContractor = response.totalRecordCount;
      this.subcontractorDocuments = response.items;
      this.spinner.hide();
    });
  }

  loadAttachments() { // Load Job Documents
    const params: JobService.GetJobDocumentsPagedAsyncParams = {
      Authorization: '',
      jobId: this.jobId,
      pageNo: this.currentPage,
      pageSize: this.pageSize,
    };
    this.jobService.GetJobDocumentsPagedAsync(params).subscribe(response => {
      this.jobAttachments = response.items;
      this.totalRecordCount = response.totalRecordCount;
      this.finished = true;
    });
  }

  loadData() {
    this.loadAttachments();
    this.loadSubContractorAttachments();
  }

  pageChanged(page) {
    this.currentPage = page;
    this.loadAttachments();
  }

  pageChangedSubContractor(page) {
    this.currentPage = page;
    this.loadSubContractorAttachments();
  }

  uploadComplete(e) {
    this.loadSubContractorAttachments();
    this.loadAttachments();
  }

  isPDF(attachmentURL: string) {
    return attachmentURL.includes('.pdf');
  }

  selectAllJobDocuments() {
    for (let i = 0; i < this.jobAttachments.length; i++) {
      if (!this.jobAttachments[i].selected) {
        this.jobAttachments[i].selected = true;
        this.selectedJobDocuments.push(this.jobAttachments[i]);
      } else {
        this.jobAttachments[i].selected = false;
        const index = this.selectedJobDocuments.indexOf(this.jobAttachments[i]);
        this.selectedJobDocuments.splice(index, 1);
      }
    }
  }

  selectAllSubContractor() {
    for (let i = 0; i < this.subcontractorDocuments.length; i++) {
      if (!this.subcontractorDocuments[i].selected) {
        this.subcontractorDocuments[i].selected = true;
        this.selectedSubContractorDocuments.push(this.subcontractorDocuments[i]);
      } else {
        this.subcontractorDocuments[i].selected = false;
        const index = this.selectedSubContractorDocuments.indexOf(this.subcontractorDocuments[i]);
        this.selectedSubContractorDocuments.splice(index, 1);
      }
    }
  }

  isCheckedJob(attachment, event) {
    const index = this.selectedJobDocuments.indexOf(attachment);
    if (index === -1) {
      if (event.target.checked) {
        this.selectedJobDocuments.push(attachment);
      }
    } else {
      this.selectedJobDocuments.splice(index, 1);
    }
  }

  isCheckedSubContractor(attachment, event) {
    const index = this.selectedSubContractorDocuments.indexOf(attachment);
    if (index === -1) {
      if (event.target.checked) {
        this.selectedSubContractorDocuments.push(attachment);
      }
    } else {
      this.selectedSubContractorDocuments.splice(index, 1);
    }
  }

  downloadSubContractorDocuments() {
    const filesModelIn = this.getFilesModelInParam(false);
    this.bulkDownload(filesModelIn, false);
    this.selectedSubContractorDocuments = [];
    this.allChecked = false;
    for (let i = 0; i < this.subcontractorDocuments.length; i++) {
      this.subcontractorDocuments[i].selected = false;
    }
  }

  downloadJobDocuments() {
    const filesModelIn = this.getFilesModelInParam(true);
    this.bulkDownload(filesModelIn, true);
    this.selectedJobDocuments = [];
    this.allCheckedJobs = false;
    for (let i = 0; i < this.jobAttachments.length; i++) {
      this.jobAttachments[i].selected = false;
    }
  }

  handleFile(event) {
    const file = event && event.target.files[0];
    this.file = file;
    this.checkFileType(file);
    this.checkFileSize(file);
  }

  handleFileJobs(event) {
    const file = event && event.target.files[0];
    this.file = file;
    this.checkFileType(file);
    this.checkFileSize(file);
  }

  checkFileType(file: File) {
    const extensionStart = file.name.lastIndexOf('.');
    const extension = file.name.substring(extensionStart);
    if (!this.acceptedFileTypes.includes(extension.toLowerCase())) {
      this.fileTypeInvalid = true;
    } else {
      this.fileTypeInvalid = false;
    }
  }

  checkFileSize(file: File) {
    const sizeInBytes = file.size;
    const sizeInMB = sizeInBytes / (1024 * 1024);
    if (sizeInMB > 2) {
      this.fileSizeInvalid = true;
    } else {
      this.fileSizeInvalid = false;
    }
  }

  uploadJobDocumentation(event) {
    this.fileUploadFormJobs.isSubmitted = true;
    if (this.fileUploadFormJobs.form.valid && !this.fileSizeInvalid && !this.fileTypeInvalid) {
      const saveModel = this.fileUploadFormJobs.getSaveModel(this.jobId, this.file);
      this.jobService.AddDocumentToJobs(saveModel).subscribe(response => {
        if (response) {
          this.alertService.fileUploaded();
          this.fileUploadFormJobs.setUpForm();
          this.file = null;
          this.loadAttachments();
          window.location.reload();
        }
      });
    } else {
      this.alertService.fileValidation();
    }
  }

  uploadSubContractor(event) {
    this.fileUploadForm.isSubmitted = true;
    if (this.fileUploadForm.form.valid && !this.fileSizeInvalid && !this.fileTypeInvalid) {

      const saveModel = this.fileUploadForm.getSaveModel(this.jobId, this.file);

      this.subContractorService.UploadSubContractorDocument(saveModel).subscribe(response => {
        if (response) {
          this.alertService.fileUploaded();
          this.fileUploadForm.setUpForm();
          this.file = null;
          this.loadSubContractorAttachments();
          window.location.reload();
        }
      });
    } else {
      this.alertService.fileValidation();
    }
  }

  back() {
    window.history.back();
  }

  selectedJobDocumentsLength() {
    return this.selectedJobDocuments.length > 0 ? `(${this.selectedJobDocuments.length})` : null;
  }

  selectedSubContractorDocumentsLength() {
    return this.selectedSubContractorDocuments.length > 0 ? `(${this.selectedSubContractorDocuments.length})` : null;
  }

  bulkDownloadAll(isJobDocuments: boolean) {
    const total = isJobDocuments ? this.totalRecordCount : this.totalRecordCountSubContractor;
    if (confirm(`Are you sure you want to download all files? (${total})`)) {
      const params: BulkDownloadService.BulkDownloadAllAsyncParams = {
        Authorization: '',
        modelIn: {
          jobId: this.jobId,
          jobRef: this.job.jobRef,
          type: isJobDocuments ? this.bulkDownloadType.job : this.bulkDownloadType.subcontractor
        }
      };
      this.toastr.success('Bulk download running in background. This might take a few mins to download.');
      this.bulkDownloadService.BulkDownloadAllAsync(params).subscribe(response => {
        const blob: any = new Blob([response], { type: 'application/zip' });
        const type = isJobDocuments ? 'Job Documents' : 'SubContractor Documents';
        const fileName = this.getZipFileName();
        FileSaver.saveAs(blob, fileName);
      });
    }
  }

  bulkDownload(filesModelIn: FilesModelIn[], isJobDocuments: boolean) {
    const params: BulkDownloadService.BulkDownloadSelectedAsyncParams = {
      Authorization: '',
      modelIn: {
        files: filesModelIn,
        type: isJobDocuments ? this.bulkDownloadType.job : this.bulkDownloadType.subcontractor
      }
    };
    this.toastr.success('Bulk download running in background. This might take a few mins to download.');
    this.bulkDownloadService.BulkDownloadSelectedAsync(params).subscribe(response => {
      const blob: any = new Blob([response], { type: 'application/zip' });
      const fileName = this.getZipFileName();
      FileSaver.saveAs(blob, fileName);
    });
  }

  getFilesModelInParam(isJobDocuments: boolean) {
    let filesModelIn: FilesModelIn[] = [];
    if (isJobDocuments) {
      filesModelIn = this.selectedJobDocuments.map(doc => ({
        id: doc.id,
        originalFileName: doc.name,
        convertedFileName: this.getJobConvertedFileName(doc),
        url: doc.url,
      }));
    } else {
      filesModelIn = this.selectedSubContractorDocuments.map(doc => ({
        id: doc.id,
        originalFileName: doc.name,
        convertedFileName: this.getSubContractorConvertedFileName(doc),
        url: doc.url,
      }));
    }
    return filesModelIn;
  }

  getJobConvertedFileName(doc: JobDocumentModelOut) {
    const description = doc.totalMobile === true ? doc.documentDescription : doc.description;
    return `Interact-${description}-${doc.jobRef}-${doc.documentRef}-${moment(doc.createdDate).format('DDMMyyyy')}`;
  }

  getSubContractorConvertedFileName(doc: SubContractorDocumentModelOut) {
    return `${doc.subContractorName}-${doc.documentDescription}-${doc.documentRef}`;
  }

  getZipFileName() {
    return `${this.job.propertyRef}-${this.job.jobRef}-${this.job.jobType}-${moment().format('DDMMyyyy')}${moment().format('HHmmss')}`;
  }
}
