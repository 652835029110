/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DebtorsTransactionsPagedModelOut } from '../models/debtors-transactions-paged-model-out';
import { DebtorsTransactionModelOut } from '../models/debtors-transaction-model-out';
import { DebtorsTransactionPagedSettings } from '../models/debtors-transaction-paged-settings';
@Injectable({
  providedIn: 'root',
})
class DebtorsTransactionService extends __BaseService {
  static readonly GetDebtorsTransactionsPagedAsyncPath = '/api/debtorsTransaction/paged';
  static readonly GetOutstandingDebtorsTransactionsPagedAsyncPath = '/api/debtorsTransaction/paged/outstanding';
  static readonly GetDebtorsTransactionByIdAsyncPath = '/api/debtorsTransaction/{debtorsTransactionId}';
  static readonly GenerateDebtorsTransactionPDFPath = '/api/debtorsTransaction/debtortransaction/pdf';
  static readonly GenerateDebtorsStatementPDFPath = '/api/debtorsTransaction/debtorstatement/pdf';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Debtors Transactions Paged
   * @param params The `DebtorsTransactionService.GetDebtorsTransactionsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `transactionStatus`:
   *
   * - `transactionRef`:
   *
   * - `transactionDate`:
   *
   * - `startDate`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `jobRef`:
   *
   * - `endDate`:
   *
   * - `debtorRef`:
   *
   * @return Success
   */
  GetDebtorsTransactionsPagedAsyncResponse(params: DebtorsTransactionService.GetDebtorsTransactionsPagedAsyncParams): __Observable<__StrictHttpResponse<DebtorsTransactionsPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.transactionStatus != null) __params = __params.set('transactionStatus', params.transactionStatus.toString());
    if (params.transactionRef != null) __params = __params.set('transactionRef', params.transactionRef.toString());
    if (params.transactionDate != null) __params = __params.set('transactionDate', params.transactionDate.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.projectRef != null) __params = __params.set('projectRef', params.projectRef.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    if (params.jobRef != null) __params = __params.set('jobRef', params.jobRef.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.debtorRef != null) __params = __params.set('debtorRef', params.debtorRef.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/debtorsTransaction/paged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DebtorsTransactionsPagedModelOut>;
      })
    );
  }
  /**
   * Get Debtors Transactions Paged
   * @param params The `DebtorsTransactionService.GetDebtorsTransactionsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `transactionStatus`:
   *
   * - `transactionRef`:
   *
   * - `transactionDate`:
   *
   * - `startDate`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `jobRef`:
   *
   * - `endDate`:
   *
   * - `debtorRef`:
   *
   * @return Success
   */
  GetDebtorsTransactionsPagedAsync(params: DebtorsTransactionService.GetDebtorsTransactionsPagedAsyncParams): __Observable<DebtorsTransactionsPagedModelOut> {
    return this.GetDebtorsTransactionsPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as DebtorsTransactionsPagedModelOut)
    );
  }

  /**
   * Get Outstanding Debtors Transactions Paged
   * @param params The `DebtorsTransactionService.GetOutstandingDebtorsTransactionsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `transactionStatus`:
   *
   * - `transactionRef`:
   *
   * - `transactionDate`:
   *
   * - `startDate`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `jobRef`:
   *
   * - `endDate`:
   *
   * - `debtorRef`:
   *
   * @return Success
   */
  GetOutstandingDebtorsTransactionsPagedAsyncResponse(params: DebtorsTransactionService.GetOutstandingDebtorsTransactionsPagedAsyncParams): __Observable<__StrictHttpResponse<DebtorsTransactionsPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.transactionStatus != null) __params = __params.set('transactionStatus', params.transactionStatus.toString());
    if (params.transactionRef != null) __params = __params.set('transactionRef', params.transactionRef.toString());
    if (params.transactionDate != null) __params = __params.set('transactionDate', params.transactionDate.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.projectRef != null) __params = __params.set('projectRef', params.projectRef.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    if (params.jobRef != null) __params = __params.set('jobRef', params.jobRef.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.debtorRef != null) __params = __params.set('debtorRef', params.debtorRef.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/debtorsTransaction/paged/outstanding`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DebtorsTransactionsPagedModelOut>;
      })
    );
  }
  /**
   * Get Outstanding Debtors Transactions Paged
   * @param params The `DebtorsTransactionService.GetOutstandingDebtorsTransactionsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `transactionStatus`:
   *
   * - `transactionRef`:
   *
   * - `transactionDate`:
   *
   * - `startDate`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `jobRef`:
   *
   * - `endDate`:
   *
   * - `debtorRef`:
   *
   * @return Success
   */
  GetOutstandingDebtorsTransactionsPagedAsync(params: DebtorsTransactionService.GetOutstandingDebtorsTransactionsPagedAsyncParams): __Observable<DebtorsTransactionsPagedModelOut> {
    return this.GetOutstandingDebtorsTransactionsPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as DebtorsTransactionsPagedModelOut)
    );
  }

  /**
   * Get Debtors Transaction by Id
   * @param params The `DebtorsTransactionService.GetDebtorsTransactionByIdAsyncParams` containing the following parameters:
   *
   * - `debtorsTransactionId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetDebtorsTransactionByIdAsyncResponse(params: DebtorsTransactionService.GetDebtorsTransactionByIdAsyncParams): __Observable<__StrictHttpResponse<DebtorsTransactionModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/debtorsTransaction/${encodeURIComponent(params.debtorsTransactionId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DebtorsTransactionModelOut>;
      })
    );
  }
  /**
   * Get Debtors Transaction by Id
   * @param params The `DebtorsTransactionService.GetDebtorsTransactionByIdAsyncParams` containing the following parameters:
   *
   * - `debtorsTransactionId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetDebtorsTransactionByIdAsync(params: DebtorsTransactionService.GetDebtorsTransactionByIdAsyncParams): __Observable<DebtorsTransactionModelOut> {
    return this.GetDebtorsTransactionByIdAsyncResponse(params).pipe(
      __map(_r => _r.body as DebtorsTransactionModelOut)
    );
  }

  /**
   * Transaction Details Pdf
   * @param params The `DebtorsTransactionService.GenerateDebtorsTransactionPDFParams` containing the following parameters:
   *
   * - `modelIn`:
   *
   * - `Authorization`: Access Token
   *
   * - `logoUrl`:
   *
   * @return Success
   */
  GenerateDebtorsTransactionPDFResponse(params: DebtorsTransactionService.GenerateDebtorsTransactionPDFParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.modelIn;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.logoUrl != null) __params = __params.set('logoUrl', params.logoUrl.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/debtorsTransaction/debtortransaction/pdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Transaction Details Pdf
   * @param params The `DebtorsTransactionService.GenerateDebtorsTransactionPDFParams` containing the following parameters:
   *
   * - `modelIn`:
   *
   * - `Authorization`: Access Token
   *
   * - `logoUrl`:
   *
   * @return Success
   */
  GenerateDebtorsTransactionPDF(params: DebtorsTransactionService.GenerateDebtorsTransactionPDFParams): __Observable<Blob> {
    return this.GenerateDebtorsTransactionPDFResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Debtor Statement Pdf
   * @param params The `DebtorsTransactionService.GenerateDebtorsStatementPDFParams` containing the following parameters:
   *
   * - `debtorsTransactionPagedSettings`:
   *
   * - `Authorization`: Access Token
   *
   * - `logoUrl`:
   *
   * @return Success
   */
  GenerateDebtorsStatementPDFResponse(params: DebtorsTransactionService.GenerateDebtorsStatementPDFParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.debtorsTransactionPagedSettings;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.logoUrl != null) __params = __params.set('logoUrl', params.logoUrl.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/debtorsTransaction/debtorstatement/pdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Debtor Statement Pdf
   * @param params The `DebtorsTransactionService.GenerateDebtorsStatementPDFParams` containing the following parameters:
   *
   * - `debtorsTransactionPagedSettings`:
   *
   * - `Authorization`: Access Token
   *
   * - `logoUrl`:
   *
   * @return Success
   */
  GenerateDebtorsStatementPDF(params: DebtorsTransactionService.GenerateDebtorsStatementPDFParams): __Observable<Blob> {
    return this.GenerateDebtorsStatementPDFResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module DebtorsTransactionService {

  /**
   * Parameters for GetDebtorsTransactionsPagedAsync
   */
  export interface GetDebtorsTransactionsPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    transactionStatus?: string;
    transactionRef?: string;
    transactionDate?: string;
    startDate?: string;
    projectRef?: string;
    keywords?: string;
    jobRef?: string;
    endDate?: string;
    debtorRef?: string;
  }

  /**
   * Parameters for GetOutstandingDebtorsTransactionsPagedAsync
   */
  export interface GetOutstandingDebtorsTransactionsPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    transactionStatus?: string;
    transactionRef?: string;
    transactionDate?: string;
    startDate?: string;
    projectRef?: string;
    keywords?: string;
    jobRef?: string;
    endDate?: string;
    debtorRef?: string;
  }

  /**
   * Parameters for GetDebtorsTransactionByIdAsync
   */
  export interface GetDebtorsTransactionByIdAsyncParams {
    debtorsTransactionId: number;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for GenerateDebtorsTransactionPDF
   */
  export interface GenerateDebtorsTransactionPDFParams {
    modelIn: Array<DebtorsTransactionModelOut>;

    /**
     * Access Token
     */
    Authorization: string;
    logoUrl?: string;
  }

  /**
   * Parameters for GenerateDebtorsStatementPDF
   */
  export interface GenerateDebtorsStatementPDFParams {
    debtorsTransactionPagedSettings: DebtorsTransactionPagedSettings;

    /**
     * Access Token
     */
    Authorization: string;
    logoUrl?: string;
  }
}

export { DebtorsTransactionService }
