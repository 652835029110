import { Component, OnInit, Input } from '@angular/core';
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../auth/services/auth.service';
import { HealthAndSafetyService } from '../../api/services/health-and-safety.service';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { DocumentFilters } from './models/documentFilters';
import { NgxSpinnerService } from 'ngx-spinner';
import { PropertyFilterService } from '../../services/property-filter-service';
import { BulkDownloadService } from '../../api/services/bulk-download.service';
import { BulkDownloadTypeEnum } from '../../common/enums/bulkdownloadtype.enum';
import * as moment from 'moment';
import { FilesModelIn } from '../../api/models/files-model-in';
@Component({
  selector: 'app-health-and-safety-documents',
  templateUrl: './health-and-safety-documents.component.html',
  styleUrls: ['./health-and-safety-documents.component.scss']
})
export class HealthAndSafetyDocumentsComponent implements OnInit {
  @Input() documentId = 0;
  selectedAll: any;
  fileURL = 'attachment.url';
  healthAndSafetyDocuments = [];
  faFile = faFile;
  faDownload = faDownload;
  adminAccess = false;
  selectedDocuments = [];
  allChecked = false;
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  filters: DocumentFilters = {
    keywords: '',
    propertyName: '',
    dateOfUpload: null,
    supplier: '',
    documentName: '',
    description: '',
    expiryDate: null
  };
  supplier = '';
  propertyRef = '';
  keywords = '';
  dateOfUpload = '';
  expiryDate: '';
  bulkDownloadType = BulkDownloadTypeEnum;

  constructor(
    private authService: AuthService,
    private healthAndSafetyService: HealthAndSafetyService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private propertyFilterService: PropertyFilterService,
    private bulkDownloadService: BulkDownloadService
  ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
  }

  ngOnInit() {
    this.spinner.show();
  }

  filter(filters: DocumentFilters) {
    this.filters = filters;
    this.loadAttachments();
  }

  loadAttachments() {
    const params: HealthAndSafetyService.GetHealthAndSafetyDocumentsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      supplier: this.filters.supplier,
      projectRef: this.propertyFilterService.getPropertyRef,
      documentName: this.filters.documentName,
      description: this.filters.description,
      dateOfUpload: this.filters.dateOfUpload != null ? this.filters.dateOfUpload.toDateString() : null,
      expiryDate: this.filters.expiryDate != null ? this.filters.expiryDate.toDateString() : null
    };
    this.healthAndSafetyService.GetHealthAndSafetyDocumentsPagedAsync(params).subscribe(response => {
      this.totalRecordCount = response.totalRecordCount;
      this.healthAndSafetyDocuments = response.items;
      this.spinner.hide();
    });
  }

  pageChanged(page) {
    this.currentPage = page;
    this.loadAttachments();
  }

  isPDF(attachmentURL: string) {
    return attachmentURL.includes('.pdf');
  }


  selectAll() {
    for (let i = 0; i < this.healthAndSafetyDocuments.length; i++) {
      if (!this.healthAndSafetyDocuments[i].selected) {
        this.healthAndSafetyDocuments[i].selected = true;
        this.selectedDocuments.push(this.healthAndSafetyDocuments[i]);
      } else {
        this.healthAndSafetyDocuments[i].selected = false;
        const index = this.selectedDocuments.indexOf(this.healthAndSafetyDocuments[i]);
        this.selectedDocuments.splice(index, 1);
      }
    }
  }

  isChecked(attachment, event) {
    const index = this.selectedDocuments.indexOf(attachment);
    if (index === -1) {
      if (event.target.checked) {
        this.selectedDocuments.push(attachment);
      }
    } else {
      this.selectedDocuments.splice(index, 1);
    }
  }

  download() {
    const filesModelIn = this.getFilesModelInParam();
    this.bulkDownload(filesModelIn);
    this.selectedDocuments = [];
    this.allChecked = false;
    for (let i = 0; i < this.healthAndSafetyDocuments.length; i++) {
      this.healthAndSafetyDocuments[i].selected = false;
    }
  }

  bulkDownload(filesModelIn: FilesModelIn[]) {
    const params: BulkDownloadService.BulkDownloadSelectedAsyncParams = {
      Authorization: '',
      modelIn: { files: filesModelIn, type: this.bulkDownloadType.healthAndSafety }
    };
    this.toastr.success('Bulk download running in background. This might take a few mins to download.');
    this.bulkDownloadService.BulkDownloadSelectedAsync(params).subscribe(response => {
      const blob: any = new Blob([response], { type: 'application/zip' });
      const fileName = `Health & Safety Documents-${moment(new Date()).format('DDMMyyyy')}`;
      FileSaver.saveAs(blob, fileName);
    });
  }

  bulkDownloadAll() {
    if (confirm(`Are you sure to download all files (${this.totalRecordCount})?`)) {
      const params: BulkDownloadService.BulkDownloadAllAsyncParams = {
        Authorization: '',
        modelIn: {
          type: this.bulkDownloadType.healthAndSafety
        }
      };
      this.toastr.success('Bulk download running in background. This might take a few mins to download.');
      this.bulkDownloadService.BulkDownloadAllAsync(params).subscribe(response => {
        const blob: any = new Blob([response], { type: 'application/zip' });
        const fileName = `Health & Safety Documents-${moment().format('DDMMyyyy')}${moment().format('HHmmss')}`;
        FileSaver.saveAs(blob, fileName);
      });
    }
  }

  getFilesModelInParam() {
    return this.selectedDocuments.map(doc => ({
      id: doc.id,
      originalFileName: doc.name,
      convertedFileName: `${doc.supplier}-${doc.propertyRef}-${doc.name}-${doc.documentRef}`,
      url: doc.url,
    }));
  }

  selectedLength() {
    return this.selectedDocuments.length > 0 ? `(${this.selectedDocuments.length})` : '';
  }
}
