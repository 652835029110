import { Component, OnInit } from '@angular/core';
import { UserEditForm } from '../usersForm';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../../../api/services/account.service';
import { NavigationService } from '../../../../services/navigation.service';
import { DropdownModelOutString } from '../../../../api/models/dropdown-model-out-string';
import { AlertsService } from '../../../../services/alert.service';
import { UserModelOut } from '../../../../api/models/user-model-out';
import { noop, Observable, Observer, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CustomerNamesPagedModelOut } from '../../../../api/models/customer-names-paged-model-out';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { AnalysisCodesPagedModelOut } from '../../../../api/models/analysis-codes-paged-model-out';
import { DropdownModelOutInt32 } from '../../../../api/models/dropdown-model-out-int-32';

@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.scss']
})
export class EditUsersComponent implements OnInit {
  pageTitle = 'Create User';
  userForm: UserEditForm;
  isSubmitted = false;
  loadingRoles: Array<DropdownModelOutString>;
  reportGroupIds: Array<DropdownModelOutInt32>;
  userId = '';
  customers: Array<CustomerNamesPagedModelOut>;
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  suggestions$: Observable<AnalysisCodesPagedModelOut[]>;
  errorMessage: string;
  hideCodeList = true;
  constructor(
    private analysisService: AccountService, // Analysis Code service held in Account Controller on API
    private userService: AccountService,
    private navigateTo: NavigationService,
    private alertService: AlertsService,
    private activatedRoute: ActivatedRoute) {

    this.userForm = new UserEditForm();
  }
  ngOnInit(): void {

    this.listenForRouteParams();
    this.loadRoles();
    this.loadReportGroups();

    this.suggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.userForm.form.value.analysisCode);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          const params: AccountService.GetAnalysisCodesPagedAsyncParams = {
            Authorization: '',
            pageNo: this.currentPage,
            pageSize: this.pageSize,
            code: this.userForm.form.value.analysisCode

          };

          return this.analysisService.GetAnalysisCodesPagedAsync(params)
            .pipe(
              map((data: AnalysisCodesPagedModelOut) => data.items.map(
                (s) => ({ analysisCode: s.code })) || []),
              tap(() => noop, err => {
                this.errorMessage = err && err.message || 'Unable to find matching analysis code';
              })
            );
        }

        this.alertService.error('Unable to find matching analysis code');

        return of([]);
      })
    );
  }
  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.pageTitle = 'Edit User';
        this.userId = params.id;
        this.loadUser(params.id);
      }
    });
  }
  loadRoles() {
    this.userService.GetRoles('').subscribe(response => {
      this.loadingRoles = response;
    });
  }
  loadReportGroups() {
    this.userService.GetReportGroupsDropdownAsync('').subscribe(response => {
      this.reportGroupIds = response;
    });
  }
  roleChange(e) {
    this.displayCodes(e.target.value);
  }
  displayCodes(rolename) {
    if (rolename === 'Customer') {
      this.hideCodeList = false;
    } else {
      this.hideCodeList = true;
    }
  }

  onSelect(event: TypeaheadMatch): void {
    this.userForm.form.value.analysisCode = event.item.analysisCode;
  }
  loadUser(id: string) {
    const params: AccountService.GetUserByIdAsyncParams = {
      Authorization: '',
      id: id
    };

    this.userService.GetUserByIdAsync(params)
      .subscribe((response: UserModelOut) => {
        this.displayCodes(response.roleId);
        this.userForm.setUpFormForEdit(response);
      });
  }
  save() {
    this.userForm.isSubmitted = true;

    if (this.userForm.form.valid) {

      if (this.userId !== '') {
        if (this.userForm.form.value.roleId === 'Customer' && this.userForm.form.value.analysisCode === null) {
          this.alertService.error('Please select a matching Analysis Code');
        } else {
          const saveModel = this.userForm.getSaveModel(this.userId);

          this.userService.UpdateUserAsync(saveModel).subscribe(response => {
            this.alertService.success('');
            this.navigateTo.users();
          });
        }
      }
    }
  }
  back() {
    this.navigateTo.users();
  }
}

