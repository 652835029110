import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { faCheck, faTimes, faFlag } from '@fortawesome/free-solid-svg-icons';
import { PropertyService } from './../../../api/services/property.service';
import { ActivatedRoute } from '@angular/router';
import { PropertyModelOut } from '../../../api/models/property-model-out';
import { JobFilters } from '../../jobs/models/job-filters';
import { JobService } from '../../../api/services/job.service';
import { JobModelOut } from '../../../api/models/job-model-out';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-property-jobs',
  templateUrl: './property-jobs.component.html',
  styleUrls: ['./property-jobs.component.scss']
})
export class PropertyJobsComponent implements OnInit {
  jobsAvailable = false;
  faFlag = faFlag;
  faCheck = faCheck;
  faTimes = faTimes;
  propertyId = 0;
  projectRef = '';
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  name = '';
  reference = '';
  property: PropertyModelOut;
  filters: JobFilters = {
    keywords: '',
    jobType: '',
    assets: '',
    properties: '',
    jobStatus: '',
    startDate: null,
    endDate: null,
    jobRef: ''
  };
  jobsList: Array<JobModelOut>;
  keywords = '';
  jobType = '';
  asset = '';
  jobStatus = '';
  selectedJobStatus = '';
  properties = '';
  constructor(
    private propertyService: PropertyService,
    private jobService: JobService,
    private navigateTo: NavigationService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.listenForRouteParams();
  }

  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      this.propertyId = params.id;
      this.loadPropertyReference(params.id);
    });
  }

  loadPropertyReference(projectRef: string) {
    const params: PropertyService.GetPropertyByProjectRefAsyncParams = {
      Authorization: '',
      projectRef: projectRef,
    };
    this.propertyService.GetPropertyByProjectRefAsync(params).subscribe(response => {
      this.property = response;
      this.projectRef = response.projectRef;
      this.loadData(this.projectRef);
    });
  }

  filter(filters: JobFilters) {
    this.filters = filters;
    this.loadData(this.projectRef);
  }

  loadData(propertyRef) {
    const params: JobService.GetJobsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      keywords: this.filters.keywords,
      jobTypeRef: this.filters.jobType,
      jobRef: this.filters.jobRef,
      assets: this.filters.assets,
      jobStatusRef: this.filters.jobStatus,
      startDate: this.filters.startDate != null ? this.filters.startDate.toDateString() : null,
      endDate: this. filters.endDate != null ? this.filters.endDate.toDateString() : null,
      projectRef: propertyRef
    };
    this.jobService.GetJobsPagedAsync(params).subscribe(response => {
      this.totalRecordCount = response.totalRecordCount;
      this.jobsList = response.items;
      if (this.jobsList.length > 0) {
        this.jobsAvailable = true;
      }
    });
  }
  details(jobId: number) {
    this.navigateTo.viewJobDetails(jobId);
  }
  pageJobsChanged(jobPage) {
    this.currentPage = jobPage;
    if (this.projectRef) {
      this.loadData(this.projectRef);
    }
  }
}
