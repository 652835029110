import { AllsopBaseForm } from '../../common/allsopBaseForm';
import { HealthAndSafetyService } from '../../api/services/health-and-safety.service';
import { HSUploadForm } from './models/hs-upload-form';
import { FormGroup } from '@angular/forms';
import { AlertsService } from '../../services/alert.service';


export class FileUploadForm extends AllsopBaseForm {

    constructor() {
        super();
        this.setUpForm();
    }
    public setUpForm() {
      this.resetForm();

      const model: any = {
        propertyId: 0,
        supplier: '',
        description: '',
        expiryDate: null,
        file: File = null
      };
      this.setUpFormForEdit(model);
    }

    public setUpFormForEdit(value: HSUploadForm) {
        this.resetForm();

        this.formGroup = this.fb.group<HSUploadForm>({
            propertyId: this.getControl(value.propertyId, false),
            supplier: this.getControl(value.supplier, false),
            description: this.getControl(value.description, false),
            expiryDate: this.getControl(value.expiryDate, false),
            file: this.getControl(value.file, true)
        });
    }

    getSaveModel(propertyId: number, file: File): HealthAndSafetyService.UploadHealthAndSafetyDocumentParams {
        const value: HSUploadForm = this.formGroup.value;
        return {
            Authorization: '',
            supplier: value.supplier,
            propertyId: propertyId,
            file0: file,
            expiryDate: value.expiryDate != null ? value.expiryDate.toDateString() : null,
            description: value.description

        };
    }
}
