import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../../services/navigation.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PropertyService } from '../../../api/services/property.service';
import { AccountService } from '../../../api/services/account.service';
import { AlertsService } from '../../../services/alert.service';
import { AssignAccountManagerForm } from '../assign-accountmanager/assign-accountManagerForm';

@Component({
  selector: 'app-unassign-accountmanager',
  templateUrl: './unassign-accountmanager.component.html',
  styleUrls: ['./unassign-accountmanager.component.scss']
})
export class UnassignAccountmanagerComponent implements OnInit {
  assignAccountManagerForm: AssignAccountManagerForm;
  propertyIds = [];
  isSubmitted = false;
  isReadOnly = true;
  constructor( private activatedRoute: ActivatedRoute,
    private navigateTo: NavigationService,
    private toastr: ToastrService,
    private monitoringService: MonitoringService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private userService: AccountService,
    private alertService: AlertsService,
    private propertyService: PropertyService
    ) { }

  ngOnInit() {
    this.assignAccountManagerForm = new AssignAccountManagerForm();
  }


  unassignAccountManager() {
    const params = this.assignAccountManagerForm.getCreateSaveModel(this.propertyIds);
    this.propertyService.UnassignAccountManagers(params).subscribe((response) => {
      this.alertService.success('Saved');
      this.close();
      window.location.reload();
      this.navigateTo.properties();
    });
  }

  back() {
    this.toastr.warning('No changes saved', 'CANCELLED');
    this.close();
  }

  close() {
    this.bsModalRef.hide();
  }
}
