import { Component, OnInit, Input } from '@angular/core';
import { faInfoCircle, faSearchLocation, faStickyNote, faFlag, faCheck, faTimes, faTasks, faClock, faFile, faDownload } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../auth/services/auth.service';
import { JobService } from '../../../api/services/job.service';
import { AssetService } from '../../../api/services/asset.service';
import { AssetFilters } from '../../assets/models/asset-filters';
import { AssetModelOut } from '../../../api/models/asset-model-out';
import { AssetModalService } from '../../assets/asset.modal.services';
import { NavigationService } from '../../../services/navigation.service';
import { AppSettingsService } from '../../../services/app-settings.service';
import { JobModelOut } from '../../../api/models/job-model-out';
import { NgxSpinnerService } from 'ngx-spinner';
import { DebtorsTransactionPagedSettings } from '../../../api/models/debtors-transaction-paged-settings';
import { JobFilters } from '../../jobs/models/job-filters';
import { DebtorsTransactionModelOut } from '../../../api/models/debtors-transaction-model-out';
import { DebtorsTransactionService } from '../../../api/services/debtors-transaction.service';
import { ActivatedRoute } from '@angular/router';
import { PropertyService } from '../../../api/services/property.service';
import { PropertyModelOut } from '../../../api/models/property-model-out';
import { PropertyViewServiceService } from '../services/property-view-service.service';
import { AlertsService } from '../../../services/alert.service';
import { CreateNotesForm } from '../property-notes-tab/createNotesForm';
import { PropertyFileUploadForm } from '../propertyFileUploadForm';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import { FileUploadForm } from '../hsFileUploadForm';
import { HealthAndSafetyService } from '../../../api/services/health-and-safety.service';

@Component({
  selector: 'app-property-tabs',
  templateUrl: './property-tabs.component.html',
  styleUrls: ['./property-tabs.component.scss']
})
export class PropertyTabsComponent implements OnInit {
  @Input() jobId = 0;
  @Input() propertyIdentity = 0;

  /* -- Icons -- */
  faInfoCircle = faInfoCircle;
  faSearchLocation = faSearchLocation;
  faStickyNote = faStickyNote;
  faClock = faClock;
  faFile = faFile;
  faDownload = faDownload;
  faFlag = faFlag;
  faCheck = faCheck;
  faTimes = faTimes;
  faTasks = faTasks;

  /*--  Paged Settings -- */
  totalRecordCount = 0;
  totalJobRecordCount = 0;
  totalDebtorRecordCount = 0;
  totalAssetRecordCount = 0;
  totalHealthSafetyRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  currentJobPage = 1;

  /*--  Auth/Permissions -- */
  adminAccess = false;
  isSubmitted = false;
  notesAvailable = false;
  docsAvailable = false;
  showUploadControl = false;
  allChecked = false;
  fileTypeInvalid = false;
  fileSizeInvalid = false;
  jobsAvailable = false;
  debtorTransAvailable = false;
  showPropertyRefFilter = false;
  assetsAvailable = false;
  showLoader = true;
  hsDocsAvailable = false;
  finished = false;

  /* -- Forms -- */
  noteForm: CreateNotesForm;
  fileUploadForm: PropertyFileUploadForm;
  healthSafetyUploadForm: FileUploadForm;

  /* -- Models -- */
  property: PropertyModelOut;
  jobsList: Array<JobModelOut>;
  debtorTransactions: Array<DebtorsTransactionModelOut>;
  assetsList: Array<AssetModelOut>;

  /* -- Variables -- */
  projectRef = '';
  propertyName = '';
  debtorRef = '';
  keywords = '';
  jobType = '';
  asset = '';
  supplier = '';
  description = '';
  expiryDate = null;
  hsDocs = [];
  jobStatus = '';
  selectedJobStatus = '';
  properties = '';
  name = '';
  note = '';
  notes = [];
  selectedDocuments = [];
  selectedDocumentsHS = [];
  propertyAttachments = [];
  propertyId = null;
  days30 = 0;
  days60 = 0;
  days90 = 0;
  currentTotal = 0;
  totalOutstanding = 0;
  custId = 0;
  customerInformation;
  logoUrl = '';
  todaysDate = new Date();
  progress: any;
  isLoading: boolean;

  /* -- File Upload Settings -- */
  uploadConfig = {};
  acceptedFileTypes = ['.jpg', '.png', '.pdf', '.xlsx', '.docx', '.xlt'];
  fileTypeErrorMsg = 'Supported file types - ' + this.acceptedFileTypes.toString();
  fileSizeErrorMsg = 'Max. file size - 2MB';
  private file: File | null = null;


  /* -- Job Filters -- */
  filters: JobFilters = {
    keywords: '',
    jobType: '',
    assets: '',
    properties: '',
    jobStatus: '',
    startDate: null,
    endDate: null,
    jobRef: ''
  };

  /* -- Asset Filters --*/
  assetFilters: AssetFilters = {
    keywords: '',
    projectRef: '',
    completedDate: null,
    assetCategory: '',
  };

  constructor(
    private propertyService: PropertyService,
    private activatedRoute: ActivatedRoute,
    private propertyViewService: PropertyViewServiceService,
    private authService: AuthService,
    private toastr: ToastrService,
    private assetService: AssetService,
    private modalService: AssetModalService,
    private alertService: AlertsService,
    private jobService: JobService,
    private navigateTo: NavigationService,
    private debtorService: DebtorsTransactionService,
    private appSettings: AppSettingsService,
    private healthAndSafetyService: HealthAndSafetyService,
    private spinner: NgxSpinnerService,

  ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
    this.isLoading = true;
    this.fileUploadForm = new PropertyFileUploadForm();
    this.healthSafetyUploadForm = new FileUploadForm();
  }

  ngOnInit(): void {
    this.listenForRouteParams();
  }

  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.projectRef = params.id;
        this.loadPropertyReference(params.id);
        this.setUpFormForCreate();
        this.loadAllPropertyData(params.id);
      }
    });
  }

  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
      if (this.finished !== true) {
        this.spinner.show();
      }
    }, 5000);
  }

  loadPropertyReference(propertyRef: string) {
    const params: PropertyService.GetPropertyByProjectRefAsyncParams = {
      Authorization: '',
      projectRef: propertyRef,
    };
    this.propertyService.GetPropertyByProjectRefAsync(params).subscribe(response => {
      this.property = response;
      this.propertyId = response.id;
      this.propertyIdentity = response.id;
      this.loadNotes();
    });
  }

  loadAllPropertyData(propertyId: number) {
    this.loadPropertyInformation(propertyId);
    this.loadAttachments();
    this.loadHSAttachments();
    this.loadJobData(this.projectRef);
    this.loadAssetData(this.projectRef);
    this.loadDebtorProperty(propertyId);
  }

  /* ------------------------- Information Tab Functions ------------------------- */

  loadPropertyInformation(propertyId: number) {
    const params: PropertyService.GetPropertyByIdAsyncParams = {
      Authorization: '',
      propertyId: propertyId,
    };
    this.propertyService.GetPropertyByIdAsync(params).subscribe(response => {
      this.property = response;
      this.propertyViewService.propertySelected(response);
    });
  }

  /* ------------------------- Notes Tab Functions ------------------------- */

  setUpFormForCreate() {
    this.noteForm = new CreateNotesForm();
    this.noteForm.setUpForm();
  }

  loadNotes() {
    const params: PropertyService.GetPropertyNotesAsyncParams = {
      Authorization: '',
      propertyId: this.propertyId
    };
    this.propertyService.GetPropertyNotesAsync(params).subscribe((response) => {
      this.notes = response;
      if (this.notes.length > 0) {
        this.notesAvailable = true;
      }
    });
  }

  /* ------------------------- Files Tab Functions ------------------------- */
  loadAttachments() {
    const params: PropertyService.GetPropertyDocumentsPagedAsyncParams = {
      Authorization: '',
      projectRef: this.projectRef,
      pageNo: this.currentPage,
      pageSize: this.pageSize
    };
    this.propertyService.GetPropertyDocumentsPagedAsync(params).subscribe(response => {
      this.propertyAttachments = response.items;
      if (this.propertyAttachments.length > 0) {
        this.docsAvailable = true;
      }
      this.totalRecordCount = response.totalRecordCount;
    });
  }

  uploadComplete(e) {
    this.loadAttachments();
  }

  isPDF(attachmentURL: string) {
    return attachmentURL.includes('.pdf');
  }

  selectAllFiles() {
    for (let i = 0; i < this.propertyAttachments.length; i++) {
      if (!this.propertyAttachments[i].selected) {
        this.propertyAttachments[i].selected = true;
        this.selectedDocuments.push(this.propertyAttachments[i]);
      } else {
        this.propertyAttachments[i].selected = false;
        const index = this.selectedDocuments.indexOf(this.propertyAttachments[i]);
        this.selectedDocuments.splice(index, 1);
      }
    }
  }

  isCheckedFiles(attachment, event) {
    const index = this.selectedDocuments.indexOf(attachment);
    if (index === -1) {
      if (event.target.checked) {
        this.selectedDocuments.push(attachment);
      }
    } else {
      this.selectedDocuments.splice(index, 1);
    }
  }

  downloadPropertyFile() {
    this.selectedDocuments.forEach(function (doc) {
      if (doc.selected) {
        FileSaver.saveAs(doc.url, doc.name);
      }
    });
    this.selectedDocuments = [];
    this.allChecked = false;
    for (let i = 0; i < this.propertyAttachments.length; i++) {
      this.propertyAttachments[i].selected = false;
    }
    this.toastr.success('Download Successful');
  }

  handleFile(event) {
    const file = event && event.target.files[0];
    this.file = file;
    this.checkFileType(file);
    this.checkFileSize(file);
  }

  checkFileType(file: File) {
    const extensionStart = file.name.lastIndexOf('.');
    const extension = file.name.substring(extensionStart);
    if (!this.acceptedFileTypes.includes(extension.toLowerCase())) {
      this.fileTypeInvalid = true;
    } else {
      this.fileTypeInvalid = false;
    }
  }

  checkFileSize(file: File) {
    const sizeInBytes = file.size;
    const sizeInMB = sizeInBytes / (1024 * 1024);
    if (sizeInMB > 2) {
      this.fileSizeInvalid = true;
    } else {
      this.fileSizeInvalid = false;
    }
  }

  uploadPropertyFile(event) {
    this.fileUploadForm.isSubmitted = true;
    if (this.fileUploadForm.form.valid && !this.fileSizeInvalid && !this.fileTypeInvalid) {

      const saveModel = this.fileUploadForm.getSaveModel(this.propertyId, this.file);
      this.propertyService.AddDocumentToProperty(saveModel).subscribe(response => {
        if (response) {
          this.alertService.fileUploaded();
          this.fileUploadForm.setUpForm();
          this.file = null;
          this.loadAttachments();
        }

      });
    } else {
      this.alertService.fileValidation();
    }
  }

  pageFilesChanged(page) {
    this.currentPage = page;
    this.loadAttachments();
  }


  /* ------------------------- Jobs Tab Functions ------------------------- */
  filterJobs(filters: JobFilters) {
    this.filters = filters;
    this.loadJobData(this.projectRef);
  }

  loadJobData(projectRef) {
    const params: JobService.GetJobsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      keywords: this.filters.keywords,
      jobTypeRef: this.filters.jobType,
      jobRef: this.filters.jobRef,
      assets: this.filters.assets,
      jobStatusRef: this.filters.jobStatus,
      startDate: this.filters.startDate != null ? this.filters.startDate.toDateString() : null,
      endDate: this.filters.endDate != null ? this.filters.endDate.toDateString() : null,
      projectRef: projectRef
    };
    this.jobService.GetJobsPagedAsync(params).subscribe(response => {
      this.totalJobRecordCount = response.totalRecordCount;
      this.jobsList = response.items;
      if (this.jobsList.length > 0) {
        this.jobsAvailable = true;
      }
    });
  }

  details(jobId: number) {
    this.navigateTo.viewJobDetails(jobId);
  }
  pageJobChanged(jobPage) {
    this.currentPage = jobPage;
    if (this.projectRef) {
      this.loadJobData(this.projectRef);
    }
  }

  /* ------------------------- Debtor Statement Tab Functions ------------------------- */

  loadDebtorProperty(propertyId: number) {
    const params: PropertyService.GetPropertyByIdAsyncParams = {
      Authorization: '',
      propertyId : propertyId,
    };
    this.propertyService.GetPropertyByIdAsync(params).subscribe(response => {
      this.property = response;
      this.projectRef = response.projectRef;
      this.loadDebtorsTransations();
    });
}

  loadDebtorsTransations() {
    const params: DebtorsTransactionService.GetOutstandingDebtorsTransactionsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      projectRef: this.projectRef,
    };
    this.debtorService.GetOutstandingDebtorsTransactionsPagedAsync(params).subscribe(response => {
      this.totalDebtorRecordCount = response.totalRecordCount;
      this.totalOutstanding = response.totalOutstanding;
      this.currentTotal = response.currentTotal;
      this.days30 = response.day30Total;
      this.days60 = response.day60Total;
      this.days90 = response.day90Total;
      this.debtorTransactions = response.items;
      this.finished = true;
    });
  }


  transactionDetails(transactionRef: number) {
    this.navigateTo.transactionDetails(transactionRef);

  }

  pageDebtorChanged(debtorPage) {
    if (this.projectRef) {
      this.currentPage = debtorPage;
      this.loadDebtorsTransations();
    }
  }

  downloadDebtorStatementPDF() {
    const params: DebtorsTransactionService.GenerateDebtorsStatementPDFParams = {
      Authorization: '',
      logoUrl: this.appSettings.getLynchLogo,
      debtorsTransactionPagedSettings: this.debtorPdfModelIn()
    };
    this.debtorService.GenerateDebtorsStatementPDF(params).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
      FileSaver.saveAs(blob, 'Property:' + this.projectRef + `_DebtorStatement.pdf`);
    });
  }

  debtorPdfModelIn() {
    const modelIn: DebtorsTransactionPagedSettings = {
      projectRef: this.projectRef,
      debtorRef: this.debtorRef,
      pageNo: this.currentPage,
      pageSize: this.pageSize,
    };
    return modelIn;
  }


  /* ------------------------- Assets Tab Functions ------------------------- */

  filterAssets(assetFilters: AssetFilters) {
    this.assetFilters = assetFilters;
    this.loadAssetData(this.projectRef);
  }

  loadAssetData(projectRef) {
    const params: AssetService.GetAssetsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      projectRef: projectRef,
      keywords: this.assetFilters.keywords,
      completedDate: this.assetFilters.completedDate != null ? this.assetFilters.completedDate.toDateString() : null,
      assetCategory: this.assetFilters.assetCategory
    };
    this.assetService.GetAssetsPagedAsync(params).subscribe(response => {
      this.totalAssetRecordCount = response.totalRecordCount;
      this.assetsList = response.items;
      if (this.assetsList.length > 0) {
        this.assetsAvailable = true;
      }
    });
  }

  viewAssetDetails(id: number) {
    this.modalService.viewAssetDetails(id);
  }

  pageAssetChanged(pageAsset) {
    this.currentPage = pageAsset;
    if (this.projectRef) {
      this.loadAssetData(this.projectRef);
    }
  }


  /* ------------------------- H&S Tab Functions ------------------------- */

//   loadPropertyHSReference(propertyIdentity: number) {
//     const params: PropertyService.GetPropertyByIdAsyncParams = {
//       Authorization: '',
//       propertyId : propertyIdentity,
//     };
//     this.propertyService.GetPropertyByIdAsync(params).subscribe(response => {
//       this.property = response;
//       this.projectRef = response.propertyRef;
//       this.loadHSAttachments();
//     });
// }

  loadHSAttachments() {
    const params: HealthAndSafetyService.GetHealthAndSafetyDocumentsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      supplier: '',
      dateOfUpload: null,
      projectRef: this.projectRef,
    };
    this.healthAndSafetyService.GetHealthAndSafetyDocumentsPagedAsync(params).subscribe(response => {
      this.totalHealthSafetyRecordCount = response.totalRecordCount;
      this.hsDocs = response.items;
      if (this.hsDocs.length > 0) {
        this.hsDocsAvailable = true;
      }
    });
  }

  uploadCompleteHS(e) {
    this.loadHSAttachments();
  }


  isPDFHS(attachmentURL: string) {
    return attachmentURL.includes('.pdf');
  }

  selectAllHS() {
    for (let i = 0; i < this.hsDocs.length; i++) {
      if (!this.hsDocs[i].selected) {
        this.hsDocs[i].selected = true;
        this.selectedDocuments.push(this.hsDocs[i]);
      } else {
        this.hsDocs[i].selected = false;
        const index = this.selectedDocuments.indexOf(this.hsDocs[i]);
        this.selectedDocuments.splice(index, 1);
      }
    }
  }

  isCheckedHS(attachment, event) {
    const index = this.selectedDocuments.indexOf(attachment);
    if (index === -1) {
      if (event.target.checked) {
        this.selectedDocuments.push(attachment);
      }
    } else {
      this.selectedDocuments.splice(index, 1);
    }
  }

  downloadHS() {
    this.selectedDocuments.forEach(function (doc) {
      if (doc.selected) {
        FileSaver.saveAs(doc.url, doc.name);
      }
    });
    this.selectedDocuments = [];
    this.allChecked = false;
    for (let i = 0; i < this.hsDocs.length; i++) {
      this.hsDocs[i].selected = false;
    }
    this.toastr.success('Download Successful');
  }

  handleFileHS(event) {
    const file = event && event.target.files[0];
    this.file = file;
    this.checkFileTypeHS(file);
    this.checkFileSizeHS(file);
  }

  checkFileTypeHS( file: File) {
        const extensionStart = file.name.lastIndexOf('.');
        const extension = file.name.substring(extensionStart);
        if ( !this.acceptedFileTypes.includes(extension.toLowerCase()) ) {
            this.fileTypeInvalid = true;
        } else {
          this.fileTypeInvalid = false;
        }
  }


  pageHealthSafetyChanged(hsPage) {
    this.currentPage = hsPage;
    if (this.projectRef) {
      this.loadHSAttachments();
    }
  }

  checkFileSizeHS( file: File) {
    const sizeInBytes = file.size;
    const sizeInMB = sizeInBytes / (1024 * 1024);
    if (sizeInMB > 2) {
        this.fileSizeInvalid = true;
    } else {
      this.fileSizeInvalid = false;
    }
  }

  uploadHS(event) {
    this.healthSafetyUploadForm.isSubmitted = true;
    if (this.healthSafetyUploadForm.form.valid && !this.fileSizeInvalid && !this.fileTypeInvalid) {

      const saveModel = this.healthSafetyUploadForm.getSaveModel(this.propertyIdentity, this.file);

      this.healthAndSafetyService.UploadHealthAndSafetyDocument(saveModel).subscribe(response => {
        if (response) {
          this.alertService.fileUploaded();
          this.healthSafetyUploadForm.setUpForm();
          this.file = null;
          this.loadHSAttachments();
        }});
    } else {
      this.alertService.fileValidation();
    }
  }

}

