import { Component, OnInit } from '@angular/core';
import { faFile, faCamera, faBriefcase } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-view-properties',
  templateUrl: './view-properties.component.html',
  styleUrls: ['./view-properties.component.scss']
})
export class ViewPropertiesComponent implements OnInit {
  faBriefcase = faBriefcase;
  constructor() { }

  ngOnInit() {
  }

}
