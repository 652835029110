import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../../api/services/property.service';
import { NavigationService } from '../../services/navigation.service';
import { AlertsService } from '../../services/alert.service';
import { AuthService } from '../../auth/services/auth.service';
import { PropertyFilters } from './models/property-filters';
import { AccountService } from '../../api/services/account.service';
import { UserModelOut } from '../../api/models/user-model-out';
import { PropertyFilterService } from '../../services/property-filter-service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent implements OnInit {
  adminAccess = false;
  filters: PropertyFilters;
  properties = [];
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  searchProperty = false;
  keywords = '';
  reference = '';
  checkedProperties = [];
  accountManagers: Array<UserModelOut> = [];
  selectedAccountManager = '';
  allChecked = false;
  constructor(
    private navigateTo: NavigationService,
    private accountService: AccountService,
    private authService: AuthService,
    private propertyService: PropertyService,
    private alertService: AlertsService,
    private propertyFilterService: PropertyFilterService,
    private spinner: NgxSpinnerService,
  ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
  }

  ngOnInit() {
    this.loadAccountManagers();
    this.loadData();
    this.spinner.show();
  }
  filter(filters: PropertyFilters) {
    this.filters = filters;
    this.loadData();
  }
  search(keywords, reference) {
    this.searchProperty = true;
    this.keywords = keywords;
    this.reference = reference;
  }
  loadData() {
      const params: PropertyService.GetPropertiesPagedAsyncParams = {
        Authorization: '',
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        keywords: this.filters.keywords,
        projectRef: this.propertyFilterService.getPropertyRef
      };
      this.propertyService.GetPropertiesPagedAsync(params).subscribe(response => {
        this.totalRecordCount = response.totalRecordCount;
        this.properties = response.items;
        this.spinner.hide();
      });
    }

  view(projectRef: string) {
    this.navigateTo.propertyDetails(projectRef);
  }

  viewDebtorStatement(projectRef: string) {
    this.navigateTo.debtorStatement(projectRef);
  }
  pageChanged(page) {
    this.allChecked = false;
    this.checkedProperties = [];
    this.currentPage = page;
    this.loadData();
  }
  loadAccountManagers() {
    const params: AccountService.GetUsersAsyncParams = {
      Authorization: '',
      pageSize: 10,
      pageNo: 1
    };
    this.accountService.GetUsersAsync(params).subscribe((response) => {
      this.accountManagers = response.items.map((s) => ({ id: s.id, name: s.firstName + ' ' + s.lastName }));

    });
  }
  isChecked(projectRef: string, event) {
    const index = this.checkedProperties.indexOf(projectRef);
    if (index === -1) {
      this.allChecked = false;
      if (event.target.checked) {
        this.checkedProperties.push(projectRef);
      }
    } else {
      this.checkedProperties.splice(index, 1);
    }
  }
  save() {
    const params: PropertyService.UpdateAccountManagersParams = {
      Authorization: '',
      userId: this.selectedAccountManager,
      modelIn: {
        projectRefs: this.checkedProperties
      }
    };

    this.propertyService.UpdateAccountManagers(params).subscribe((response) => {

      this.alertService.success('Saved');
    });
  }

  selectAll(event) {
    this.checkedProperties = [];
    for (let i = 0; i < this.properties.length; i++) {
      if (event.target.checked) {
        this.properties[i].selected = true;
        this.checkedProperties.push(this.properties[i].id);
      } else {
        this.checkedProperties = [];
        this.properties[i].selected = false;
        const index = this.checkedProperties.indexOf(this.properties[i].id);
        this.checkedProperties.splice(index, 1);
      }
    }
  }
}
