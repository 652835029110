import { Component, OnInit } from '@angular/core';
import { UserModelOut } from '../../../../api/models/user-model-out';
import { NavigationService } from '../../../../services/navigation.service';
import { AccountService } from '../../../../api/services/account.service';
import { faEdit  } from '@fortawesome/free-solid-svg-icons';
import { AlertsService } from '../../../../services/alert.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeleteModalService } from '../delete-modal/delete-modal-service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users: Array<UserModelOut>;
  faEdit = faEdit;
  Authorization: '';
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  keywords = '';
  constructor(
    private userService: AccountService,
    private navigateTo: NavigationService,
    private alertsService: AlertsService,
    private modalService: DeleteModalService,
  ) { }

  ngOnInit(): void {
    this.loadData();
  }
  resetFilters() {
    this.keywords = '';
    this.loadData();
  }
  search(keywords) {
    this.keywords = keywords;
    this.loadData();
  }
  loadData() {
    const params: AccountService.GetUsersAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      keywords: this.keywords,
    };
    this.userService.GetUsersAsync(params).subscribe(response => {
      this.totalRecordCount = response.totalRecordCount;
      this.users = response.items;
    });
  }

  deletePopUp(userId: string) {
    this.modalService.deleteUser(userId);
  }

  edit(userId: string) {
    this.navigateTo.editUser(userId);
  }
  create() {
    this.navigateTo.createUser();
  }
  details() {
    this.navigateTo.users();
  }
  pageChanged(page) {
    this.currentPage = page;
    this.loadData();
  }

}
