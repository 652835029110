/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HealthAndSafetyDocumentsPagedModelOut } from '../models/health-and-safety-documents-paged-model-out';
@Injectable({
  providedIn: 'root',
})
class HealthAndSafetyService extends __BaseService {
  static readonly GetHealthAndSafetyDocumentsPagedAsyncPath = '/api/HealthAndSafety/paged';
  static readonly UploadHealthAndSafetyDocumentPath = '/api/HealthAndSafety/fileUpload';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Health And Safety Documents Paged
   * @param params The `HealthAndSafetyService.GetHealthAndSafetyDocumentsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `supplier`:
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `expiryDate`:
   *
   * - `documentName`:
   *
   * - `description`:
   *
   * - `dateOfUpload`:
   *
   * @return Success
   */
  GetHealthAndSafetyDocumentsPagedAsyncResponse(params: HealthAndSafetyService.GetHealthAndSafetyDocumentsPagedAsyncParams): __Observable<__StrictHttpResponse<HealthAndSafetyDocumentsPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.supplier != null) __params = __params.set('supplier', params.supplier.toString());
    if (params.propertyName != null) __params = __params.set('propertyName', params.propertyName.toString());
    if (params.projectRef != null) __params = __params.set('projectRef', params.projectRef.toString());
    if (params.expiryDate != null) __params = __params.set('expiryDate', params.expiryDate.toString());
    if (params.documentName != null) __params = __params.set('documentName', params.documentName.toString());
    if (params.description != null) __params = __params.set('description', params.description.toString());
    if (params.dateOfUpload != null) __params = __params.set('dateOfUpload', params.dateOfUpload.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/HealthAndSafety/paged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HealthAndSafetyDocumentsPagedModelOut>;
      })
    );
  }
  /**
   * Get Health And Safety Documents Paged
   * @param params The `HealthAndSafetyService.GetHealthAndSafetyDocumentsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `supplier`:
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `expiryDate`:
   *
   * - `documentName`:
   *
   * - `description`:
   *
   * - `dateOfUpload`:
   *
   * @return Success
   */
  GetHealthAndSafetyDocumentsPagedAsync(params: HealthAndSafetyService.GetHealthAndSafetyDocumentsPagedAsyncParams): __Observable<HealthAndSafetyDocumentsPagedModelOut> {
    return this.GetHealthAndSafetyDocumentsPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as HealthAndSafetyDocumentsPagedModelOut)
    );
  }

  /**
   * @param params The `HealthAndSafetyService.UploadHealthAndSafetyDocumentParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `supplier`:
   *
   * - `propertyId`:
   *
   * - `file0`:
   *
   * - `expiryDate`:
   *
   * - `description`:
   *
   * @return Success
   */
  UploadHealthAndSafetyDocumentResponse(params: HealthAndSafetyService.UploadHealthAndSafetyDocumentParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.supplier != null) { __formData.append('supplier', params.supplier as string | Blob);}
    if (params.propertyId != null) __params = __params.set('propertyId', params.propertyId.toString());
    if (params.file0 != null) { __formData.append('file0', params.file0 as string | Blob);}
    if (params.expiryDate != null) { __formData.append('expiryDate', params.expiryDate as string | Blob);}
    if (params.description != null) { __formData.append('description', params.description as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/HealthAndSafety/fileUpload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `HealthAndSafetyService.UploadHealthAndSafetyDocumentParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `supplier`:
   *
   * - `propertyId`:
   *
   * - `file0`:
   *
   * - `expiryDate`:
   *
   * - `description`:
   *
   * @return Success
   */
  UploadHealthAndSafetyDocument(params: HealthAndSafetyService.UploadHealthAndSafetyDocumentParams): __Observable<string> {
    return this.UploadHealthAndSafetyDocumentResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module HealthAndSafetyService {

  /**
   * Parameters for GetHealthAndSafetyDocumentsPagedAsync
   */
  export interface GetHealthAndSafetyDocumentsPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    supplier?: string;
    propertyName?: string;
    projectRef?: string;
    expiryDate?: string;
    documentName?: string;
    description?: string;
    dateOfUpload?: string;
  }

  /**
   * Parameters for UploadHealthAndSafetyDocument
   */
  export interface UploadHealthAndSafetyDocumentParams {

    /**
     * Access Token
     */
    Authorization: string;
    supplier?: string;
    propertyId?: number;
    file0?: Blob;
    expiryDate?: string;
    description?: string;
  }
}

export { HealthAndSafetyService }
