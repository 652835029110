import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { PropertyNamesPagedModelOut } from '../../../api/models/property-names-paged-model-out';
import { Observable, Observer, noop, of } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { PropertyFilters } from '../../../pages/properties/models/property-filters';
import { PropertyService } from '../../../api/services/property.service';
import { AlertsService } from '../../../services/alert.service';
import { debounceTime, distinctUntilChanged, switchMap, map, tap } from 'rxjs/operators';
import { PropertyFilterService } from '../../../services/property-filter-service';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { Router, NavigationEnd, Event, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-property-filters',
  templateUrl: './property-filters.component.html',
  styleUrls: ['./property-filters.component.scss']
})
export class PropertyFiltersComponent implements OnInit {
  @Output() search: EventEmitter<PropertyFilters> = new EventEmitter();
  projectRef = this.propertyFilterService.getPropertyRef;
  faUndo = faUndo;
  propSuggestions$: Observable<PropertyNamesPagedModelOut[]>;
  currentPage = 1;
  pageSize = 20;
  route = '';
  errorMessage: string;
  constructor(
    private propertyService: PropertyService,
    private propertyFilterService: PropertyFilterService,
    private alertsService: AlertsService,
    private router: Router
  ) {

    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {

        this.route = this.router.url;
        this.projectRef = this.propertyFilterService.getPropertyRef;

      }
    });
  }

  ngOnInit() {
    this.projectRef = this.propertyFilterService.getPropertyRef;
    this.propSuggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.projectRef);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((query: string) => {
        if (query) {

          const params: PropertyService.GetPropertyNamesPagedAsyncParams = {
            Authorization: '',
            pageNo: this.currentPage,
            pageSize: this.pageSize,
            keywords: this.projectRef
          };

          return this.propertyService.GetPropertyNamesPagedAsync(params)
            .pipe(
              map((data: PropertyNamesPagedModelOut) => data.items.map(
                (s) => ({ projectDescription: s.projectDescription + ' (' + s.projectCode + ')', projectRef: s.projectCode })) || []),
              tap(() => noop, err => {
                this.errorMessage = err && err.message || 'Unable to find matching property';
              })
            );
        } else {

          const params: PropertyService.GetPropertyNamesPagedAsyncParams = {
            Authorization: '',
            pageNo: this.currentPage,
            pageSize: this.pageSize
          };

          return this.propertyService.GetPropertyNamesPagedAsync(params)
            .pipe(
              map((data: PropertyNamesPagedModelOut) => data.items.map(
                (s) => ({ projectDescription: s.projectDescription + ' (' + s.projectCode + ')', projectRef: s.projectCode })) || []),
              tap(() => noop, err => {
                this.errorMessage = err && err.message || 'Unable to find matching property';
              })
            );
        }

        this.alertsService.error('Unable to find matching property');

        return of([]);
      })
    );
  }

  resetFilters() {
    this.propertyFilterService.setPropertyRef = '';

    this.router.navigateByUrl('/users', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.route]);
    });
  }

  onSelectProperty(event: TypeaheadMatch): void {
    this.projectRef = event.item.projectRef;
    this.propertyFilterService.setPropertyRef = event.item.projectRef;

    this.router.navigateByUrl('/users', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.route]);
    });
  }
  onBackspaceProp(event) {
    const key = (window.Event) ? event.which : event.keyCode;
    if (key === 8) {
      this.projectRef = '';
    }
  }
}
