import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as pbi from 'powerbi-client';
import { NgxPowerBiService } from 'ngx-powerbi';
import { PowerBiService } from '../../../api/services/power-bi.service';
import { ReportModelOut } from '../../../api/models/report-model-out';
import { ActivatedRoute } from '@angular/router';
import { PropertyService } from '../../../api/services/property.service';

@Component({
  selector: 'app-schedule-properties',
  templateUrl: './schedule-properties.component.html',
  styleUrls: ['./schedule-properties.component.scss']
})
export class SchedulePropertiesComponent implements OnInit {
  @ViewChild('embed') embed: ElementRef;
  private pbiContainerElement: HTMLElement;
  selectedReport = 0;
  propertyId = 0;
  propertyRef = '';
  reports: Array<ReportModelOut>;
  embedConfig = {
    type: 'report',
    id: '',
    pageName: 'ReportSection0a1568c925cfd5da8df1',
    embedUrl: '',
    tokenType: pbi.models.TokenType.Embed,
    accessToken: '',
    filters: [],
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      layoutType: pbi.models.LayoutType.Custom
    }
  };
  report: any;
  constructor(
    private ngxPowerBiService: NgxPowerBiService,
    private powerBiService: PowerBiService,
    private activatedRoute: ActivatedRoute,
    private propertyService: PropertyService
  ) {
  }
  ngOnInit() {
    this.pbiContainerElement = <HTMLElement>(document.getElementById('pbi-container'));
    this.listenForRouteParams();
  }

  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.propertyId = params.id;
        this.getPropertyRef(this.propertyId);
      }
    });
  }

  getPropertyRef(propertyId: number) {
    const params: PropertyService.GetPropertyByIdAsyncParams = {
      Authorization: '',
      propertyId: propertyId
    };
    this.propertyService.GetPropertyByIdAsync(params).subscribe((response) => {

      this.propertyRef = response.propertyRef;
      this.loadUserReports();

    });
  }

  loadUserReports() {
    this.powerBiService.GetUserReportsAsync('').subscribe((response: Array<ReportModelOut>) => {
      this.reports = response;
      this.loadReport(this.reports.filter((r) =>  r.name.indexOf('PPM Planner') >= 0)[0].reportId, this.propertyRef);
    });
  }

  loadReport(reportId: string, propertyRef: string) {
    const params: PowerBiService.GetReportByIdAsyncParams = {
      Authorization: '',
      reportId: reportId
    };
    this.powerBiService.GetReportByIdAsync(params).subscribe((response) => {
      this.embedConfig.accessToken = response.embedToken;
      this.embedConfig.id = response.reportId;
      this.embedConfig.embedUrl = response.embedUrl;

      const filter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'Properties',
          column: 'ProjectRef'
        },
        operator: 'In',
        values: [propertyRef],
        filterType: pbi.models.FilterType.Basic
      };

      this.embedConfig.filters = [filter];

      this.report = this.ngxPowerBiService.embed(this.pbiContainerElement, this.embedConfig);

    });
  }
}

