import { FormControl, Validators, FormGroup, ValidationErrors } from '@angular/forms';
import { FormBuilderTypeSafe, CustomValidators } from '@allsop/angular-common';
export class AllsopBaseForm {
  formGroup: FormGroup;
  public isSubmitted = false;
  fb: FormBuilderTypeSafe = new FormBuilderTypeSafe();
  constructor() {
  }
  // Move to base class
  getControl(value, required = true) {
    if (required) {
      return new FormControl(value, [Validators.required]);
    }
    return new FormControl(value);
  }
  getDateControl(value) {
    let date = new Date();
    if (value) {
      date = new Date(value);
    }
    return new FormControl(date, [Validators.required]);
  }
  getSelectControl(value) {
    return new FormControl(value, [CustomValidators.requiredListSelection]);
  }
  getEmailControl(value) {
    return new FormControl(value, [Validators.required, CustomValidators.email]);
  }
  getWebSiteControl(value) {
    return new FormControl(value, [Validators.required, CustomValidators.website]);
  }
  getLatitudeControl(value) {
    return new FormControl(value, [CustomValidators.latitude]);
  }
  field(name: string) {
    return this.formGroup.get(name);
  }
  fieldErrors(name: string): ValidationErrors {
    return this.formGroup.get(name).errors;
  }
  findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
}
  resetForm() {
    this.fb = new FormBuilderTypeSafe();
  }
  get form() {
    return this.formGroup;
  }
  get allowSave() {
    return this.isSubmitted && this.formGroup.valid;
  }
  reset() {
    this.formGroup.reset();
  }
}
