/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EstimatesPagedModelOut } from '../models/estimates-paged-model-out';
import { EstimateModelOut } from '../models/estimate-model-out';
import { OkResult } from '../models/ok-result';
import { UpdateEstimateModelIn } from '../models/update-estimate-model-in';
import { EstimateGroupByKeyModelOut } from '../models/estimate-group-by-key-model-out';
@Injectable({
  providedIn: 'root',
})
class EstimateService extends __BaseService {
  static readonly GetEstimatesPagedAsyncPath = '/api/estimate/paged';
  static readonly GetEstimateByIdAsyncPath = '/api/estimate/{estimateId}';
  static readonly UpdateEstimateStatusAndCommentAsyncPath = '/api/estimate/update/{estimateId}';
  static readonly GenerateSingleEstimatePDFPath = '/api/estimate/estimate/pdf';
  static readonly GetEstimateByFormResultKeyAsyncPath = '/api/estimate/viewAudit/{formResultKey}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get All Estimate Documents
   * @param params The `EstimateService.GetEstimatesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `startDate`:
   *
   * - `propertyRef`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  GetEstimatesPagedAsyncResponse(params: EstimateService.GetEstimatesPagedAsyncParams): __Observable<__StrictHttpResponse<EstimatesPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.propertyRef != null) __params = __params.set('propertyRef', params.propertyRef.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/estimate/paged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EstimatesPagedModelOut>;
      })
    );
  }
  /**
   * Get All Estimate Documents
   * @param params The `EstimateService.GetEstimatesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `startDate`:
   *
   * - `propertyRef`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  GetEstimatesPagedAsync(params: EstimateService.GetEstimatesPagedAsyncParams): __Observable<EstimatesPagedModelOut> {
    return this.GetEstimatesPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as EstimatesPagedModelOut)
    );
  }

  /**
   * Get Estimate Document By Id
   * @param params The `EstimateService.GetEstimateByIdAsyncParams` containing the following parameters:
   *
   * - `estimateId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetEstimateByIdAsyncResponse(params: EstimateService.GetEstimateByIdAsyncParams): __Observable<__StrictHttpResponse<EstimateModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/estimate/${encodeURIComponent(params.estimateId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EstimateModelOut>;
      })
    );
  }
  /**
   * Get Estimate Document By Id
   * @param params The `EstimateService.GetEstimateByIdAsyncParams` containing the following parameters:
   *
   * - `estimateId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetEstimateByIdAsync(params: EstimateService.GetEstimateByIdAsyncParams): __Observable<EstimateModelOut> {
    return this.GetEstimateByIdAsyncResponse(params).pipe(
      __map(_r => _r.body as EstimateModelOut)
    );
  }

  /**
   * Update an Estimate's status
   * @param params The `EstimateService.UpdateEstimateStatusAndCommentAsyncParams` containing the following parameters:
   *
   * - `estimateId`:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  UpdateEstimateStatusAndCommentAsyncResponse(params: EstimateService.UpdateEstimateStatusAndCommentAsyncParams): __Observable<__StrictHttpResponse<OkResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.modelIn;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/estimate/update/${encodeURIComponent(params.estimateId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OkResult>;
      })
    );
  }
  /**
   * Update an Estimate's status
   * @param params The `EstimateService.UpdateEstimateStatusAndCommentAsyncParams` containing the following parameters:
   *
   * - `estimateId`:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  UpdateEstimateStatusAndCommentAsync(params: EstimateService.UpdateEstimateStatusAndCommentAsyncParams): __Observable<OkResult> {
    return this.UpdateEstimateStatusAndCommentAsyncResponse(params).pipe(
      __map(_r => _r.body as OkResult)
    );
  }

  /**
   * Estimate Pdf
   * @param params The `EstimateService.GenerateSingleEstimatePDFParams` containing the following parameters:
   *
   * - `modelIn`:
   *
   * - `Authorization`: Access Token
   *
   * - `logoUrl`:
   *
   * @return Success
   */
  GenerateSingleEstimatePDFResponse(params: EstimateService.GenerateSingleEstimatePDFParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.modelIn;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.logoUrl != null) __params = __params.set('logoUrl', params.logoUrl.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/estimate/estimate/pdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Estimate Pdf
   * @param params The `EstimateService.GenerateSingleEstimatePDFParams` containing the following parameters:
   *
   * - `modelIn`:
   *
   * - `Authorization`: Access Token
   *
   * - `logoUrl`:
   *
   * @return Success
   */
  GenerateSingleEstimatePDF(params: EstimateService.GenerateSingleEstimatePDFParams): __Observable<Blob> {
    return this.GenerateSingleEstimatePDFResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Get Estimate Data By Form Result Key
   * @param params The `EstimateService.GetEstimateByFormResultKeyAsyncParams` containing the following parameters:
   *
   * - `formResultKey`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetEstimateByFormResultKeyAsyncResponse(params: EstimateService.GetEstimateByFormResultKeyAsyncParams): __Observable<__StrictHttpResponse<EstimateGroupByKeyModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/estimate/viewAudit/${encodeURIComponent(params.formResultKey)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EstimateGroupByKeyModelOut>;
      })
    );
  }
  /**
   * Get Estimate Data By Form Result Key
   * @param params The `EstimateService.GetEstimateByFormResultKeyAsyncParams` containing the following parameters:
   *
   * - `formResultKey`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetEstimateByFormResultKeyAsync(params: EstimateService.GetEstimateByFormResultKeyAsyncParams): __Observable<EstimateGroupByKeyModelOut> {
    return this.GetEstimateByFormResultKeyAsyncResponse(params).pipe(
      __map(_r => _r.body as EstimateGroupByKeyModelOut)
    );
  }
}

module EstimateService {

  /**
   * Parameters for GetEstimatesPagedAsync
   */
  export interface GetEstimatesPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    startDate?: string;
    propertyRef?: string;
    endDate?: string;
  }

  /**
   * Parameters for GetEstimateByIdAsync
   */
  export interface GetEstimateByIdAsyncParams {
    estimateId: number;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for UpdateEstimateStatusAndCommentAsync
   */
  export interface UpdateEstimateStatusAndCommentAsyncParams {
    estimateId: number;

    /**
     * Access Token
     */
    Authorization: string;
    modelIn?: UpdateEstimateModelIn;
  }

  /**
   * Parameters for GenerateSingleEstimatePDF
   */
  export interface GenerateSingleEstimatePDFParams {
    modelIn: EstimateGroupByKeyModelOut;

    /**
     * Access Token
     */
    Authorization: string;
    logoUrl?: string;
  }

  /**
   * Parameters for GetEstimateByFormResultKeyAsync
   */
  export interface GetEstimateByFormResultKeyAsyncParams {
    formResultKey: string;

    /**
     * Access Token
     */
    Authorization: string;
  }
}

export { EstimateService }
