import { Component, OnInit, Output } from '@angular/core';
import { EstimateForm } from '../estimateForm';
import { NavigationService } from '../../../services/navigation.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EstimateStatusService } from '../../../api/services/estimate-status.service';
import { EstimateStatusModelIn } from '../../../api/models/estimate-status-model-in';
import { EstimateGroupByKeyModelOut } from '../../../api/models/estimate-group-by-key-model-out';
import { AlertsService } from '../../../services/alert.service';

@Component({
    selector: 'app-estimates-modal',
    templateUrl: './estimates-modal.component.html',
    styleUrls: ['./estimates-modal.component.scss']
})
export class EstimatesModalComponent implements OnInit {
    estimateForm: EstimateForm;
    estimateId = 0;
    formResultKey = '';
    estimate: EstimateGroupByKeyModelOut;
    isSubmitted = false;
    isReadOnly = true;
    title = 'Estimate Review';
    estimateStatuses = [];
    estimateComments = [];

    constructor(
        private monitoringService: MonitoringService,
        public bsModalRef: BsModalRef,
        private navigateTo: NavigationService,
        private toastr: ToastrService,
        private estimateStatusService: EstimateStatusService,
        private alertService: AlertsService
    ) {
        this.estimateForm = new EstimateForm();
    }

    ngOnInit() {
        console.log(this.formResultKey);
        this.loadEstimateStatusDetailsById(this.formResultKey);
        this.loadEstimateCommentsById(this.formResultKey);
    }

    loadEstimateStatusDetailsById(key: string) {
        const params: EstimateStatusService.GetEstimateStatusesByFormResultKeyAsyncParams = {
            Authorization: '',
            formResultKey: key
        };
        this.estimateStatusService.GetEstimateStatusesByFormResultKeyAsync(params).subscribe(response => {
            this.estimateStatuses = response;
        }, error => {
            this.monitoringService.logWebException(error.error, 1);
        });
    }

    loadEstimateCommentsById(key: string) {
      const params: EstimateStatusService.GetEstimateCommentsByFormResultKeyAsyncParams = {
          Authorization: '',
          formResultKey: key
      };
      this.estimateStatusService.GetEstimateCommentsByFormResultKeyAsync(params).subscribe(response => {
          this.estimateComments = response;
      }, error => {
          this.monitoringService.logWebException(error.error, 1);
      });
  }

    accept(propertyRef: string, quoteRef: string) {

        if (this.estimateForm.formGroup.value.comment !== '') {

            const modelIn: EstimateStatusModelIn = {
                status: 'Accepted',
                comment: this.estimateForm.formGroup.value.comment,
                formResultKey: this.formResultKey,
                poNumber: this.estimateForm.formGroup.value.poNumber
            };
            const params: EstimateStatusService.PostAsyncParams = {
                Authorization: '',
                propertyRef: propertyRef,
                quoteRef: quoteRef,
                modelIn: modelIn
            };

            this.estimateStatusService.PostAsync(params).subscribe(response => {
                this.toastr.success('Estimate Status Saved Successfully', 'SAVED');
                this.close();
            }, error => {
                this.monitoringService.logWebException(error.error, 1);
                this.close();
            });

        } else {
            this.alertService.error('Please enter a Comment');
        }

    }

    reject(propertyRef: string, quoteRef: string) {

        if (this.estimateForm.formGroup.value.comment !== '') {

            const modelIn: EstimateStatusModelIn = {
                status: 'Rejected',
                comment: this.estimateForm.formGroup.value.comment,
                formResultKey: this.formResultKey,
                poNumber: this.estimateForm.formGroup.value.poNumber
            };
            const params: EstimateStatusService.PostAsyncParams = {
                Authorization: '',
                propertyRef: propertyRef,
                quoteRef: quoteRef,
                modelIn: modelIn
            };

            this.estimateStatusService.PostAsync(params).subscribe(response => {
                this.toastr.success('Estimate Status Saved Successfully', 'SAVED');
                this.close();
            }, error => {
                this.monitoringService.logWebException(error.error, 1);
                this.close();
            });

        } else {
            this.alertService.error('Please enter a Comment');
        }

    }

    partAccept(propertyRef: string, quoteRef: string) {

        if (this.estimateForm.formGroup.value.comment !== '') {

            const modelIn: EstimateStatusModelIn = {
                status: 'Part-Accepted',
                comment: this.estimateForm.formGroup.value.comment,
                formResultKey: this.formResultKey,
                poNumber: this.estimateForm.formGroup.value.poNumber
            };
            const params: EstimateStatusService.PostAsyncParams = {
                Authorization: '',
                propertyRef: propertyRef,
                quoteRef: quoteRef,
                modelIn: modelIn
            };

            this.estimateStatusService.PostAsync(params).subscribe(response => {
                this.toastr.success('Estimate Status Saved Successfully', 'SAVED');
                this.close();
            }, error => {
                this.monitoringService.logWebException(error.error, 1);
                this.close();
            });

        } else {
            this.alertService.error('Please enter a Comment');
        }

    }

    AddComment(propertyRef: string, quoteRef: string) {

      if (this.estimateForm.formGroup.value.comment !== '') {

          const modelIn: EstimateStatusModelIn = {
              status: 'Rejected',
              comment: this.estimateForm.formGroup.value.comment,
              formResultKey: this.formResultKey,
              poNumber: this.estimateForm.formGroup.value.poNumber
          };
          const params: EstimateStatusService.PostAsyncParams = {
              Authorization: '',
              propertyRef: propertyRef,
              quoteRef: quoteRef,
              modelIn: modelIn
          };

          this.estimateStatusService.AddEstimateCommentAsync(params).subscribe(response => {
              this.toastr.success('Estimate Comment Saved Successfully', 'SAVED');
              this.close();
          }, error => {
              this.monitoringService.logWebException(error.error, 1);
              this.close();
          });

      } else {
          this.alertService.error('Please enter a Comment');
      }

  }

    back() {
        this.close();
    }

    close() {
        this.bsModalRef.hide();
    }
}
