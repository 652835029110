import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menuOpen = false;
  constructor() {

  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }
  hideMenu() {
    this.menuOpen = false;
  }
}
