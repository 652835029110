import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  route = '';
  email = 'helpdesk@lynchinteract.com';
  constructor(
    public bsModalRef: BsModalRef,
    private router: Router
    ) { }

  ngOnInit() {
  }

  close() {
    this.bsModalRef.hide();
  }

}
