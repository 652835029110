import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
import { PropertyModelOut } from '../../../api/models/property-model-out';

@Component({
  selector: 'app-property-location',
  templateUrl: './property-location.component.html',
  styleUrls: ['./property-location.component.scss']
})
export class PropertyLocationComponent implements OnInit, AfterViewInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild('somemarker', { static: false }) somemarker: MapMarker;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @Input() property: PropertyModelOut;

  marker;
  zoom = 16;
  center: google.maps.LatLngLiteral;
   options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 8,
  };
  infoContent = '';
  infoWindowOpen = true;
  address = '';
  errorMsg = '';

  constructor(
  ) { }

  ngAfterViewInit(): void {
    this.infoContent = this.address;
   this.infoWindow.open(this.somemarker);
  }

  ngOnInit() {
    this.loadMarker();
  }

  loadMarker() {
    const geocoder = new google.maps.Geocoder();
    this.address = `${this.property.siteAddressLine1} ${this.property.siteAddressLine2}
    ${this.property.siteAddressLine3} ${this.property.country}`;
    geocoder.geocode({ 'address': this.address }, (results, status) => {

      if (status === 'OK') {
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        this.marker = ({
          position: {
            lat: latitude,
            lng: longitude,
          },
          label: {
            color: '#ffffff00',
            text: this.address,
          },
          title: this.address
        });

        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: latitude,
            lng: longitude
          };
        });

      } else {
        if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          this.errorMsg = 'Invalid address, try typing a new address';
        } else if (status === google.maps.GeocoderStatus.REQUEST_DENIED) {
          this.errorMsg = 'Your Request Was Denied. Please check if Geocoding is enabled';
        } else if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
          this.errorMsg = 'All Requests Used Up';
        } else {
          this.errorMsg = 'Something went wrong';
        }
      }
    });
   }
}
