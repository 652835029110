import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MainComponent } from './layout/main/main.component';
import { UsersComponent } from './pages/users/users/users-list/users.component';
import { EditUsersComponent } from './pages/users/users/edit-users/edit-users.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { EditCustomersComponent } from './pages/customers/edit-customers/edit-customers.component';
import { LoginComponent } from './auth/login/login.component';
import { PowerbiReportComponent } from './pages/reports/powerbi-report/powerbi-report.component';
import { CreateUserComponent } from './pages/users/users/create-user/create-user.component';
import { AssetsComponent } from './pages/assets/assets.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { FinancialComponent } from './pages/financial/financial.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { PropertiesComponent } from './pages/properties/properties.component';
import { ViewJobsComponent } from '../app/pages/jobs/view-jobs/view-jobs.component';
import { HealthAndSafetyDocumentsComponent } from './pages/health-and-safety-documents/health-and-safety-documents.component';
import { AssetDetailsComponent } from './pages/assets/asset-details/asset-details.component';
import { FinancialDetailsComponent } from './pages/financial/financial-details/financial-details.component';
import { OutstandingTransactionsComponent } from './pages/financial/outstanding-transactions/outstanding-transactions.component';
import { TransactionDetailsComponent } from './pages/financial/financial-details/transactionDetails/transactionDetails.component';
import { DebtorStatementComponent } from './pages/debtor-statement/debtor-statement.component';
import { PpmDetailComponent } from './pages/reports/ppm-detail/ppm-detail.component';
import { PpmReportComponent } from './pages/reports/ppm-report/ppm-report.component';
import { EstimatesComponent } from './pages/estimates/estimates.component';
import { QuotesComponent } from './pages/quotes/quotes.component';
import { ReactiveJobsComponent } from './pages/reactive-jobs/reactive-jobs.component';
import { SubcontractorDocumentsComponent } from './pages/subcontractor-documents/subcontractor-documents.component';
import { DeleteModalComponent } from './pages/users/users/delete-modal/delete-modal.component';
import { PpmJobsComponent } from './pages/ppm-jobs/ppm-jobs.component';
import { PaginatedReportComponent } from './pages/reports/paginated-report/paginated-report.component';
import { PaidInvoicesComponent } from './pages/paid-invoices/paid-invoices.component';
import { OutstandingInvoicesComponent } from './pages/outstanding-invoices/outstanding-invoices.component';
import { LoginModalComponent } from './auth/login/login-modal/login-modal.component';
import { PropertyTabsComponent } from './pages/properties/property-tabs/property-tabs.component';
import { EstimateDetailsComponent } from './pages/estimates/estimate-details/estimate-details.component';
import { CustomReportsComponent } from './pages/reports/report-group/custom-reports/custom-reports.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
  path: '', component: MainComponent,
  children: [
    {
      path: 'users',
      component: UsersComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator' ] }
    },
    {
      path: 'home',
      component: PowerbiReportComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'user/:id',
      component: EditUsersComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator' ] }
    },
    {
      path: 'user',
      component: CreateUserComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator' ] }
    },
    {
      path: 'asset',
      component: AssetsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'customReport',
      component: CustomReportsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'jobs',
      component: JobsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'schedule',
      component: ScheduleComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'ppmReport',
      component: PpmReportComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'paginatedReport/:reportId',
      component: PaginatedReportComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'ppmDetail',
      component: PpmDetailComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'financial',
      component: FinancialComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'outstandingInvoices',
      component: OutstandingTransactionsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'paidInvoices',
      component: PaidInvoicesComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'outstandingInvoicesAll',
      component: OutstandingInvoicesComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'financial/:id',
      component: FinancialDetailsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'invoice/:id',
      component: TransactionDetailsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'customers',
      component: CustomersComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager' ] }
    },
    {
      path: 'customer/:id',
      component: EditCustomersComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager' ] }
    },
    {
      path: 'properties',
      component: PropertiesComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'properties/:id',
      component: PropertyTabsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'estimates',
      component: EstimatesComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'estimate/:id',
      component: EstimateDetailsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'quotes',
      component: QuotesComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'reactiveJobs',
      component: ReactiveJobsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },

    {
      path: 'ppmJobs',
      component: PpmJobsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'job-details',
      component: ViewJobsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'jobs/:id',
      component: ViewJobsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'hsDocuments',
      component: HealthAndSafetyDocumentsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
    {
      path: 'assets/:id',
      component: AssetDetailsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager' ] }
    },

    {
      path: 'loginComponent',
      component: LoginModalComponent,
    },
    {
      path: 'users/:id',
      component: DeleteModalComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator' ] }
    },
    {
      path: 'statement/:id',
      component: DebtorStatementComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager' ] }
    },
    {
      path: 'subDocuments',
      component: SubcontractorDocumentsComponent,
      canActivate: [AuthGuard],
      data: { roles: ['Administrator', 'AllAccounts', 'AccountManager', 'Customer' ] }
    },
   ]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
