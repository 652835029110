import { AllsopBaseForm } from '../../common/allsopBaseForm';
import { HealthAndSafetyService } from '../../api/services/health-and-safety.service';
import { SubContractorService } from '../../api/services/sub-contractor.service';
import { SCUploadForm } from './models/sc-upload-form';
import { FormGroup } from '@angular/forms';
import { AlertsService } from '../../services/alert.service';


export class FileUploadForm extends AllsopBaseForm {

    constructor() {
        super();
        this.setUpForm();
    }
    public setUpForm() {
      this.resetForm();

      const model: any = {
        jobId: 0,
        subcontractor: '',
        description: '',
        expiryDate: null,
        file: File = null
      };
      this.setUpFormForEdit(model);
    }

    public setUpFormForEdit(value: SCUploadForm) {
        this.resetForm();

        this.formGroup = this.fb.group<SCUploadForm>({
            jobId: this.getControl(value.jobId, false),
            subcontractor: this.getControl(value.subcontractor, false),
            description: this.getControl(value.description, false),
            expiryDate: this.getControl(value.expiryDate, false),
            file: this.getControl(value.file, true)
        });
    }

    getSaveModel(jobId: number, file: File): SubContractorService.UploadSubContractorDocumentParams {
        const value: SCUploadForm = this.formGroup.value;
        return {
            Authorization: '',
            subContractor: value.subcontractor,
            jobId: jobId,
            file0: file,
            expiryDate: value.expiryDate != null ? value.expiryDate.toDateString() : null,
            description: value.description

        };
    }
}
