import { FormControl, Validators, FormGroup } from '@angular/forms';
import { LoginModelIn } from '../../api/models/login-model-in';
import {FormBuilderTypeSafe, FormGroupTypeSafe} from '@allsop/angular-common';

export class LoginForm {
    fb: FormBuilderTypeSafe = new FormBuilderTypeSafe();
    isSubmitted = false;
    _form: FormGroup;

    resetForm() {
        this.fb = new FormBuilderTypeSafe();
    }
    public setUpForm() {
        this.resetForm();
        this._form = this.fb.group<LoginModelIn>({
            username: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required])
        });
    }
    public patchValue(contact: LoginModelIn): any {
        this.form.patchValue(contact);
    }
    get form() {
        if (!this._form) {
            this.setUpForm();
        }
        return this._form;
    }
    getSaveModel() {
        const saveModel: LoginModelIn = {
            username: this._form.value.username,
            password: this._form.value.password
        };
        return saveModel;
    }
    allowSave() {
        return this.isSubmitted && this._form.valid;
    }
}
