import { Component, OnInit } from '@angular/core';
import { EstimateForm } from '../estimateForm';
import { faInfoCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { EstimateStatusService } from '../../../api/services/estimate-status.service';
import { EstimateStatusModelIn } from '../../../api/models/estimate-status-model-in';
import { EstimateGroupByKeyModelOut } from '../../../api/models/estimate-group-by-key-model-out';
import { NavigationService } from '../../../services/navigation.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { AlertsService } from '../../../services/alert.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params } from '@angular/router';
import { EstimateModelOut } from '../../../api/models/estimate-model-out';
import { EstimateService } from '../../../api/services/estimate.service';
import * as FileSaver from 'file-saver';
import { AppSettingsService } from '../../../services/app-settings.service';

@Component({
  selector: 'app-estimate-details',
  templateUrl: './estimate-details.component.html',
  styleUrls: ['./estimate-details.component.scss']
})
export class EstimateDetailsComponent implements OnInit {
  /* -- Variables -- */
  formResultKey = '';
  quoteRef = '';
  propertyRef = '';
  title = 'Estimate Audit Details';
  estimateStatuses = [];
  estimateComments = [];
  estimates: Array<EstimateModelOut>;
  queryParams: Params;

  /*--  Auth/Permissions -- */
  isSubmitted = false;
  isReadOnly = true;
  finished = false;
  disabledFlag = false;

  /* -- Estimate Form -- */
  estimateForm: EstimateForm;

  /* -- Models -- */
  estimateDetails: EstimateGroupByKeyModelOut;

  /* -- Icons -- */
  faInfoCircle = faInfoCircle;
  faDownload = faDownload;
  isLoaded = false;

  constructor(
    private monitoringService: MonitoringService,
    private estimateService: EstimateService,
    private navigateTo: NavigationService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private estimateStatusService: EstimateStatusService,
    private alertService: AlertsService,
    private appSettings: AppSettingsService
  ) {
    this.estimateForm = new EstimateForm();
    this.activatedRoute.params.subscribe(params => {
      this.loadEstimateStatusDetailsById(params.id);
      this.loadEstimateDetailsByFormKeyResult(params.id);
      this.loadEstimateCommentsById(params.id);
    });
  }

  ngOnInit() { }


  loadEstimateDetailsByFormKeyResult(key: string) {
    const params: EstimateService.GetEstimateByFormResultKeyAsyncParams = {
      Authorization: '',
      formResultKey: key
    };
    this.estimateService.GetEstimateByFormResultKeyAsync(params).subscribe(response => {
      this.estimateDetails = response;
      this.quoteRef = response.quoteRef;
      this.formResultKey = response.formResultKey;
      this.propertyRef = response.propertyRef;
    }, error => {
      this.monitoringService.logWebException(error.error, 1);
    });
  }


  loadEstimateStatusDetailsById(key: string) {
    const params: EstimateStatusService.GetEstimateStatusesByFormResultKeyAsyncParams = {
      Authorization: '',
      formResultKey: key
    };
    this.estimateStatusService.GetEstimateStatusesByFormResultKeyAsync(params).subscribe(response => {
      this.estimateStatuses = response;
      if (this.estimateStatuses.length >= 1) {
        this.disabledFlag = true;
      }
    }, error => {
      this.monitoringService.logWebException(error.error, 1);
    });
  }


  loadEstimateCommentsById(key: string) {
    const params: EstimateStatusService.GetEstimateCommentsByFormResultKeyAsyncParams = {
      Authorization: '',
      formResultKey: key
    };
    this.estimateStatusService.GetEstimateCommentsByFormResultKeyAsync(params).subscribe(response => {
      this.estimateComments = response;
      this.finished = true;
    }, error => {
      this.monitoringService.logWebException(error.error, 1);
    });
  }

  accept(propertyRef: string, quoteRef: string) {
    if (this.estimateForm.formGroup.value.comment !== '') {
      const modelIn: EstimateStatusModelIn = {
        status: 'Accepted',
        comment: this.estimateForm.formGroup.value.comment,
        formResultKey: this.formResultKey,
        poNumber: this.estimateForm.formGroup.value.poNumber
      };
      const params: EstimateStatusService.PostAsyncParams = {
        Authorization: '',
        propertyRef: propertyRef,
        quoteRef: quoteRef,
        modelIn: modelIn
      };
      this.estimateStatusService.PostAsync(params).subscribe(response => {
        this.toastr.success('Estimate Status Saved Successfully', 'SAVED');
        window.location.reload();
      }, error => {
        this.monitoringService.logWebException(error.error, 1);
        window.location.reload();
      });

    } else {
      this.alertService.Warning('Please enter a Comment before changing the status');
    }
  }

  reject(propertyRef: string, quoteRef: string) {
    if (this.estimateForm.formGroup.value.comment !== '') {
      const modelIn: EstimateStatusModelIn = {
        status: 'Rejected',
        comment: this.estimateForm.formGroup.value.comment,
        formResultKey: this.formResultKey,
        poNumber: this.estimateForm.formGroup.value.poNumber
      };
      const params: EstimateStatusService.PostAsyncParams = {
        Authorization: '',
        propertyRef: propertyRef,
        quoteRef: quoteRef,
        modelIn: modelIn
      };

      this.estimateStatusService.PostAsync(params).subscribe(response => {
        this.toastr.success('Estimate Status Saved Successfully', 'SAVED');
        window.location.reload();
      }, error => {
        this.monitoringService.logWebException(error.error, 1);
        window.location.reload();
      });

    } else {
      this.alertService.Warning('Please enter a Comment before changing the status');
    }
  }

  partAccept(propertyRef: string, quoteRef: string) {
    if (this.estimateForm.formGroup.value.comment !== '') {
      const modelIn: EstimateStatusModelIn = {
        status: 'Part-Accepted',
        comment: this.estimateForm.formGroup.value.comment,
        formResultKey: this.formResultKey,
        poNumber: this.estimateForm.formGroup.value.poNumber
      };
      const params: EstimateStatusService.PostAsyncParams = {
        Authorization: '',
        propertyRef: propertyRef,
        quoteRef: quoteRef,
        modelIn: modelIn
      };

      this.estimateStatusService.PostAsync(params).subscribe(response => {
        this.toastr.success('Estimate Status Saved Successfully', 'SAVED');
        window.location.reload();
      }, error => {
        this.monitoringService.logWebException(error.error, 1);
        window.location.reload();
      });

    } else {
      this.alertService.Warning('Please enter a Comment before changing the status');
    }
  }

  AddComment(propertyRef: string, quoteRef: string) {
    if (this.estimateForm.formGroup.value.comment !== '') {
      const modelIn: EstimateStatusModelIn = {
        status: 'Rejected',
        comment: this.estimateForm.formGroup.value.comment,
        formResultKey: this.formResultKey,
        poNumber: this.estimateForm.formGroup.value.poNumber
      };
      const params: EstimateStatusService.PostAsyncParams = {
        Authorization: '',
        propertyRef: propertyRef,
        quoteRef: quoteRef,
        modelIn: modelIn
      };

      this.estimateStatusService.AddEstimateCommentAsync(params).subscribe(response => {
        this.toastr.success('Estimate Comment Saved Successfully', 'SAVED');
        window.location.reload();
      }, error => {
        this.monitoringService.logWebException(error.error, 1);
      });

    } else {
      this.alertService.Warning('Please enter a Comment');
    }
  }

  printPdf(estimate: EstimateGroupByKeyModelOut) {
    const params: EstimateService.GenerateSingleEstimatePDFParams = {
      Authorization: '',
      logoUrl: this.appSettings.getLynchLogo,
      modelIn: estimate
    };
    this.estimateService.GenerateSingleEstimatePDF(params).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
      FileSaver.saveAs(blob, `Estimate.pdf`);
    });
  }

  back() {
    this.navigateTo.estimates();
  }
}
