import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { PropertyService } from '../../../api/services/property.service';

export class AssignAccountManagerForm extends AllsopBaseForm {
  constructor() {
    super();

    this.setUpForm();
  }
  public setUpForm() {
    this.resetForm();

    this.formGroup = this.fb.group<any>({
      userId: this.getControl('')
    });
  }

  getCreateSaveModel(projectRefs: string[]): PropertyService.UpdateAccountManagersParams {
    const value = this.formGroup.value;
    return {
      Authorization: '',
      userId: value.userId,
      modelIn: {
        projectRefs: projectRefs
      }
    };
  }
}
