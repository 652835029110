import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../../api/services/account.service';
import { AlertsService } from '../../../../services/alert.service';
import { NavigationService } from '../../../../services/navigation.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  userId = '';
  constructor(
     private userService: AccountService,
     private navigateTo: NavigationService,
     private alertsService: AlertsService,
     public bsModalRef: BsModalRef
     ) { }

     ngOnInit() {
    }

  deleteUser(userId: string) {
    const params: AccountService.DeleteUserParams = {
      Authorization: '',
      userId: userId
    };
    this.userService.DeleteUser(params).subscribe(() => {
      this.alertsService.recordDeleted();
      this.close();
      window.location.reload();
      this.navigateTo.users();
    });
  }

  back() {
    this.alertsService.noChanges();
    this.close();
    this.navigateTo.users();
  }

  close() {
    this.bsModalRef.hide();
  }
}
