import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PropertyModelOut } from '../../../api/models/property-model-out';

@Injectable({
  providedIn: 'root'
})
export class PropertyViewServiceService {
  private selectedProperty: Subject<PropertyModelOut> = new Subject;

  constructor() { }

  public onPropertySelected() {
    return this.selectedProperty;
  }
  public propertySelected(property: PropertyModelOut) {
    this.selectedProperty.next(property);
  }

}
