import { Inject, Injectable, OnInit } from '@angular/core';
import { Observable, } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

export class AppSettings {
  api_url = '';
}

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService implements OnInit {
  appSettings: AppSettings;
  constructor(@Inject(DOCUMENT) private document: Document,
    private http: HttpClient) {


  }
  ngOnInit(): void {
    this.getSettings();
  }
  getSettings() {
    return this.http.get('../../app-settings.json')
      .toPromise();
  }
  get settings() {
    return this.appSettings;
  }
  get getLynchLogo() {
    return `${this.document.location.origin}/assets/img/BidvestNoonanLogo.png`;
  }
}
