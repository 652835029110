import { Component, OnInit, Input } from '@angular/core';
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../auth/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { HealthAndSafetyService } from '../../../api/services/health-and-safety.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileUploadForm } from '../hsFileUploadForm';
import { AlertsService } from '../../../services/alert.service';
import { PropertyModelOut } from '../../../api/models/property-model-out';
import { PropertyService } from '../../../api/services/property.service';

@Component({
  selector: 'app-property-health-and-safety',
  templateUrl: './property-health-and-safety.component.html',
  styleUrls: ['./property-health-and-safety.component.scss']
})
export class PropertyHealthAndSafetyComponent implements OnInit {
  @Input() propertyIdentity = 0;
  propertyRef = '';
  projectRef = '';
  supplier = '';
  description = '';
  expiryDate = null;
  hsDocs = [];
  hsDocsAvailable = false;
  showUploadControl = false;
  faFile = faFile;
  faDownload = faDownload;
  adminAccess = false;
  selectedDocumentsHS = [];
  allChecked = false;
  totalHealthSafetyRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  propertyName = '';
  healthSafetyUploadForm: FileUploadForm;
  property: PropertyModelOut;
  progress: any;
  acceptedFileTypes = ['.jpg', '.png', '.pdf', '.xlsx', '.docx', '.xlt'];
  fileTypeInvalid = false;
  fileTypeErrorMsg = 'Supported file types - ' + this.acceptedFileTypes.toString();
  fileSizeInvalid = false;
  fileSizeErrorMsg = 'Max. file size - 2MB';
  private file: File | null = null;


  constructor(
    private authService: AuthService,
    private healthAndSafetyService: HealthAndSafetyService,
    private propertyService: PropertyService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private alertService: AlertsService,
  ) {
    this.adminAccess = this.authService.roleMatch(['Administrator']);
    this.healthSafetyUploadForm = new FileUploadForm();
  }

  ngOnInit() {
    this.listenForRouteParams();
  }
  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      this.projectRef = params.id;
      this.loadPropertyHSReference(params.id);
    });
  }

  loadPropertyHSReference(projectRef: string) {
    const params: PropertyService.GetPropertyByProjectRefAsyncParams = {
      Authorization: '',
      projectRef : projectRef,
    };
    this.propertyService.GetPropertyByProjectRefAsync(params).subscribe(response => {
      this.property = response;
      this.propertyIdentity = response.id;
      this.loadHSAttachments();
    });
}

  loadHSAttachments() {
    const params: HealthAndSafetyService.GetHealthAndSafetyDocumentsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      supplier: '',
      dateOfUpload: null,
      projectRef: this.projectRef,
    };
    this.healthAndSafetyService.GetHealthAndSafetyDocumentsPagedAsync(params).subscribe(response => {
      this.totalHealthSafetyRecordCount = response.totalRecordCount;
      this.hsDocs = response.items;
      if (this.hsDocs.length > 0) {
        this.hsDocsAvailable = true;
      }
      this.spinner.hide();
    });
  }

  uploadComplete(e) {
    this.loadHSAttachments();
  }


  isPDF(attachmentURL: string) {
    return attachmentURL.includes('.pdf');
  }

  selectAllHS() {
    for (let i = 0; i < this.hsDocs.length; i++) {
      if (!this.hsDocs[i].selected) {
        this.hsDocs[i].selected = true;
        this.selectedDocumentsHS.push(this.hsDocs[i]);
      } else {
        this.hsDocs[i].selected = false;
        const index = this.selectedDocumentsHS.indexOf(this.hsDocs[i]);
        this.selectedDocumentsHS.splice(index, 1);
      }
    }
  }

  isCheckedHS(attachment, event) {
    const index = this.selectedDocumentsHS.indexOf(attachment);
    if (index === -1) {
      if (event.target.checked) {
        this.selectedDocumentsHS.push(attachment);
      }
    } else {
      this.selectedDocumentsHS.splice(index, 1);
    }
  }

  downloadHS() {
    this.selectedDocumentsHS.forEach(function (doc) {
      if (doc.selected) {
        FileSaver.saveAs(doc.url, doc.name);
      }
    });
    this.selectedDocumentsHS = [];
    this.allChecked = false;
    for (let i = 0; i < this.hsDocs.length; i++) {
      this.hsDocs[i].selected = false;
    }
    this.toastr.success('Download Successful');
  }

  handleFile(event) {
    const file = event && event.target.files[0];
    this.file = file;
    this.checkFileType(file);
    this.checkFileSize(file);
  }

  checkFileType( file: File) {
        const extensionStart = file.name.lastIndexOf('.');
        const extension = file.name.substring(extensionStart);
        if ( !this.acceptedFileTypes.includes(extension.toLowerCase()) ) {
            this.fileTypeInvalid = true;
        } else {
          this.fileTypeInvalid = false;
        }
  }

  pageHealthSafetyChanged(hsPage) {
    this.currentPage = hsPage;
    if (this.projectRef) {
      this.loadHSAttachments();
    }
  }


  checkFileSize( file: File) {
    const sizeInBytes = file.size;
    const sizeInMB = sizeInBytes / (1024 * 1024);
    if (sizeInMB > 2) {
        this.fileSizeInvalid = true;
    } else {
      this.fileSizeInvalid = false;
    }
  }

  uploadHS(event) {
    this.healthSafetyUploadForm.isSubmitted = true;
    if (this.healthSafetyUploadForm.form.valid && !this.fileSizeInvalid && !this.fileTypeInvalid) {

      const saveModel = this.healthSafetyUploadForm.getSaveModel(this.propertyIdentity, this.file);

      this.healthAndSafetyService.UploadHealthAndSafetyDocument(saveModel).subscribe(response => {
        if (response) {
          this.alertService.fileUploaded();
          this.healthSafetyUploadForm.setUpForm();
          this.file = null;
          this.loadHSAttachments();
        }});
    } else {
      this.alertService.fileValidation();
    }
  }

}
