import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from '../../../services/navigation.service';
import { faDownload} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../auth/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { DebtorsTransactionModelOut } from '../../../api/models/debtors-transaction-model-out';
import { DebtorsTransactionService } from '../../../api/services/debtors-transaction.service';
import { PropertyService } from '../../../api/services/property.service';
import { PropertyViewServiceService } from '../../properties/services/property-view-service.service';


@Component({
  selector: 'app-paid-debtors',
  templateUrl: './paid-debtors.component.html',
  styleUrls: ['./paid-debtors.component.scss']
})
export class PaidDebtorsComponent implements OnInit {
  debtorTransactions: DebtorsTransactionModelOut;
  adminAccess = false;
  faDownload = faDownload;
  paidInvoiceAttachment = [];
  selectedPaidInvoice = [];
  paidInvoices;
  property;
  debtorId = 0;
  debtorRef = '';
  propertyRef = '';
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  allChecked = false;
  constructor(
    private debtorService: DebtorsTransactionService,
    private propertyService: PropertyService,
    private propertyViewService: PropertyViewServiceService,
    private navigateTo: NavigationService,
    private toastr: ToastrService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute, ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
  }

  ngOnInit(): void {
    this.listenForRouteParams();
  }

  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.debtorId = params.id;
        this.loadFinancialDetails(params.id);
      }
    });
  }
  loadFinancialDetails(financialId: number) {
    const params: DebtorsTransactionService.GetDebtorsTransactionByIdAsyncParams = {
      Authorization: '',
      debtorsTransactionId: financialId,
    };
    this.debtorService.GetDebtorsTransactionByIdAsync(params).subscribe(response => {
      this.debtorTransactions = response;
      this.debtorRef = response.debtorRef;
      this.propertyRef = response.propertyRef;
      this.loadData(this.debtorRef);
    });
  }
  loadProperty(propertyId: number) {
    const params: PropertyService.GetPropertyByIdAsyncParams = {
      Authorization: '',
      propertyId : propertyId,
    };
    this.propertyService.GetPropertyByIdAsync(params).subscribe(response => {
      this.property = response;
      this.propertyViewService.propertySelected(response);
    });
}
  loadData(debtorRef) {
    // const params: DebtorsTransactionService.GetPaidInvoicesPagedAsyncParams = {
    //   Authorization: '',
    //   pageNo: this.currentPage,
    //   pageSize: this.pageSize,
    //   debtorRef: debtorRef
    // };
    // this.debtorService.GetDebtorsTransactionsPagedAsync(params).subscribe(response => {
    //   this.totalRecordCount = response.totalRecordCount;
    //   this.paidInvoices = response.items;
    // });
  }

  pageChanged(page) {
    this.currentPage = page;
    if (this.debtorRef) {
      this.loadData(this.debtorRef);
    }
  }

  selectAll() { // REQUIRED IF WE IMPLEMENT MULTI DOWNLOAD -- NEEDS REVIEWED
    // for (let i = 0; i < this.paidInvoiceAttachment.length; i++) {
    //   if (!this.paidInvoiceAttachment[i].selected) {
    //     this.paidInvoiceAttachment[i].selected = true;
    //     this.selectedPaidInvoice.push(this.paidInvoiceAttachment[i]);
    //   } else {
    //     this.paidInvoiceAttachment[i].selected = false;
    //     const index = this.selectedPaidInvoice.indexOf(this.paidInvoiceAttachment[i]);
    //     this.selectedPaidInvoice.splice(index, 1);
    //   }
    // }
  }

  isChecked(attachment, event) {
    const index = this.selectedPaidInvoice.indexOf(attachment);
    if (index === -1) {
      if (event.target.checked) {
        this.selectedPaidInvoice.push(attachment);
      }
    } else {
      this.selectedPaidInvoice.splice(index, 1);
    }
  }

  populatePDFData() {
    this.selectedPaidInvoice.forEach(function (doc) {
      if (doc.selected) {
        this.generatePDFDetails(this.selectedPaidInvoice);
      }
    });
    this.selectedPaidInvoice = [];
    this.allChecked = false;
    for (let i = 0; i < this.paidInvoiceAttachment.length; i++) {
      this.paidInvoiceAttachment[i].selected = false;
    }
    this.toastr.success('Paid Invoice Generated for' + this.debtorRef);
  }

  // generatePDFDetails(FinancialPDFDetails: Array<DebtorsTransactionModelIn>) {  // PDF File Saver Component - *AWAITING API*
  //   const financePDFs: DebtorsTransactionService.GeneratePDFParams = {
  //     Authorization: '',
  //     models: FinancialPDFDetails
  //   };
  //   this.debtorService.GeneratePDF(financePDFs).subscribe(response => {
  //     const blob: any = new Blob([response], {type: 'text/plain; charset=utf-8'});
  //     const fileName = 'DebtorSummary' + '.pdf';
  //     FileSaver.saveAs(blob, fileName);
  //     this.toastr.success('PDF Information Download Successful');
  //     this.loadDebtorsTransations();
  //   });
  // }
}
