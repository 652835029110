import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../../../auth/services/auth.service';
import { UserFilters } from '../models/userFilters';
import { UserFilterForm } from '../userFilterForm';
import { NavigationService } from '../../../../services/navigation.service';

@Component({
  selector: 'app-user-filters',
  templateUrl: './user-filters.component.html',
  styleUrls: ['./user-filters.component.scss']
})
export class UserFiltersComponent implements OnInit {
  @Output() search: EventEmitter<UserFilters> = new EventEmitter();
  filters: UserFilters = {
    keywords: '',
  };
  filterForm: UserFilterForm;
  currentPage = 1;
  pageSize = 20;
  adminAccess = false;
  constructor(
    private authService: AuthService,
    private navigateTo: NavigationService,
    ) {
    this.adminAccess = this.authService.roleMatch(['Administrator']);
    this.filterForm = new UserFilterForm();
   }

  ngOnInit() {
  }

  onSearchClick() {
    this.search.emit(this.filters);
  }

  resetFilters() {
    this.filters = {
      keywords: '',
    };
    this.filterForm.form.patchValue(this.filters);
    this.search.emit(this.filters);
  }
  create() {
    this.navigateTo.createUser();
  }
}
