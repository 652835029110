import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { PropertyNoteModelIn } from '../../../api/models/property-note-model-in';
import { PropertyService } from './../../../api/services/property.service';

export class CreateNotesForm extends AllsopBaseForm {
    constructor() {
        super();

        this.setUpForm();
    }
    public setUpForm() {
        this.resetForm();
        const model: PropertyNoteModelIn = {
            propertyId: 0,
            note: '',
        };

        this.setUpFormForEdit(model);
    }

    public setUpFormForEdit(value: PropertyNoteModelIn) {
        this.resetForm();
        this.formGroup = this.fb.group<PropertyNoteModelIn>({
            propertyId: this.getControl(value.propertyId, false),
            note: this.getControl(value.note, false),
        },
        );
    }

    getSaveModel(propertyId: number): PropertyService.CreatePropertyNoteAsyncParams {
        const value: PropertyNoteModelIn = this.formGroup.value;
        return {
            Authorization: '',
            modelIn: {
                propertyId: propertyId,
                note: value.note
            }
        };
    }
}

