import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { UserModelOut} from '../../../api/models/user-model-out';
import { AccountService } from '../../../api/services/account.service';

export class UserEditForm extends AllsopBaseForm {

    constructor() {
        super();

        this.setUpForm();
    }
    public setUpForm() {
      this.resetForm();

      const model: any = {
        id: '0',
        firstName: '',
        lastName: '',
        roles: '',
        username: '',
        password: '',
        email: '',
        analysisCode: '',
        reportGroupId: ''
      };
      this.setUpFormForEdit(model);
    }

    public setUpFormForEdit(value: UserModelOut) {
        this.resetForm();

        this.formGroup = this.fb.group<UserModelOut>({
            id: this.getControl(value.id),
            firstName: this.getControl(value.firstName),
            lastName: this.getControl(value.lastName),
            email: this.getEmailControl(value.email),
            roleId: this.getControl(value.roleId),
            userName: this.getControl(value.userName, false),
            analysisCode: this.getControl(value.analysisCode, false),
            reportGroupId: this.getControl(value.reportGroupId, false),
        });
    }

    getSaveModel(userId: string): AccountService.UpdateUserAsyncParams {
        const value = this.formGroup.value;
        return {
            Authorization: '',
            userId: userId,
            model: {
                firstName: value.firstName,
                lastName: value.lastName,
                roleId: value.roleId,
                userName: value.userName,
                analysisCode: value.analysisCode,
                email: value.email,
                reportGroupId: value.reportGroupId
            }
        };
    }
}
