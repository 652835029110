import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../../../api/services/account.service';
import { AlertsService } from '../../../../services/alert.service';
import { UpdatePasswordForm } from '../updateuserPasswordForm';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  updatePasswordForm: UpdatePasswordForm;
  isSubmitted = false;
  @Input() userId = '';
  constructor(
    private userService: AccountService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertsService) {

    this.updatePasswordForm = new UpdatePasswordForm();
    this.updatePasswordForm.setUpForm();

  }
  ngOnInit() {
    this.listenForRouteParams();
  }
  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.userId = params.id;
      }
    });
  }
  get passswordsDontMatch(): boolean {
    return this.updatePasswordForm.fieldErrors('confirmPassword') != null;
  }


  save() {
    this.updatePasswordForm.isSubmitted = true;

    if (this.updatePasswordForm.form.valid) {
      const saveModel = this.updatePasswordForm.getSaveModel(this.userId);

      this.userService.UpdatePasswordAsync(saveModel).subscribe(response => {
        this.alertService.passwordChangedSuccessfully();
      });
    }
  }
}
