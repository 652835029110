/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SubContractorDocumentsPagedModelOut } from '../models/sub-contractor-documents-paged-model-out';
@Injectable({
  providedIn: 'root',
})
class SubContractorService extends __BaseService {
  static readonly GetSubContractorDocumentsPagedAsyncPath = '/api/SubContractor/paged';
  static readonly UploadSubContractorDocumentPath = '/api/SubContractor/fileUpload';
  static readonly GetSubContractorDocumentsByJobIdPagedAsyncPath = '/api/SubContractor/{jobId}/documents';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Sub Contractor Documents Paged
   * @param params The `SubContractorService.GetSubContractorDocumentsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `subContractorName`:
   *
   * - `siteName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `jobRef`:
   *
   * - `expiryDate`:
   *
   * - `documentName`:
   *
   * - `dateOfUpload`:
   *
   * - `clientName`:
   *
   * @return Success
   */
  GetSubContractorDocumentsPagedAsyncResponse(params: SubContractorService.GetSubContractorDocumentsPagedAsyncParams): __Observable<__StrictHttpResponse<SubContractorDocumentsPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.subContractorName != null) __params = __params.set('subContractorName', params.subContractorName.toString());
    if (params.siteName != null) __params = __params.set('siteName', params.siteName.toString());
    if (params.projectRef != null) __params = __params.set('projectRef', params.projectRef.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    if (params.jobRef != null) __params = __params.set('jobRef', params.jobRef.toString());
    if (params.expiryDate != null) __params = __params.set('expiryDate', params.expiryDate.toString());
    if (params.documentName != null) __params = __params.set('documentName', params.documentName.toString());
    if (params.dateOfUpload != null) __params = __params.set('dateOfUpload', params.dateOfUpload.toString());
    if (params.clientName != null) __params = __params.set('clientName', params.clientName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/SubContractor/paged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SubContractorDocumentsPagedModelOut>;
      })
    );
  }
  /**
   * Get Sub Contractor Documents Paged
   * @param params The `SubContractorService.GetSubContractorDocumentsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `subContractorName`:
   *
   * - `siteName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `jobRef`:
   *
   * - `expiryDate`:
   *
   * - `documentName`:
   *
   * - `dateOfUpload`:
   *
   * - `clientName`:
   *
   * @return Success
   */
  GetSubContractorDocumentsPagedAsync(params: SubContractorService.GetSubContractorDocumentsPagedAsyncParams): __Observable<SubContractorDocumentsPagedModelOut> {
    return this.GetSubContractorDocumentsPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as SubContractorDocumentsPagedModelOut)
    );
  }

  /**
   * @param params The `SubContractorService.UploadSubContractorDocumentParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `subContractor`:
   *
   * - `jobId`:
   *
   * - `file0`:
   *
   * - `expiryDate`:
   *
   * - `description`:
   *
   * @return Success
   */
  UploadSubContractorDocumentResponse(params: SubContractorService.UploadSubContractorDocumentParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.subContractor != null) { __formData.append('subContractor', params.subContractor as string | Blob);}
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.file0 != null) { __formData.append('file0', params.file0 as string | Blob);}
    if (params.expiryDate != null) { __formData.append('expiryDate', params.expiryDate as string | Blob);}
    if (params.description != null) { __formData.append('description', params.description as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SubContractor/fileUpload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `SubContractorService.UploadSubContractorDocumentParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `subContractor`:
   *
   * - `jobId`:
   *
   * - `file0`:
   *
   * - `expiryDate`:
   *
   * - `description`:
   *
   * @return Success
   */
  UploadSubContractorDocument(params: SubContractorService.UploadSubContractorDocumentParams): __Observable<string> {
    return this.UploadSubContractorDocumentResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Get Sub Contractor Documents Paged by Job Id
   * @param params The `SubContractorService.GetSubContractorDocumentsByJobIdPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `jobId`:
   *
   * - `Authorization`: Access Token
   *
   * - `subContractorName`:
   *
   * - `siteName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `jobRef`:
   *
   * - `expiryDate`:
   *
   * - `documentName`:
   *
   * - `dateOfUpload`:
   *
   * - `clientName`:
   *
   * @return Success
   */
  GetSubContractorDocumentsByJobIdPagedAsyncResponse(params: SubContractorService.GetSubContractorDocumentsByJobIdPagedAsyncParams): __Observable<__StrictHttpResponse<SubContractorDocumentsPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.subContractorName != null) __params = __params.set('subContractorName', params.subContractorName.toString());
    if (params.siteName != null) __params = __params.set('siteName', params.siteName.toString());
    if (params.projectRef != null) __params = __params.set('projectRef', params.projectRef.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    if (params.jobRef != null) __params = __params.set('jobRef', params.jobRef.toString());
    if (params.expiryDate != null) __params = __params.set('expiryDate', params.expiryDate.toString());
    if (params.documentName != null) __params = __params.set('documentName', params.documentName.toString());
    if (params.dateOfUpload != null) __params = __params.set('dateOfUpload', params.dateOfUpload.toString());
    if (params.clientName != null) __params = __params.set('clientName', params.clientName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/SubContractor/${encodeURIComponent(params.jobId)}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SubContractorDocumentsPagedModelOut>;
      })
    );
  }
  /**
   * Get Sub Contractor Documents Paged by Job Id
   * @param params The `SubContractorService.GetSubContractorDocumentsByJobIdPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `jobId`:
   *
   * - `Authorization`: Access Token
   *
   * - `subContractorName`:
   *
   * - `siteName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `jobRef`:
   *
   * - `expiryDate`:
   *
   * - `documentName`:
   *
   * - `dateOfUpload`:
   *
   * - `clientName`:
   *
   * @return Success
   */
  GetSubContractorDocumentsByJobIdPagedAsync(params: SubContractorService.GetSubContractorDocumentsByJobIdPagedAsyncParams): __Observable<SubContractorDocumentsPagedModelOut> {
    return this.GetSubContractorDocumentsByJobIdPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as SubContractorDocumentsPagedModelOut)
    );
  }
}

module SubContractorService {

  /**
   * Parameters for GetSubContractorDocumentsPagedAsync
   */
  export interface GetSubContractorDocumentsPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    subContractorName?: string;
    siteName?: string;
    projectRef?: string;
    keywords?: string;
    jobRef?: string;
    expiryDate?: string;
    documentName?: string;
    dateOfUpload?: string;
    clientName?: string;
  }

  /**
   * Parameters for UploadSubContractorDocument
   */
  export interface UploadSubContractorDocumentParams {

    /**
     * Access Token
     */
    Authorization: string;
    subContractor?: string;
    jobId?: number;
    file0?: Blob;
    expiryDate?: string;
    description?: string;
  }

  /**
   * Parameters for GetSubContractorDocumentsByJobIdPagedAsync
   */
  export interface GetSubContractorDocumentsByJobIdPagedAsyncParams {
    pageSize: number;
    pageNo: number;
    jobId: number;

    /**
     * Access Token
     */
    Authorization: string;
    subContractorName?: string;
    siteName?: string;
    projectRef?: string;
    keywords?: string;
    jobRef?: string;
    expiryDate?: string;
    documentName?: string;
    dateOfUpload?: string;
    clientName?: string;
  }
}

export { SubContractorService }
