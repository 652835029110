import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DebtorFiltersForm } from './filterFormDebtor';
import { DebtorTransationFilters } from './models/debtor-finance-filters';

@Component({
  selector: 'app-finance-filters',
  templateUrl: './finance-filters.component.html',
  styleUrls: ['./finance-filters.component.scss']
})
export class FinanceFiltersComponent implements OnInit {
  @Output() search: EventEmitter<DebtorTransationFilters> = new EventEmitter();
  filters: DebtorTransationFilters = {
    keywords: '',
    transactionStatus: '',
    startDate: null,
    endDate: null
  };
  filterForm: DebtorFiltersForm;
  adminAccess = false;
  constructor() {
    this.filterForm = new DebtorFiltersForm();
  }

  ngOnInit() { }
  onSearchClick() {
    this.search.emit(this.filters);
  }
  resetFilters() {
    const sDate = new Date();
    sDate.setDate(sDate.getDate() - 3);

    this.filters = {
      keywords: '',
      transactionStatus: '',
      startDate: null,
      endDate: null
    };
    this.search.emit(this.filters);
    this.filterForm.setUpForm();
  }

  onStatusChange(event) {
    this.filters.transactionStatus = event.target.value;
  }

  setStartDate(date) {
    this.filters.startDate = date;
  }

  setEndDate(date) {
    this.filters.endDate = date;
  }
}
