/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EstimateStatusModelOut } from '../models/estimate-status-model-out';
import { EstimateStatusModelIn } from '../models/estimate-status-model-in';
@Injectable({
  providedIn: 'root',
})
class EstimateStatusService extends __BaseService {
  static readonly GetEstimateStatusesByFormResultKeyAsyncPath = '/api/estimatestatus/statuses';
  static readonly GetEstimateCommentsByFormResultKeyAsyncPath = '/api/estimatestatus/comments';
  static readonly PostAsyncPath = '/api/estimatestatus/addStatus';
  static readonly AddEstimateCommentAsyncPath = '/api/estimatestatus/addComment';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get EstimateStatus By Id
   * @param params The `EstimateStatusService.GetEstimateStatusesByFormResultKeyAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `formResultKey`:
   *
   * @return Success
   */
  GetEstimateStatusesByFormResultKeyAsyncResponse(params: EstimateStatusService.GetEstimateStatusesByFormResultKeyAsyncParams): __Observable<__StrictHttpResponse<Array<EstimateStatusModelOut>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.formResultKey != null) __params = __params.set('formResultKey', params.formResultKey.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/estimatestatus/statuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EstimateStatusModelOut>>;
      })
    );
  }
  /**
   * Get EstimateStatus By Id
   * @param params The `EstimateStatusService.GetEstimateStatusesByFormResultKeyAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `formResultKey`:
   *
   * @return Success
   */
  GetEstimateStatusesByFormResultKeyAsync(params: EstimateStatusService.GetEstimateStatusesByFormResultKeyAsyncParams): __Observable<Array<EstimateStatusModelOut>> {
    return this.GetEstimateStatusesByFormResultKeyAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<EstimateStatusModelOut>)
    );
  }

  /**
   * Get EstimateStatus Comments By Id
   * @param params The `EstimateStatusService.GetEstimateCommentsByFormResultKeyAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `formResultKey`:
   *
   * @return Success
   */
  GetEstimateCommentsByFormResultKeyAsyncResponse(params: EstimateStatusService.GetEstimateCommentsByFormResultKeyAsyncParams): __Observable<__StrictHttpResponse<Array<EstimateStatusModelOut>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.formResultKey != null) __params = __params.set('formResultKey', params.formResultKey.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/estimatestatus/comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EstimateStatusModelOut>>;
      })
    );
  }
  /**
   * Get EstimateStatus Comments By Id
   * @param params The `EstimateStatusService.GetEstimateCommentsByFormResultKeyAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `formResultKey`:
   *
   * @return Success
   */
  GetEstimateCommentsByFormResultKeyAsync(params: EstimateStatusService.GetEstimateCommentsByFormResultKeyAsyncParams): __Observable<Array<EstimateStatusModelOut>> {
    return this.GetEstimateCommentsByFormResultKeyAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<EstimateStatusModelOut>)
    );
  }

  /**
   * Creates a EstimateStatus
   * @param params The `EstimateStatusService.PostAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `quoteRef`:
   *
   * - `propertyRef`:
   *
   * - `modelIn`:
   *
   * @return Success
   */
  PostAsyncResponse(params: EstimateStatusService.PostAsyncParams): __Observable<__StrictHttpResponse<EstimateStatusModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.quoteRef != null) __params = __params.set('quoteRef', params.quoteRef.toString());
    if (params.propertyRef != null) __params = __params.set('propertyRef', params.propertyRef.toString());
    __body = params.modelIn;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/estimatestatus/addStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EstimateStatusModelOut>;
      })
    );
  }
  /**
   * Creates a EstimateStatus
   * @param params The `EstimateStatusService.PostAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `quoteRef`:
   *
   * - `propertyRef`:
   *
   * - `modelIn`:
   *
   * @return Success
   */
  PostAsync(params: EstimateStatusService.PostAsyncParams): __Observable<EstimateStatusModelOut> {
    return this.PostAsyncResponse(params).pipe(
      __map(_r => _r.body as EstimateStatusModelOut)
    );
  }

  /**
   * Creates a EstimateStatus Comment
   * @param params The `EstimateStatusService.AddEstimateCommentAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `quoteRef`:
   *
   * - `propertyRef`:
   *
   * - `modelIn`:
   *
   * @return Success
   */
  AddEstimateCommentAsyncResponse(params: EstimateStatusService.AddEstimateCommentAsyncParams): __Observable<__StrictHttpResponse<EstimateStatusModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.quoteRef != null) __params = __params.set('quoteRef', params.quoteRef.toString());
    if (params.propertyRef != null) __params = __params.set('propertyRef', params.propertyRef.toString());
    __body = params.modelIn;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/estimatestatus/addComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EstimateStatusModelOut>;
      })
    );
  }
  /**
   * Creates a EstimateStatus Comment
   * @param params The `EstimateStatusService.AddEstimateCommentAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `quoteRef`:
   *
   * - `propertyRef`:
   *
   * - `modelIn`:
   *
   * @return Success
   */
  AddEstimateCommentAsync(params: EstimateStatusService.AddEstimateCommentAsyncParams): __Observable<EstimateStatusModelOut> {
    return this.AddEstimateCommentAsyncResponse(params).pipe(
      __map(_r => _r.body as EstimateStatusModelOut)
    );
  }
}

module EstimateStatusService {

  /**
   * Parameters for GetEstimateStatusesByFormResultKeyAsync
   */
  export interface GetEstimateStatusesByFormResultKeyAsyncParams {

    /**
     * Access Token
     */
    Authorization: string;
    formResultKey?: string;
  }

  /**
   * Parameters for GetEstimateCommentsByFormResultKeyAsync
   */
  export interface GetEstimateCommentsByFormResultKeyAsyncParams {

    /**
     * Access Token
     */
    Authorization: string;
    formResultKey?: string;
  }

  /**
   * Parameters for PostAsync
   */
  export interface PostAsyncParams {

    /**
     * Access Token
     */
    Authorization: string;
    quoteRef?: string;
    propertyRef?: string;
    modelIn?: EstimateStatusModelIn;
  }

  /**
   * Parameters for AddEstimateCommentAsync
   */
  export interface AddEstimateCommentAsyncParams {

    /**
     * Access Token
     */
    Authorization: string;
    quoteRef?: string;
    propertyRef?: string;
    modelIn?: EstimateStatusModelIn;
  }
}

export { EstimateStatusService }
