import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { AssetService } from '../../../api/services/asset.service';
import { AssetModelIn } from '../../../api/models/asset-model-in';
import { AssetModelOut } from '../../../api/models/asset-model-out';

export class AssetLifecycleForm extends AllsopBaseForm {

    constructor() {
        super();
    }
    public setUpForm() {
        this.resetForm();

        const model: AssetModelIn = {
            yearOfInstallation: 0,
            costForRemainingLife: 0,
            costOfRepairsPA: 0,
            costOfReplacement: 0,
            projectedLifeCycle: 0
        };
        this.setUpFormForEdit(model);
    }

    public setUpFormForEdit(modelOut: AssetModelOut) {
        this.resetForm();

        this.formGroup = this.fb.group<AssetModelOut>({
            yearOfInstallation: this.getControl(modelOut.yearOfInstallation),
            costForRemainingLife: this.getControl(modelOut.costForRemainingLife),
            costOfRepairsPA: this.getControl(modelOut.costOfRepairsPA),
            costOfReplacement: this.getControl(modelOut.costOfReplacement),
            projectedLifeCycle: this.getControl(modelOut.projectedLifeCycle)
        });
    }

    getEditSaveModel(assetId: number): AssetService.UpdateAssetLifecyleByAssetIdParams {
        return {
            Authorization: '',
            modelIn: this.getModelIn(),
            assetId: assetId
        };
    }
    getModelIn(): AssetModelIn {
        const value: AssetModelIn = this.formGroup.value;
        return {
            yearOfInstallation: value.yearOfInstallation,
            costForRemainingLife: value.costForRemainingLife,
            costOfRepairsPA: value.costOfRepairsPA,
            costOfReplacement: value.costOfReplacement,
            projectedLifeCycle: value.projectedLifeCycle
        };
    }
}
