import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { AssetFilters } from '../models/asset-filters';

export class AssetFiltersForm extends AllsopBaseForm {
    constructor() {
        super();

        this.setUpForm();
    }
    public setUpForm() {
        const model: AssetFilters = {
            keywords: '',
            projectRef: '',
            completedDate: null,
            assetCategory: ''
        };

        this.setupForm(model);
    }

    public setupForm(value: AssetFilters) {
        this.resetForm();
        this.formGroup = this.fb.group<AssetFilters>({
            keywords: this.getControl(value.keywords, false),
            projectRef: this.getControl(value.projectRef, false),
            completedDate: this.getControl(value.completedDate, false),
            assetCategory: this.getControl(value.assetCategory, false),
        },
        );
    }
}


