import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PropertyService } from '../../../api/services/property.service';
import { JobFilters } from '../../jobs/models/job-filters';
import { AssetService } from '../../../api/services/asset.service';
import { JobService } from '../../../api/services/job.service';
import { JobFiltersForm } from '../../jobs/job-filters/jobFiltersForm';
import { AssetNamesPagedModelOut } from '../../../api/models/asset-names-paged-model-out';
import { PropertyNamesPagedModelOut } from '../../../api/models/property-names-paged-model-out';
import { Observable, Observer, noop, of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { AlertsService } from '../../../services/alert.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';


@Component({
  selector: 'app-reactive-filters',
  templateUrl: './reactive-filters.component.html',
  styleUrls: ['./reactive-filters.component.scss']
})
export class ReactiveFiltersComponent implements OnInit {
  @Output() search: EventEmitter<JobFilters> = new EventEmitter();
  filters: JobFilters = {
    keywords: '',
    jobType: '04' || '06',
    assets: '',
    properties: '',
    jobStatus: '',
    startDate: null,
    endDate: null,
    jobRef: ''
  };
  filterForm: JobFiltersForm;
  properties = [];
  assets = [];
  jobStatusList = [];
  selectedStatusId = 0;
  jobTypeList = [];
  currentPage = 1;
  pageSize = 20;
  name = '';
  assetSuggestions$: Observable<AssetNamesPagedModelOut[]>;
  propSuggestions$: Observable<PropertyNamesPagedModelOut[]>;
  errorMessage: string;
  constructor(

    private propertyService: PropertyService,
    private jobTypeService: JobService,
    private jobStatus: JobService,
    private assetService: AssetService,
    private alertsService: AlertsService
  ) {
    this.filterForm = new JobFiltersForm();
  }

  ngOnInit() {
    this.loadJobTypes();
    this.loadJobStatus();

    this.assetSuggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.filterForm.form.value.assets);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          const params: AssetService.GetAssetNamesPagedAsyncParams = {
            Authorization: '',
            pageNo: this.currentPage,
            pageSize: this.pageSize,
            assetName: this.filterForm.form.value.assets

          };

          return this.assetService.GetAssetNamesPagedAsync(params)
            .pipe(
              map((data: AssetNamesPagedModelOut) => data.items.map(
                (s) => ({ assetName: s.assetRef + ' - ' + s.assetName, assetRef: s.assetRef })) || []),
              tap(() => noop, err => {
                this.errorMessage = err && err.message || 'Unable to find matching asset';
              })
            );
        }

        this.alertsService.error('Unable to find matching asset');

        return of([]);
      })
    );

    this.propSuggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.filterForm.form.value.properties);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          const params: PropertyService.GetPropertyNamesPagedAsyncParams = {
            Authorization: '',
            pageNo: this.currentPage,
            pageSize: this.pageSize,
            keywords: this.filterForm.form.value.properties

          };

          return this.propertyService.GetPropertyNamesPagedAsync(params)
            .pipe(
              map((data: PropertyNamesPagedModelOut) => data.items.map(
                (s) => ({ projectDescription: s.projectDescription + ' (' + s.projectCode + ')', projectRef: s.projectCode })) || []),
              tap(() => noop, err => {
                this.errorMessage = err && err.message || 'Unable to find matching property';
              })
            );
        }

        this.alertsService.error('Unable to find matching property');

        return of([]);
      })
    );
  }
  onSearchClick() {
    this.search.emit(this.filters);
  }
  resetFilters() {
    this.filters = {
      keywords: '',
      jobType: '04' || '06',
      assets: '',
      properties: '',
      jobStatus: '',
      startDate: null,
      endDate: null,
      jobRef: '',
    };
    this.filterForm.form.patchValue(this.filters);
    this.search.emit(this.filters);
  }

  onSelectAsset(event: TypeaheadMatch): void {
    this.filterForm.form.value.assets = event.item.assetRef;
    this.filters.assets = event.item.assetRef;
  }
  onSelectProperty(event: TypeaheadMatch): void {
    this.filterForm.form.value.properties = event.item.propertyRef;
    this.filters.properties = event.item.propertyRef;
  }
  onBackspaceProp(event) {
    const key = (window.Event) ? event.which : event.keyCode;
    if (key === 8) {
      this.filters.properties = '';
    }
  }
  onBackspaceAsset(event) {
    const key = (window.Event) ? event.which : event.keyCode;
    if (key === 8) {
      this.filters.assets = '';
    }
  }

  loadJobTypes() {
    this.jobTypeService.GetJobTypesAsync('').subscribe(response => {
      this.jobTypeList = response;
    });
  }

  loadJobStatus() {
    this.jobStatus.GetJobStatusesAsync('').subscribe(response => {
      this.jobStatusList = response;
    });
  }

  setStartDate(date) {
    this.filters.startDate = date;
  }

  setEndDate(date) {
    this.filters.endDate = date;
  }

}
