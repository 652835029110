import { Component, OnInit, Input} from '@angular/core';
import { faStickyNote, faClock } from '@fortawesome/free-solid-svg-icons';
import { PropertyService } from './../../../api/services/property.service';
import { CreateNotesForm } from '../property-notes-tab/createNotesForm';
import { NavigationService } from '../../../services/navigation.service';
import { AlertsService } from '../../../services/alert.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-property-notes-tab',
  templateUrl: './property-notes-tab.component.html',
  styleUrls: ['./property-notes-tab.component.scss']
})
export class PropertyNotesTabComponent implements OnInit {
  faStickyNote = faStickyNote;
  notesAvailable = false;
  faClock = faClock;
  note = '';
  notes = [];
  propertyId = 0;
  noteForm: CreateNotesForm;
  isSubmitted = false;

  constructor(
     private noteService: PropertyService,
     private navigateTo: NavigationService,
     private alertService: AlertsService,
     private activatedRoute: ActivatedRoute
     ) { }

     ngOnInit() {
        this.listenForRouteParams();
        this.loadNotes();
    }

    listenForRouteParams() {
      this.activatedRoute.params.subscribe(params => {
        this.propertyId = params.id;
        if (params.id) {
          this.setUpFormForCreate();
        }
      });
    }

    setUpFormForCreate() {
      this.noteForm = new CreateNotesForm();
      this.noteForm.setUpForm();
    }

    loadNotes() {
      const params: PropertyService.GetPropertyNotesAsyncParams = {
        Authorization: '',
        propertyId: this.propertyId
      };
      this.noteService.GetPropertyNotesAsync(params).subscribe((response) => {
       this.notes = response;
       if (this.notes.length > 0) {
        this.notesAvailable = true;
      }
      });
    }
  saveNotes() {
    this.noteForm.isSubmitted = true;
    if (this.noteForm.form.valid) {
      const saveModel = this.noteForm.getSaveModel(this.propertyId);
      this.noteService.CreatePropertyNoteAsync(saveModel).subscribe(response => {
        window.location.reload();
        this.alertService.success('');
      });
    }
  }
}
