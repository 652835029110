import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobDetailsViewService {
  jobId = new BehaviorSubject<number>(0);
  constructor() { }

  public LoadCustomerRelatedData(customerId: number) {
    this.jobId.next(customerId);
  }
  public OnCustomerIdChanged() {
    return this.jobId;
  }
}

