import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons';
import { DocumentFilters } from '../models/documentFilters';
import { DocumentFiltersForm } from '../../health-and-safety-documents/documentFiltersForm';
import { PropertyService } from '../../../api/services/property.service';
import { Observable, Observer, noop, of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { PropertyNamesPagedModelOut } from '../../../api/models/property-names-paged-model-out';
import { AlertsService } from '../../../services/alert.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';


@Component({
  selector: 'app-health-safety-filters',
  templateUrl: './health-safety-filters.component.html',
  styleUrls: ['./health-safety-filters.component.scss']
})
export class HealthSafetyFiltersComponent implements OnInit {
  @Output() search: EventEmitter<DocumentFilters> = new EventEmitter();
  filters: DocumentFilters = {
    keywords: '',
    propertyName: '',
    dateOfUpload: null,
    supplier: '',
    documentName: '',
    description: '',
    expiryDate: null
  };
  filterForm: DocumentFiltersForm;
  currentPage = 1;
  pageSize = 20;
  propertiesList = [];
  supplierList = [];
  faDownload = faDownload;
  faFile = faFile;
  propSuggestions$: Observable<PropertyNamesPagedModelOut[]>;
  errorMessage: string;
  constructor(
    private propertyService: PropertyService,
    private alertsService: AlertsService
  ) {
    this.filterForm = new DocumentFiltersForm();
  }

  ngOnInit() {
    this.propSuggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.filterForm.form.value.propertyName);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          const params: PropertyService.GetPropertyNamesPagedAsyncParams = {
            Authorization: '',
            pageNo: this.currentPage,
            pageSize: this.pageSize,
            keywords: this.filterForm.form.value.propertyName

          };

          return this.propertyService.GetPropertyNamesPagedAsync(params)
            .pipe(
              map((data: PropertyNamesPagedModelOut) => data.items.map(
                (s) => ({ projectDescription: s.projectDescription + ' (' + s.projectCode + ')', projectRef: s.projectCode })) || []),
              tap(() => noop, err => {
                this.errorMessage = err && err.message || 'Unable to find matching property';
              })
            );
        }

        this.alertsService.error('Unable to find matching property');

        return of([]);
      })
    );
  }

  onSearchClick() {
    this.search.emit(this.filters);
  }

  resetFilters() {
    this.filters = {
      keywords: '',
      propertyName: '',
      dateOfUpload: null,
      supplier: '',
      documentName: '',
      description: '',
      expiryDate: null
    };
    this.filterForm.form.patchValue(this.filters);
    this.search.emit(this.filters);
  }

  onSelectProperty(event: TypeaheadMatch): void {
    this.filterForm.form.value.properties = event.item.propertyRef;
    this.filters.propertyName = event.item.siteName;
  }
  onBackspaceProp(event) {
    const key = (window.Event) ? event.which : event.keyCode;
    if (key === 8) {
      this.filters.propertyName = '';
    }
  }

  setDate(date) {
    this.filters.dateOfUpload = date;
  }

  setDateHs(date) {
    this.filters.expiryDate = date;
  }

}
