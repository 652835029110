import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { CustomerModalService } from '../../customers/customer.modal.services';
import { PropertyFilters } from '../models/property-filters';
import { PropertyModalService } from '../services/property.modal.services';
import { AlertsService } from '../../../services/alert.service';
import { PropertyNamesPagedModelOut } from '../../../api/models/property-names-paged-model-out';
import { Observable, Observer, noop, of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { PropertyService } from '../../../api/services/property.service';
import { PropertyFiltersForm } from './propertyFiltersForm';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';


@Component({
  selector: 'app-properties-filters',
  templateUrl: './properties-filters.component.html',
  styleUrls: ['./properties-filters.component.scss']
})
export class PropertiesFiltersComponent implements OnInit {
  @Output() search: EventEmitter<PropertyFilters> = new EventEmitter();
  @Input() checkedProperties = [];
  adminAccess = false;
  currentPage = 1;
  pageSize = 20;
  filterForm: PropertyFiltersForm;
  filters: PropertyFilters = {
    keywords: '',
    properties: '',
  };
  errorMessage: string;
  constructor(
    private authService: AuthService,
    private alertsService: AlertsService,
    private modalService: CustomerModalService,

  ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
    this.filterForm = new PropertyFiltersForm();
  }

  ngOnInit() {
    this.resetFilters();
  }
  onSearchClick() {
    this.search.emit(this.filters);
  }
  resetFilters() {
    this.filters = {
      keywords: '',
      properties: '',
    };
    this.filterForm.form.patchValue(this.filters);
    this.search.emit(this.filters);
  }

  assignAccountManagers() {
    console.log(this.checkedProperties);
    if (this.checkedProperties.length !== 0) {
    this.modalService.assignAccountManagers(this.checkedProperties);
    } else {
      this.alertsService.Warning('Please select one or more properties');
    }
  }
  unassignAccountManagers() {
    console.log(this.checkedProperties);
    if (this.checkedProperties.length !== 0) {
    this.modalService.unassignAccountManagers(this.checkedProperties);
    } else {
      this.alertsService.Warning('Please select one or more properties');
    }
  }
}
