import { Component, OnInit } from '@angular/core';
import { LoginForm} from './login-form';
import { AuthService} from '../services/auth.service';
import { NavigationService } from '../../services/navigation.service';
import { AlertsService } from '../../services/alert.service';
import { MenuService } from '../../services/menu.service';
import { LoginModalService } from '../login/login.modal.services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: LoginForm;
  adminLogIn = false;
  reDirectURL: string;

  constructor(
    private auth: AuthService,
    private menuService: MenuService,
    private modalService: LoginModalService,
    private navigateTo: NavigationService,
    private alertService: AlertsService,
    private activatedRoute: ActivatedRoute
    ) {
    this.loginForm = new LoginForm();
  }
  ngOnInit() {
   this.activatedRoute.queryParams.subscribe(queryParams => {
    this.reDirectURL = queryParams['reDirectUrl'];
  });
  }

  login() {
    this.loginForm.isSubmitted = true;
    if (this.loginForm.allowSave()) {
      const saveModel = this.loginForm.getSaveModel();
      this.auth.login(saveModel).then(() => {
      this.alertService.loginSuccessful();
      if (this.reDirectURL) {
        this.navigateTo.redirectionPath(this.reDirectURL);
      } else {
        this.navigateTo.home();
        this.menuService.toggleMenu();
      }
      });
    }
  }

  viewLoginModal() {
    this.modalService.viewLoginModal();
  }
}
