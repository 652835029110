import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Provider, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularCommonModule } from '@allsop/angular-common';
import { AllsopComponentModule } from '@allsop/angular-common';
import { MainComponent } from './layout/main/main.component';
import { HeaderComponent } from './layout/header/header.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AlertsService } from './services/alert.service';
import { ApiConfiguration } from './api/api-configuration';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UsersComponent } from './pages/users/users/users-list/users.component';
import { EditUsersComponent } from './pages/users/users/edit-users/edit-users.component';
import { PageTitleComponent } from './common/Components/page-title/page-title.component';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AuthService } from './auth/services/auth.service';
import { TokenInterceptor } from './auth/services/interceptor.service';
import { LoginComponent } from './auth/login/login.component';
import { HasRoleDirective } from './common/directives/hasRole.directive';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GlobalErrorHandler } from './services/lobal-error-handler';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './pages/services/loaderInterceptor';
import { LoaderComponent } from './common/Components/loader/loader.component';
import { ScrollbarModule } from 'ngx-scrollbar';
import { PowerbiReportComponent } from './pages/reports/powerbi-report/powerbi-report.component';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { HomeComponent } from './pages/home/home.component';
import { CreateUserComponent } from './pages/users/users/create-user/create-user.component';
import { UpdatePasswordComponent } from './pages/users/users/update-password/update-password.component';
import { ConfigSettings, APP_CONFIG } from './config';
import { DependentConfigSettings } from './dependent-config';
import { TableEditButtonComponent } from './common/Components/table-edit-button/table-edit-button.component';
import { TableDeleteButtonComponent } from './common/Components/table-delete-button/table-delete-button.component';
import { TableViewButtonComponent } from './common/Components/table-view-button/table-view-button.component';
import { PagingComponent } from './common/Components/paging/paging.component';
import { TicketAddButtonComponent } from './common/Components/ticket-add-button/ticket-add-button.component';
import { KeywordsComponent } from '../app/common/Components/keywords/keywords.component';
import { AssetsComponent } from './pages/assets/assets.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { LoadingModule } from './layout/loading/loading.module';
import { SideMenuComponent } from './layout/side-menu/side-menu.component';
import { FinancialComponent } from './pages/financial/financial.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { EstimateFiltersComponent } from '../app/common/filters/estimate-filters/estimate-filters.component';
import { EditCustomersComponent } from '../app/pages/customers/edit-customers/edit-customers.component';
import { PropertiesComponent } from './pages/properties/properties.component';
import { ViewPropertiesComponent } from '../app/pages/properties/view-properties/view-properties.component';
import { ViewJobsComponent } from '../app/pages/jobs/view-jobs/view-jobs.component';
import { PropertyInfoTabComponent } from './pages/properties/property-info-tab/property-info-tab.component';
import { CustomerFiltersComponent } from './pages/customers/customer-filters/customer-filters.component';
import { JobFiltersComponent } from './pages/jobs/job-filters/job-filters.component';
import { PropertiesFiltersComponent } from './pages/properties/properties-filters/properties-filters.component';
import { PropertyNotesTabComponent } from './pages/properties/property-notes-tab/property-notes-tab.component';
import { PropertyAssetsComponent } from './pages/properties/property-assets/property-assets.component';
import { PropertyJobsComponent } from './pages/properties/property-jobs/property-jobs.component';
import { PropertyDocumentationComponent } from './pages/properties/property-documentation/property-documentation.component';
import { PropertyFinancialInfoComponent } from './pages/properties/property-financial-info/property-financial-info.component';
import { FinanceFiltersComponent } from './pages/financial/finance-filters/finance-filters.component';
import { AssignAccountmanagerComponent } from './pages/properties/assign-accountmanager/assign-accountmanager.component';
import { AssetFiltersComponent } from './pages/assets/asset-filters/asset-filters.component';
import { HealthAndSafetyDocumentsComponent } from './pages/health-and-safety-documents/health-and-safety-documents.component';
import { HealthSafetyFiltersComponent } from './pages/health-and-safety-documents/health-safety-filters/health-safety-filters.component';
import { AssetDetailsComponent } from './pages/assets/asset-details/asset-details.component';
import { PropertyLocationComponent } from './pages/properties/property-location/property-location.component';
import { FinancialDetailsComponent } from './pages/financial/financial-details/financial-details.component';
import { PaidDebtorsComponent } from './pages/financial/paid-debtors/paid-debtors.component';
import { OutstandingTransactionsComponent } from './pages/financial/outstanding-transactions/outstanding-transactions.component';
import { TransactionDetailsComponent } from './pages/financial/financial-details/transactionDetails/transactionDetails.component';
import { SchedulePropertiesComponent } from './pages/schedule/schedule-properties/schedule-properties.component';
import { UnassignAccountmanagerComponent } from './pages/properties/unassign-accountmanager/unassign-accountmanager.component';
 import { GoogleMapsModule } from '@angular/google-maps';
import { DebtorStatementComponent } from './pages/debtor-statement/debtor-statement.component';
import { PpmDetailComponent } from './pages/reports/ppm-detail/ppm-detail.component';
import { PpmReportComponent } from './pages/reports/ppm-report/ppm-report.component';
import { EstimatesComponent } from './pages/estimates/estimates.component';
import { EstimatesModalComponent } from './pages/estimates/estimates-modal/estimates-modal.component';
import { EstFilterComponent } from './pages/estimates/est-filter/est-filter.component';
import { QuotesComponent } from './pages/quotes/quotes.component';
import { PropertyHealthAndSafetyComponent } from './pages/properties/property-health-and-safety/property-health-and-safety.component';
import { QuoteFiltersComponent } from './pages/quotes/quote-filters/quote-filters.component';
import { QuoteModalComponent } from './pages/quotes/quote-modal/quote-modal.component';
import { ReactiveFiltersComponent } from './pages/reactive-jobs/reactive-filters/reactive-filters.component';
import { ReactiveJobsComponent } from './pages/reactive-jobs/reactive-jobs.component';
import { SubcontractorDocumentsComponent } from './pages/subcontractor-documents/subcontractor-documents.component';
import { SubcontractorFiltersComponent } from './pages/subcontractor-documents/subcontractor-filters/subcontractor-filters.component';
import { DebtorSummaryComponent } from './pages/properties/debtor-summary/debtor-summary.component';
import { DeleteModalComponent } from './pages/users/users/delete-modal/delete-modal.component';
import { PropertyFiltersComponent } from './common/filters/property-filters/property-filters.component';
import { PpmJobsComponent } from './pages/ppm-jobs/ppm-jobs.component';
import { PpmJobFiltersComponent } from './pages/ppm-jobs/ppm-job-filters/ppm-job-filters.component';
import { PaginatedReportComponent } from './pages/reports/paginated-report/paginated-report.component';
import { PaidInvoicesComponent } from './pages/paid-invoices/paid-invoices.component';
import { OutstandingInvoicesComponent } from './pages/outstanding-invoices/outstanding-invoices.component';
import { PaidInvoicesFiltersComponent } from './pages/paid-invoices/paid-invoices-filters/paid-invoices-filters.component';
import { UserFiltersComponent } from './pages/users/users/user-filters/user-filters.component';
import { LoginModalComponent } from './auth/login/login-modal/login-modal.component';
import { PropertyTabsComponent } from './pages/properties/property-tabs/property-tabs.component';
import { EstimateDetailsComponent } from './pages/estimates/estimate-details/estimate-details.component';
import { CustomReportsComponent } from './pages/reports/report-group/custom-reports/custom-reports.component';

export function initApiConfiguration(
  config: ApiConfiguration,
  http: HttpClient
): (Function) {
  return async () => {
    const AppSettings: any = await http.get('/app-settings.json').toPromise();
    config.rootUrl = AppSettings.api_url;
  };
}
export const INIT_API_CONFIGURATION: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initApiConfiguration,
  deps: [ApiConfiguration, HttpClient],
  multi: true
};

library.add(fas);
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    LoginComponent,
    UsersComponent,
    EditUsersComponent,
    PageTitleComponent,
    HasRoleDirective,
    LoaderComponent,
    CustomReportsComponent,
    PowerbiReportComponent,
    HomeComponent,
    CreateUserComponent,
    UpdatePasswordComponent,
    TableEditButtonComponent,
    TableViewButtonComponent,
    TableDeleteButtonComponent,
    PagingComponent,
    TicketAddButtonComponent,
    SideMenuComponent,
    KeywordsComponent,
    AssetsComponent,
    JobsComponent,
    ScheduleComponent,
    FinancialComponent,
    CustomersComponent,
    CustomerFiltersComponent,
    JobFiltersComponent,
    EditCustomersComponent,
    PropertiesComponent,
    PropertiesFiltersComponent,
    EstimateFiltersComponent,
    ViewPropertiesComponent,
    ViewJobsComponent,
    PropertyInfoTabComponent,
    PropertyNotesTabComponent,
    PropertyAssetsComponent,
    PropertyJobsComponent,
    PropertyDocumentationComponent,
    PropertyFinancialInfoComponent,
    PropertyHealthAndSafetyComponent,
    FinanceFiltersComponent,
    AssetFiltersComponent,
    AssignAccountmanagerComponent,
    HealthAndSafetyDocumentsComponent,
    HealthSafetyFiltersComponent,
    AssetDetailsComponent,
    PropertyLocationComponent,
    FinancialDetailsComponent,
    OutstandingTransactionsComponent,
    PaidDebtorsComponent,
    TransactionDetailsComponent,
    SchedulePropertiesComponent,
    UnassignAccountmanagerComponent,
    DebtorStatementComponent,
    PpmDetailComponent,
    PpmReportComponent,
    EstimateFiltersComponent,
    EstimatesComponent,
    EstimatesModalComponent,
    EstFilterComponent,
    QuotesComponent,
    QuoteFiltersComponent,
    QuoteModalComponent,
    QuoteFiltersComponent,
    ReactiveFiltersComponent,
    ReactiveJobsComponent,
    SubcontractorDocumentsComponent,
    SubcontractorFiltersComponent,
    DebtorSummaryComponent,
    DeleteModalComponent,
    PropertyFiltersComponent,
    PpmJobsComponent,
    PpmJobFiltersComponent,
    PaginatedReportComponent,
    PaidInvoicesComponent,
    OutstandingInvoicesComponent,
    PaidInvoicesFiltersComponent,
    UserFiltersComponent,
    LoginModalComponent,
    PropertyTabsComponent,
    EstimateDetailsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgxSmartModalModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    TabsModule.forRoot(),
    AppRoutingModule,
    ModalModule.forRoot(),
    FontAwesomeModule,
    AngularCommonModule,
    AllsopComponentModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgbModule,
    AppRoutingModule,
    NgxSpinnerModule,
    TypeaheadModule.forRoot(),
    AngularFileUploaderModule,
    ScrollbarModule,
    NgxPowerBiModule,
    LoadingModule,
    GoogleMapsModule
  ],
  providers: [
    AuthService,
    AlertsService,
    NgxSmartModalService,
    LoaderService,
    ConfigSettings,
    {
      provide: DependentConfigSettings,
      useFactory: CreateDepenentSettings,
      deps: [APP_CONFIG],
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    INIT_API_CONFIGURATION,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: ApplicationInsights,
      useFactory: appInsightsFactory
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  entryComponents: [AssignAccountmanagerComponent, UnassignAccountmanagerComponent, EstimatesModalComponent],
})
export class AppModule { }

export function appInsightsFactory(): ApplicationInsights {
  return new ApplicationInsights({
    config: {
      instrumentationKey: ''
    }
  });
}
export function CreateDepenentSettings(settings: ConfigSettings): DependentConfigSettings {
  return {
    dependentValue1: ''
  };
}
