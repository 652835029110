import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { UserFilters } from './models/userFilters';

export class UserFilterForm extends AllsopBaseForm {
    constructor() {
        super();

        this.setUpForm();
    }
    public setUpForm() {
        const model: UserFilters = {
            keywords: '',
        };

        this.setupForm(model);
    }

    public setupForm(value: UserFilters) {
        this.resetForm();
        this.formGroup = this.fb.group<UserFilters>({
            keywords: this.getControl(value.keywords, false),
        },
        );
    }
}


