import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, tap, filter, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LocalSettingsService } from '../../services/local-settings.service';
import { OAuthModelOut } from '../../api/models/oauth-model-out';
import { MonitoringService } from '../../services/monitoring.service';
import { NavigationService } from '../../services/navigation.service';
import { LoaderService } from '../../services/loader.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    public auth: AuthService,
    private monitoringService: MonitoringService,
    private localSettingsService: LocalSettingsService,
    private navigateTo: NavigationService,
    private loaderService: LoaderService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    if (this.auth.accessToken) {
      request = this.addToken(request, this.auth.accessToken);
    }

    return next.handle(request).pipe(catchError(error => {
      if (error.url.includes('/refreshToken')) {
        this.auth.logout();
        this.navigateTo.reDirectionLogin(this.router.url);
      }

      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next, error);
      }

      const errorMessage = this.logError(error);
      return throwError(errorMessage);
    }));
  }

  private logError(error): string {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {

      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      if (error.error.Message) {
        errorMessage = `${error.error.Message}`;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
    }
    this.monitoringService.logHttpException(errorMessage, error);

    return errorMessage;
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      if (this.localSettingsService.RefreshToken) {
          return this.auth.renewToken(this.localSettingsService.RefreshToken).pipe(
            switchMap((token: OAuthModelOut) => {
            this.localSettingsService.setTokens(token.accessToken, token.refreshToken);
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token.accessToken);
            return next.handle(this.addToken(request, token.accessToken));
          }));
      } else {
          this.isRefreshing = false;
          this.auth.logout();
          this.navigateTo.reDirectionLogin(this.router.url);
          this.loaderService.hide();
          const errorMessage = this.logError(error);
          return throwError(errorMessage);
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
