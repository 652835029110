import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from '../../../services/navigation.service';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../auth/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { DebtorsTransactionModelOut } from '../../../api/models/debtors-transaction-model-out';
import { DebtorsTransactionService } from '../../../api/services/debtors-transaction.service';
import { PropertyService } from '../../../api/services/property.service';
import { PropertyViewServiceService } from '../../properties/services/property-view-service.service';

@Component({
  selector: 'app-outstanding-transactions',
  templateUrl: './outstanding-transactions.component.html',
  styleUrls: ['./outstanding-transactions.component.scss']
})
export class OutstandingTransactionsComponent implements OnInit {
  adminAccess = false;
  faDownload = faDownload;
  OutstandingInvoiceAttachment = [];
  selectedOutstandingInvoice = [];
  outstandingInvoices;
  property;
  propertyId = 0;
  debtorId = 0;
  debtorRef = '';
  propertyRef = '';
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  name = '';
  reference = '';
  allChecked = false;
  debtorTransactions: DebtorsTransactionModelOut;
  constructor(
    private debtorService: DebtorsTransactionService,
    private propertyService: PropertyService,
    private propertyViewService: PropertyViewServiceService,
    private navigateTo: NavigationService,
    private toastr: ToastrService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
  }
  ngOnInit(): void {
    this.loadData2();
    this.listenForRouteParams();
  }

  listenForRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        if (this.propertyId = params.id) {
          this.loadProperty(params.id);
        } else {
          this.debtorId = params.id;
          this.loadFinancialDetails(params.id);
        }
      }
    });
  }

  loadFinancialDetails(financialId: number) {
    const params: DebtorsTransactionService.GetDebtorsTransactionByIdAsyncParams = {
      Authorization: '',
      debtorsTransactionId: financialId,
    };
    this.debtorService.GetDebtorsTransactionByIdAsync(params).subscribe(response => {
      this.debtorTransactions = response;
      this.debtorRef = response.debtorRef;
      this.propertyRef = response.propertyRef;
      this.loadData(this.debtorRef, this.propertyRef);
    });
  }

  loadProperty(propertyId: number) {
    const params: PropertyService.GetPropertyByIdAsyncParams = {
      Authorization: '',
      propertyId: propertyId,
    };
    this.propertyService.GetPropertyByIdAsync(params).subscribe(response => {
      this.property = response;
      this.propertyViewService.propertySelected(response);
    });
  }


  loadData(debtorRef, propertyRef) {
    // const params: DebtorsTransactionService.GetOutstandingInvoicesPagedAsyncParams = {
    //   Authorization: '',
    //   pageNo: this.currentPage,
    //   pageSize: this.pageSize,
    //   debtorRef: debtorRef,
    //   propertyRef: propertyRef
    // };
    // this.debtorService.GetDebtorsTransactionsPagedAsync(params).subscribe(response => {
    //   this.totalRecordCount = response.totalRecordCount;
    //   this.outstandingInvoices = response.items;
    // });
  }

  loadData2() {
    // const params: DebtorsTransactionService.GetOutstandingInvoicesPagedAsyncParams = {
    //   Authorization: '',
    //   pageNo: this.currentPage,
    //   pageSize: this.pageSize,
    // };
    // this.debtorService.GetDebtorsTransactionsPagedAsync(params).subscribe(response => {
    //   this.totalRecordCount = response.totalRecordCount;
    //   this.outstandingInvoices = response.items;
    // });
  }

  pageChanged(page) {
    this.currentPage = page;
    if (this.debtorRef) {
      this.loadData(this.debtorRef, this.propertyRef);
    }
  }

  selectAll() { // REQUIRED IF WE IMPLEMENT MULTI DOWNLOAD -- NEEDS REVIEWED
    // for (let i = 0; i < this.OutstandingInvoiceAttachment.length; i++) {
    //   if (!this.OutstandingInvoiceAttachment[i].selected) {
    //     this.OutstandingInvoiceAttachment[i].selected = true;
    //     this.selectedOutstandingInvoice.push(this.OutstandingInvoiceAttachment[i]);
    //   } else {
    //     this.OutstandingInvoiceAttachment[i].selected = false;
    //     const index = this.selectedOutstandingInvoice.indexOf(this.OutstandingInvoiceAttachment[i]);
    //     this.selectedOutstandingInvoice.splice(index, 1);
    //   }
    // }
  }

  isChecked(attachment, event) {
    const index = this.selectedOutstandingInvoice.indexOf(attachment);
    if (index === -1) {
      if (event.target.checked) {
        this.selectedOutstandingInvoice.push(attachment);
      }
    } else {
      this.selectedOutstandingInvoice.splice(index, 1);
    }
  }

  populatePDFData() {
    this.selectedOutstandingInvoice.forEach(function (doc) {
      if (doc.selected) {
        this.generatePDFDetails(this.selectedOutstandingInvoice);
      }
    });
    this.selectedOutstandingInvoice = [];
    this.allChecked = false;
    for (let i = 0; i < this.OutstandingInvoiceAttachment.length; i++) {
      this.OutstandingInvoiceAttachment[i].selected = false;
    }
    this.toastr.success('Outstanding Invoice Generated for' + this.debtorRef);
  }

  // generatePDFDetails(FinancialPDFDetails: Array<DebtorsTransactionModelIn>) {  // PDF File Saver Component - *AWAITING API*
  //   const debtorTransactionsPDFs: DebtorsTransactionService.GeneratePDFParams = {
  //     Authorization: '',
  //     models: FinancialPDFDetails
  //   };
  //   this.debtorService.GeneratePDF(debtorTransactionsPDFs).subscribe(response => {
  //     const blob: any = new Blob([response], {type: 'text/plain; charset=utf-8'});
  //     const fileName = 'DebtorSummary' + '.pdf';
  //     FileSaver.saveAs(blob, fileName);
  //     this.toastr.success('PDF Information Download Successful');
  //     this.loadDebtorsTransations();
  //   });
  // }
}
