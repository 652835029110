import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as pbi from 'powerbi-client';
import { NgxPowerBiService } from 'ngx-powerbi';
import { PowerBiService } from '../../api/services/power-bi.service';
import { ReportModelOut } from '../../api/models/report-model-out';
import { PropertyFilterService } from '../../services/property-filter-service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  @ViewChild('embed') embed: ElementRef;
  private pbiContainerElement: HTMLElement;
  selectedReport = 0;
  projectRef = '';
  reports: Array<ReportModelOut>;
  embedConfig = {
    type: 'report',
    id: '',
    pageName: 'ReportSection',  // PPM Schedule Page on PowerBI
    embedUrl: '',
    tokenType: pbi.models.TokenType.Embed,
    accessToken: '',
    filters: [],
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      layoutType: pbi.models.LayoutType.Custom
    }
  };
  report: any;
  constructor(
    private ngxPowerBiService: NgxPowerBiService,
    private powerBiService: PowerBiService,
    private propertyFilterService: PropertyFilterService,
  ) {
  }
  ngOnInit() {
    this.projectRef = this.propertyFilterService.getPropertyRef;
    this.pbiContainerElement = <HTMLElement>(document.getElementById('pbi-container'));
    this.loadUserReports();
  }

  loadUserReports() {
    this.powerBiService.GetUserReportsAsync('').subscribe((response: Array<ReportModelOut>) => {
      this.reports = response;
      this.loadReport(this.reports.filter((r) =>  r.name.indexOf('PPM Report') >= 0)[0].reportId);
    });
  }

  loadReport(reportId: string) {
    const params: PowerBiService.GetReportByIdAsyncParams = {
      Authorization: '',
      reportId: reportId
    };
    this.powerBiService.GetReportByIdAsync(params).subscribe((response) => {
      this.embedConfig.accessToken = response.embedToken;
      this.embedConfig.id = response.reportId;
      this.embedConfig.embedUrl = response.embedUrl;

      if (this.projectRef != null || this.projectRef !== '') {
        const filter = {
          $schema: 'http://powerbi.com/product/schema#basic',
          target: {
            table: 'Properties',
            column: 'ProjectRef'
          },
          operator: 'In',
          values: [this.projectRef],
          filterType: pbi.models.FilterType.Basic
        };
        this.embedConfig.filters = [filter];
      }
      this.report = this.ngxPowerBiService.embed(this.pbiContainerElement, this.embedConfig);
    });
  }
}
