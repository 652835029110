import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SubContractorFilters } from '../models/subDocsFilters';
import { faDownload, faFile } from '@fortawesome/free-solid-svg-icons';
import { PropertyService } from '../../../api/services/property.service';
import { CustomerService } from '../../../api/services/customer.service';
import { SubContractorService } from '../../../api/services/sub-contractor.service';
import { SubContractorFilterForm } from '../subContractorFilterForm';
import { Observable, of, Observer, noop } from 'rxjs';
import { CustomerNamesPagedModelOut } from '../../../api/models/customer-names-paged-model-out';
import { PropertyNamesPagedModelOut } from '../../../api/models/property-names-paged-model-out';
import { switchMap, map, tap } from 'rxjs/operators';
import { AlertsService } from '../../../services/alert.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Component({
  selector: 'app-subcontractor-filters',
  templateUrl: './subcontractor-filters.component.html',
  styleUrls: ['./subcontractor-filters.component.scss']
})
export class SubcontractorFiltersComponent implements OnInit {
  @Output() search: EventEmitter<SubContractorFilters> = new EventEmitter();
  filters: SubContractorFilters = {
    documentName: '',
    subContractorName: '',
    clientName: '',
    propertyName: '',
    expiryDate: null
  };
  filterForm: SubContractorFilterForm;
  currentPage = 1;
  pageSize = 20;
  propertiesList = [];
  supplierList = [];
  faDownload = faDownload;
  faFile = faFile;
  propSuggestions$: Observable<PropertyNamesPagedModelOut[]>;
  errorMessage: string;
  constructor(
    private propertyService: PropertyService,
    private customerService: CustomerService,
    private subContractorService: SubContractorService,
    private alertsService: AlertsService
  ) {
    this.filterForm = new SubContractorFilterForm();
  }

  ngOnInit() {

    this.propSuggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.filterForm.form.value.propertyName);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          const params: PropertyService.GetPropertyNamesPagedAsyncParams = {
            Authorization: '',
            pageNo: this.currentPage,
            pageSize: this.pageSize,
            keywords: this.filterForm.form.value.propertyName

          };

          return this.propertyService.GetPropertyNamesPagedAsync(params)
            .pipe(
              map((data: PropertyNamesPagedModelOut) => data.items.map(
                (s) => ({ projectDescription: s.projectDescription + ' (' + s.projectCode + ')', projectRef: s.projectCode })) || []),
              tap(() => noop, err => {
                this.errorMessage = err && err.message || 'Unable to find matching property';
              })
            );
        }

        this.alertsService.error('Unable to find matching property');

        return of([]);
      })
    );

  }

  onSearchClick() {
    this.search.emit(this.filters);
  }

  resetFilters() {
    this.filters = {
      documentName: '',
      subContractorName: '',
      clientName: '',
      propertyName: '',
      expiryDate: null
    };
    this.filterForm.form.patchValue(this.filters);
    this.search.emit(this.filters);
  }

  onSelectProperty(event: TypeaheadMatch): void {
    this.filterForm.form.value.properties = event.item.propertyRef;
    this.filters.propertyName = event.item.siteName;
  }
  onBackspaceProp(event) {
    const key = (window.Event) ? event.which : event.keyCode;
    if (key === 8) {
      this.filters.propertyName = '';
    }
  }
  onBackspaceCustomer(event) {
    const key = (window.Event) ? event.which : event.keyCode;
    if (key === 8) {
      this.filters.clientName = '';
    }
  }

  setDate(date) {
    this.filters.expiryDate = date;
  }


}
