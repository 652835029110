import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent  {
  // center: google.maps.LatLngLiteral;
  // markers: any[] = [];
  // resources = {
  //   baseUrl: 'https://runbuilder.blob.core.windows.net/resources/',
  //   redMarker: 'map-marker-red.png',
  //   orangeMarker: 'map-marker-orange.png',
  //   blueMarker: 'map-marker-blue.png',
  //   purpleMarker: 'map-marker-purple.png',
  //   selectedMarker: 'map-marker-selected.png',
  //   completedMarker: 'map-marker-green.png'
  // };
  // constructor(private mapService: MapserviceService) { }

  // ngOnInit() {
  //   this.addMarkers();
  // }

  // addMarkers() {
  //   const icon =
  //   this.center = {
  //     lat: 54.5647361,
  //     lng: -5.8980014999999995
  //   };

  //   for (let index = 0; index < 10; index++) {
  //     this.markers.push({
  //       position: {
  //         lat: this.center.lat + ((Math.random() - 0.5) * 2) / 10,
  //         lng: this.center.lng + ((Math.random() - 0.5) * 2) / 10,
  //       },
  //       label: {
  //         color: 'red',
  //         text: 'Marker label',
  //       },
  //       title: 'Marker title',
  //       jobId: index,
  //       options: {
  //         icon: {
  //           url: this.resources.baseUrl + this.resources.selectedMarker,
  //           scaledSize: new google.maps.Size(40, 40), // scaled size
  //           origin: new google.maps.Point(0,0) , // origin
  //           anchor: new google.maps.Point(0, 0) // anchor
  //         }
  //       },
  //       // infowindow: infowindow
  //     });
  //  }

  //   this.mapService.addMarkers(this.markers);
  // }
}
