import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetFilters } from '../models/asset-filters';
import { AssetFiltersForm } from '../asset-filters/assetFiltersForm';
import { PropertyService } from '../../../api/services/property.service';
import { AssetGroupService } from '../../../api/services/asset-group.service';
import { PropertyNamesPagedModelOut } from '../../../api/models/property-names-paged-model-out';
import { Observable, Observer, noop, of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { AlertsService } from '../../../services/alert.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Component({
  selector: 'app-asset-filters',
  templateUrl: './asset-filters.component.html',
  styleUrls: ['./asset-filters.component.scss']
})
export class AssetFiltersComponent implements OnInit {
  @Input() showPropertyRefFilter: boolean;
  @Output() search: EventEmitter<AssetFilters> = new EventEmitter();
  filters: AssetFilters = {
    keywords: '',
    projectRef: '',
    completedDate: null,
    assetCategory: '',
  };
  filterForm: AssetFiltersForm;
  currentPage = 1;
  pageSize = 20;
  propertiesList = [];
  assetGroupsList = [];
  suggestions$: Observable<PropertyNamesPagedModelOut[]>;
  errorMessage: string;

  constructor(
    private propertyService: PropertyService,
    private assetGroupService: AssetGroupService,
    private alertsService: AlertsService
  ) {
    this.filterForm = new AssetFiltersForm();
  }

  ngOnInit() {
    this.loadAssetCategory();

    this.suggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.filterForm.form.value.propertyRef);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          const params: PropertyService.GetPropertyNamesPagedAsyncParams = {
            Authorization: '',
            pageNo: this.currentPage,
            pageSize: this.pageSize,
            keywords: this.filterForm.form.value.propertyRef

          };

          return this.propertyService.GetPropertyNamesPagedAsync(params)
            .pipe(
              map((data: PropertyNamesPagedModelOut) => data.items.map(
                (s) => ({ projectDescription: s.projectDescription + ' (' + s.projectCode + ')', projectRef: s.projectCode })) || []),
              tap(() => noop, err => {
                this.errorMessage = err && err.message || 'Unable to find matching property';
              })
            );
        }

        this.alertsService.error('Unable to find matching property');

        return of([]);
      })
    );
  }

  onSearchClick() {
    this.search.emit(this.filters);
  }

  resetFilters() {
    this.filters = {
      keywords: '',
      projectRef: '',
      completedDate: null,
      assetCategory: '',
    };
    this.filterForm.form.patchValue(this.filters);
    this.search.emit(this.filters);
  }

  onSelectProperty(event: TypeaheadMatch): void {
    this.filterForm.form.value.properties = event.item.propertyRef;
    this.filters.projectRef = event.item.projectRef;
  }
  onBackspaceProp(event) {
    const key = (window.Event) ? event.which : event.keyCode;
    if (key === 8) {
      this.filters.projectRef = '';
    }
  }

  loadAssetCategory() {
    this.assetGroupService.GetAssetGroupsAsync('').subscribe(response => {
      this.assetGroupsList = response;
    });
  }

  setCompletedDate(date) {
    this.filters.completedDate = date;
  }

}
