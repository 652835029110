import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { isEmpty } from 'lodash';
import { AccountService } from '../../api/services/account.service';
import { LoginModelIn } from '../../api/models/login-model-in';
import * as JwtDecode from 'jwt-decode';
import { LocalSettingsService } from '../../services/local-settings.service';
import { OAuthModelOut } from '../../api/models/oauth-model-out';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _accessToken: any = false;
  private _refreshToken: any = false;
  jwtHelper = new JwtHelperService();
  private authed = new BehaviorSubject<boolean>(false);
  private _profile: any = false;
  decodedToken: any;
  constructor(
    private api: AccountService,
    private localSettingsService: LocalSettingsService
  ) {
    this._accessToken = this.localSettingsService.AccessToken;
    this._refreshToken = this.localSettingsService.RefreshToken;

    const isLoggedIn = this.bothTokensExist();
    this.authed.next(isLoggedIn);
  }
  private bothTokensExist(): boolean {
    return (!isEmpty(this._accessToken) && !isEmpty(this._refreshToken));
  }
  async logout() {
    this.localSettingsService.clearTokens();
    this.authed.next(false);
    this._accessToken = '';
    this._refreshToken = '';
  }
  public get userId() {
    this._decodeProfile();
    return this._profile.UserId;
  }
  public get accessToken() {
    return this._accessToken;
  }
  public set accessToken(val) {
    this.localSettingsService.AccessToken = val;
    this._accessToken = val;
  }
  public get refreshToken() {
    return this._refreshToken;
  }
  public set refreshToken(val) {
    this.localSettingsService.RefreshToken = val;
    this._refreshToken = val;
  }

  public renewToken(refreshToken: string): Observable<OAuthModelOut> {
    return this.api.RefreshTokenAsync({
      refreshToken
    });
  }

  public login(loginModelIn: LoginModelIn) {
    return new Promise((resolve, reject) => {
      this.api.AdminLoginAsync(loginModelIn).subscribe((data: any) => {
        this.accessToken = data.accessToken;
        this.refreshToken = data.refreshToken;
        this.decodedToken = this.jwtHelper.decodeToken(this.accessToken);
        this.authed.next(true);
        resolve();
      }, reject);
    });
  }

  public get isAuthenticated() {
    return this.authed.value;
  }

  public loggedin(): Observable<boolean> {
    return this.authed.asObservable();
  }

  public checkAdminRole() {
    this._decodeProfile();
    let myRole = this._profile['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] || false;
    if (!Array.isArray(myRole)) {
      myRole = [myRole];
    }
    return myRole.indexOf('Admin') > -1;
  }
  get role(): string {
    return this.getDecodedTokeon.Role;
  }
  get fullName(): string {
    return this.getDecodedTokeon.FirstName + ' '  + this.getDecodedTokeon.LastName;
  }
  get getDecodedTokeon() {
    if (!this.decodedToken) {
      return this.jwtHelper.decodeToken(this.accessToken);
    }
    return this.decodedToken;
  }
  roleMatch(allowedRoles): boolean {
    let isMatch = false;

    const userRole = this.getDecodedTokeon.Role;
    allowedRoles.forEach(role => {
      if (userRole === role) {
        isMatch = true;
        return;
      }
    });

    return isMatch;
  }
  get UserProfile() {
    return this._decodeProfile();
  }
  private _decodeProfile() {
    if (this._accessToken !== '') {
      try {
        this._profile = JwtDecode(this._accessToken);
      } catch (error) {
        this._profile = {};
      }
    } else {
      this._profile = {};
    }
    return this._profile;
  }
}
