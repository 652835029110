import { AllsopBaseForm } from '../../common/allsopBaseForm';
import { SubContractorFilters } from './models/subDocsFilters';

export class SubContractorFilterForm extends AllsopBaseForm {
    constructor() {
        super();

        this.setUpForm();
    }
    public setUpForm() {
        const model: SubContractorFilters = {
            documentName: '',
            subContractorName: '',
            clientName: '',
            propertyName: '',
            expiryDate: null
        };

        this.setupForm(model);
    }

    public setupForm(value: SubContractorFilters) {
        this.resetForm();
        this.formGroup = this.fb.group<SubContractorFilters>({
            documentName: this.getControl(value.documentName, false),
            subContractorName: this.getControl(value.subContractorName, false),
            clientName: this.getControl(value.clientName, false),
            propertyName: this.getControl(value.propertyName, false),
            expiryDate: this.getControl(value.expiryDate, false)
        },
        );
    }

    resetForm() {
        this.isSubmitted = false;
        const model: SubContractorFilters = {
            documentName: '',
            subContractorName: '',
            clientName: '',
            propertyName: '',
            expiryDate: null
        };
    }
}


