/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PropertiesPagedModelOut } from '../models/properties-paged-model-out';
import { PropertyNamesPagedModelOut } from '../models/property-names-paged-model-out';
import { PropertyModelOut } from '../models/property-model-out';
import { PropertyDocumentsPagedModelOut } from '../models/property-documents-paged-model-out';
import { PropertyNoteModelOut } from '../models/property-note-model-out';
import { PropertyNoteModelIn } from '../models/property-note-model-in';
import { IActionResult } from '../models/iaction-result';
import { UpdatePropertyAccountManagerModelIn } from '../models/update-property-account-manager-model-in';
import { UpdatePropertyAccountManagersModelIn } from '../models/update-property-account-managers-model-in';
@Injectable({
  providedIn: 'root',
})
class PropertyService extends __BaseService {
  static readonly GetPropertiesPagedAsyncPath = '/api/property/paged';
  static readonly GetPropertyNamesPagedAsyncPath = '/api/property/propertName';
  static readonly GetPropertyByIdAsyncPath = '/api/property/{propertyId}';
  static readonly GetPropertyByProjectRefAsyncPath = '/api/property/{projectRef}';
  static readonly AddDocumentToPropertyPath = '/api/property/fileUpload/{propertyId}';
  static readonly GetPropertyDocumentsPagedAsyncPath = '/api/property/{projectRef}/documents';
  static readonly GetPropertyNotesAsyncPath = '/api/property/{propertyId}/notes';
  static readonly CreatePropertyNoteAsyncPath = '/api/property/note';
  static readonly UpdateAccountManagerPath = '/api/property/{propertyId}/accountManger';
  static readonly UpdateAccountManagersPath = '/api/property/accountMangers/{userId}';
  static readonly UnassignAccountManagersPath = '/api/property/accountMangers/unassign';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Properties Paged
   * @param params The `PropertyService.GetPropertiesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * @return Success
   */
  GetPropertiesPagedAsyncResponse(params: PropertyService.GetPropertiesPagedAsyncParams): __Observable<__StrictHttpResponse<PropertiesPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.propertyName != null) __params = __params.set('propertyName', params.propertyName.toString());
    if (params.projectRef != null) __params = __params.set('projectRef', params.projectRef.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/property/paged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PropertiesPagedModelOut>;
      })
    );
  }
  /**
   * Get Properties Paged
   * @param params The `PropertyService.GetPropertiesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * @return Success
   */
  GetPropertiesPagedAsync(params: PropertyService.GetPropertiesPagedAsyncParams): __Observable<PropertiesPagedModelOut> {
    return this.GetPropertiesPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as PropertiesPagedModelOut)
    );
  }

  /**
   * @param params The `PropertyService.GetPropertyNamesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * @return Success
   */
  GetPropertyNamesPagedAsyncResponse(params: PropertyService.GetPropertyNamesPagedAsyncParams): __Observable<__StrictHttpResponse<PropertyNamesPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.propertyName != null) __params = __params.set('propertyName', params.propertyName.toString());
    if (params.projectRef != null) __params = __params.set('projectRef', params.projectRef.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/property/propertName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PropertyNamesPagedModelOut>;
      })
    );
  }
  /**
   * @param params The `PropertyService.GetPropertyNamesPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * @return Success
   */
  GetPropertyNamesPagedAsync(params: PropertyService.GetPropertyNamesPagedAsyncParams): __Observable<PropertyNamesPagedModelOut> {
    return this.GetPropertyNamesPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as PropertyNamesPagedModelOut)
    );
  }

  /**
   * Get Property by Id
   * @param params The `PropertyService.GetPropertyByIdAsyncParams` containing the following parameters:
   *
   * - `propertyId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetPropertyByIdAsyncResponse(params: PropertyService.GetPropertyByIdAsyncParams): __Observable<__StrictHttpResponse<PropertyModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/property/${encodeURIComponent(params.propertyId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PropertyModelOut>;
      })
    );
  }
  /**
   * Get Property by Id
   * @param params The `PropertyService.GetPropertyByIdAsyncParams` containing the following parameters:
   *
   * - `propertyId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetPropertyByIdAsync(params: PropertyService.GetPropertyByIdAsyncParams): __Observable<PropertyModelOut> {
    return this.GetPropertyByIdAsyncResponse(params).pipe(
      __map(_r => _r.body as PropertyModelOut)
    );
  }

  /**
   * Get Property by ProjectRef
   * @param params The `PropertyService.GetPropertyByProjectRefAsyncParams` containing the following parameters:
   *
   * - `projectRef`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetPropertyByProjectRefAsyncResponse(params: PropertyService.GetPropertyByProjectRefAsyncParams): __Observable<__StrictHttpResponse<PropertyModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/property/${encodeURIComponent(params.projectRef)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PropertyModelOut>;
      })
    );
  }
  /**
   * Get Property by ProjectRef
   * @param params The `PropertyService.GetPropertyByProjectRefAsyncParams` containing the following parameters:
   *
   * - `projectRef`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetPropertyByProjectRefAsync(params: PropertyService.GetPropertyByProjectRefAsyncParams): __Observable<PropertyModelOut> {
    return this.GetPropertyByProjectRefAsyncResponse(params).pipe(
      __map(_r => _r.body as PropertyModelOut)
    );
  }

  /**
   * @param params The `PropertyService.AddDocumentToPropertyParams` containing the following parameters:
   *
   * - `propertyId`:
   *
   * - `Authorization`: Access Token
   *
   * - `supplier`:
   *
   * - `file0`:
   *
   * - `expiryDate`:
   *
   * - `description`:
   *
   * @return Success
   */
  AddDocumentToPropertyResponse(params: PropertyService.AddDocumentToPropertyParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.supplier != null) { __formData.append('supplier', params.supplier as string | Blob);}
    if (params.file0 != null) { __formData.append('file0', params.file0 as string | Blob);}
    if (params.expiryDate != null) { __formData.append('expiryDate', params.expiryDate as string | Blob);}
    if (params.description != null) { __formData.append('description', params.description as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/property/fileUpload/${encodeURIComponent(params.propertyId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `PropertyService.AddDocumentToPropertyParams` containing the following parameters:
   *
   * - `propertyId`:
   *
   * - `Authorization`: Access Token
   *
   * - `supplier`:
   *
   * - `file0`:
   *
   * - `expiryDate`:
   *
   * - `description`:
   *
   * @return Success
   */
  AddDocumentToProperty(params: PropertyService.AddDocumentToPropertyParams): __Observable<string> {
    return this.AddDocumentToPropertyResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Get Property Documents Paged by ProjectRef
   * @param params The `PropertyService.GetPropertyDocumentsPagedAsyncParams` containing the following parameters:
   *
   * - `projectRef`:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetPropertyDocumentsPagedAsyncResponse(params: PropertyService.GetPropertyDocumentsPagedAsyncParams): __Observable<__StrictHttpResponse<PropertyDocumentsPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/property/${encodeURIComponent(params.projectRef)}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PropertyDocumentsPagedModelOut>;
      })
    );
  }
  /**
   * Get Property Documents Paged by ProjectRef
   * @param params The `PropertyService.GetPropertyDocumentsPagedAsyncParams` containing the following parameters:
   *
   * - `projectRef`:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetPropertyDocumentsPagedAsync(params: PropertyService.GetPropertyDocumentsPagedAsyncParams): __Observable<PropertyDocumentsPagedModelOut> {
    return this.GetPropertyDocumentsPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as PropertyDocumentsPagedModelOut)
    );
  }

  /**
   * Get Property Notes by Property Id
   * @param params The `PropertyService.GetPropertyNotesAsyncParams` containing the following parameters:
   *
   * - `propertyId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetPropertyNotesAsyncResponse(params: PropertyService.GetPropertyNotesAsyncParams): __Observable<__StrictHttpResponse<Array<PropertyNoteModelOut>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/property/${encodeURIComponent(params.propertyId)}/notes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PropertyNoteModelOut>>;
      })
    );
  }
  /**
   * Get Property Notes by Property Id
   * @param params The `PropertyService.GetPropertyNotesAsyncParams` containing the following parameters:
   *
   * - `propertyId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetPropertyNotesAsync(params: PropertyService.GetPropertyNotesAsyncParams): __Observable<Array<PropertyNoteModelOut>> {
    return this.GetPropertyNotesAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<PropertyNoteModelOut>)
    );
  }

  /**
   * Post Property Note
   * @param params The `PropertyService.CreatePropertyNoteAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  CreatePropertyNoteAsyncResponse(params: PropertyService.CreatePropertyNoteAsyncParams): __Observable<__StrictHttpResponse<PropertyNoteModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.modelIn;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/property/note`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PropertyNoteModelOut>;
      })
    );
  }
  /**
   * Post Property Note
   * @param params The `PropertyService.CreatePropertyNoteAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  CreatePropertyNoteAsync(params: PropertyService.CreatePropertyNoteAsyncParams): __Observable<PropertyNoteModelOut> {
    return this.CreatePropertyNoteAsyncResponse(params).pipe(
      __map(_r => _r.body as PropertyNoteModelOut)
    );
  }

  /**
   * @param params The `PropertyService.UpdateAccountManagerParams` containing the following parameters:
   *
   * - `propertyId`:
   *
   * - `Authorization`: Access Token
   *
   * - `projectRef`:
   *
   * - `modelIn`:
   *
   * @return Success
   */
  UpdateAccountManagerResponse(params: PropertyService.UpdateAccountManagerParams): __Observable<__StrictHttpResponse<IActionResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.projectRef != null) __params = __params.set('projectRef', params.projectRef.toString());
    __body = params.modelIn;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/property/${encodeURIComponent(params.propertyId)}/accountManger`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IActionResult>;
      })
    );
  }
  /**
   * @param params The `PropertyService.UpdateAccountManagerParams` containing the following parameters:
   *
   * - `propertyId`:
   *
   * - `Authorization`: Access Token
   *
   * - `projectRef`:
   *
   * - `modelIn`:
   *
   * @return Success
   */
  UpdateAccountManager(params: PropertyService.UpdateAccountManagerParams): __Observable<IActionResult> {
    return this.UpdateAccountManagerResponse(params).pipe(
      __map(_r => _r.body as IActionResult)
    );
  }

  /**
   * @param params The `PropertyService.UpdateAccountManagersParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  UpdateAccountManagersResponse(params: PropertyService.UpdateAccountManagersParams): __Observable<__StrictHttpResponse<IActionResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.modelIn;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/property/accountMangers/${encodeURIComponent(params.userId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IActionResult>;
      })
    );
  }
  /**
   * @param params The `PropertyService.UpdateAccountManagersParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  UpdateAccountManagers(params: PropertyService.UpdateAccountManagersParams): __Observable<IActionResult> {
    return this.UpdateAccountManagersResponse(params).pipe(
      __map(_r => _r.body as IActionResult)
    );
  }

  /**
   * @param params The `PropertyService.UnassignAccountManagersParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  UnassignAccountManagersResponse(params: PropertyService.UnassignAccountManagersParams): __Observable<__StrictHttpResponse<IActionResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.modelIn;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/property/accountMangers/unassign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IActionResult>;
      })
    );
  }
  /**
   * @param params The `PropertyService.UnassignAccountManagersParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `modelIn`:
   *
   * @return Success
   */
  UnassignAccountManagers(params: PropertyService.UnassignAccountManagersParams): __Observable<IActionResult> {
    return this.UnassignAccountManagersResponse(params).pipe(
      __map(_r => _r.body as IActionResult)
    );
  }
}

module PropertyService {

  /**
   * Parameters for GetPropertiesPagedAsync
   */
  export interface GetPropertiesPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    propertyName?: string;
    projectRef?: string;
    keywords?: string;
  }

  /**
   * Parameters for GetPropertyNamesPagedAsync
   */
  export interface GetPropertyNamesPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    propertyName?: string;
    projectRef?: string;
    keywords?: string;
  }

  /**
   * Parameters for GetPropertyByIdAsync
   */
  export interface GetPropertyByIdAsyncParams {
    propertyId: number;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for GetPropertyByProjectRefAsync
   */
  export interface GetPropertyByProjectRefAsyncParams {
    projectRef: string;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for AddDocumentToProperty
   */
  export interface AddDocumentToPropertyParams {
    propertyId: number;

    /**
     * Access Token
     */
    Authorization: string;
    supplier?: string;
    file0?: Blob;
    expiryDate?: string;
    description?: string;
  }

  /**
   * Parameters for GetPropertyDocumentsPagedAsync
   */
  export interface GetPropertyDocumentsPagedAsyncParams {
    projectRef: string;
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for GetPropertyNotesAsync
   */
  export interface GetPropertyNotesAsyncParams {
    propertyId: number;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for CreatePropertyNoteAsync
   */
  export interface CreatePropertyNoteAsyncParams {

    /**
     * Access Token
     */
    Authorization: string;
    modelIn?: PropertyNoteModelIn;
  }

  /**
   * Parameters for UpdateAccountManager
   */
  export interface UpdateAccountManagerParams {
    propertyId: string;

    /**
     * Access Token
     */
    Authorization: string;
    projectRef?: string;
    modelIn?: UpdatePropertyAccountManagerModelIn;
  }

  /**
   * Parameters for UpdateAccountManagers
   */
  export interface UpdateAccountManagersParams {
    userId: string;

    /**
     * Access Token
     */
    Authorization: string;
    modelIn?: UpdatePropertyAccountManagersModelIn;
  }

  /**
   * Parameters for UnassignAccountManagers
   */
  export interface UnassignAccountManagersParams {

    /**
     * Access Token
     */
    Authorization: string;
    modelIn?: UpdatePropertyAccountManagersModelIn;
  }
}

export { PropertyService }
