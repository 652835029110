/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { JobsPagedModelOut } from '../models/jobs-paged-model-out';
import { JobModelOut } from '../models/job-model-out';
import { DropdownModelOutString } from '../models/dropdown-model-out-string';
import { JobDocumentsPagedModelOut } from '../models/job-documents-paged-model-out';
@Injectable({
  providedIn: 'root',
})
class JobService extends __BaseService {
  static readonly GetJobsPagedAsyncPath = '/api/job/paged';
  static readonly GetJobByIdAsyncPath = '/api/job/{jobId}';
  static readonly GetJobByJobRefAsyncPath = '/api/job/getJob';
  static readonly GetJobStatusesAsyncPath = '/api/job/statuses';
  static readonly GetJobTypesAsyncPath = '/api/job/types';
  static readonly GetJobDocumentsPagedAsyncPath = '/api/job/{jobId}/documents';
  static readonly AddDocumentToJobsPath = '/api/job/fileUpload/{jobId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Jobs Paged
   * @param params The `JobService.GetJobsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `startDate`:
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `jobTypeRef`:
   *
   * - `jobStatusRef`:
   *
   * - `jobRef`:
   *
   * - `jobId`:
   *
   * - `endDate`:
   *
   * - `assets`:
   *
   * - `assetRef`:
   *
   * @return Success
   */
  GetJobsPagedAsyncResponse(params: JobService.GetJobsPagedAsyncParams): __Observable<__StrictHttpResponse<JobsPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.propertyName != null) __params = __params.set('propertyName', params.propertyName.toString());
    if (params.projectRef != null) __params = __params.set('projectRef', params.projectRef.toString());
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString());
    if (params.jobTypeRef != null) __params = __params.set('jobTypeRef', params.jobTypeRef.toString());
    if (params.jobStatusRef != null) __params = __params.set('jobStatusRef', params.jobStatusRef.toString());
    if (params.jobRef != null) __params = __params.set('jobRef', params.jobRef.toString());
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.assets != null) __params = __params.set('assets', params.assets.toString());
    if (params.assetRef != null) __params = __params.set('assetRef', params.assetRef.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/job/paged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobsPagedModelOut>;
      })
    );
  }
  /**
   * Get Jobs Paged
   * @param params The `JobService.GetJobsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `Authorization`: Access Token
   *
   * - `startDate`:
   *
   * - `propertyName`:
   *
   * - `projectRef`:
   *
   * - `keywords`:
   *
   * - `jobTypeRef`:
   *
   * - `jobStatusRef`:
   *
   * - `jobRef`:
   *
   * - `jobId`:
   *
   * - `endDate`:
   *
   * - `assets`:
   *
   * - `assetRef`:
   *
   * @return Success
   */
  GetJobsPagedAsync(params: JobService.GetJobsPagedAsyncParams): __Observable<JobsPagedModelOut> {
    return this.GetJobsPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as JobsPagedModelOut)
    );
  }

  /**
   * Get Job by Id
   * @param params The `JobService.GetJobByIdAsyncParams` containing the following parameters:
   *
   * - `jobId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetJobByIdAsyncResponse(params: JobService.GetJobByIdAsyncParams): __Observable<__StrictHttpResponse<JobModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/job/${encodeURIComponent(params.jobId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobModelOut>;
      })
    );
  }
  /**
   * Get Job by Id
   * @param params The `JobService.GetJobByIdAsyncParams` containing the following parameters:
   *
   * - `jobId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetJobByIdAsync(params: JobService.GetJobByIdAsyncParams): __Observable<JobModelOut> {
    return this.GetJobByIdAsyncResponse(params).pipe(
      __map(_r => _r.body as JobModelOut)
    );
  }

  /**
   * Get Job by JobRef
   * @param params The `JobService.GetJobByJobRefAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `jobRef`:
   *
   * @return Success
   */
  GetJobByJobRefAsyncResponse(params: JobService.GetJobByJobRefAsyncParams): __Observable<__StrictHttpResponse<JobModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.jobRef != null) __params = __params.set('jobRef', params.jobRef.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/job/getJob`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobModelOut>;
      })
    );
  }
  /**
   * Get Job by JobRef
   * @param params The `JobService.GetJobByJobRefAsyncParams` containing the following parameters:
   *
   * - `Authorization`: Access Token
   *
   * - `jobRef`:
   *
   * @return Success
   */
  GetJobByJobRefAsync(params: JobService.GetJobByJobRefAsyncParams): __Observable<JobModelOut> {
    return this.GetJobByJobRefAsyncResponse(params).pipe(
      __map(_r => _r.body as JobModelOut)
    );
  }

  /**
   * Get Job Statuses
   * @param Authorization Access Token
   * @return Success
   */
  GetJobStatusesAsyncResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<DropdownModelOutString>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/job/statuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DropdownModelOutString>>;
      })
    );
  }
  /**
   * Get Job Statuses
   * @param Authorization Access Token
   * @return Success
   */
  GetJobStatusesAsync(Authorization: string): __Observable<Array<DropdownModelOutString>> {
    return this.GetJobStatusesAsyncResponse(Authorization).pipe(
      __map(_r => _r.body as Array<DropdownModelOutString>)
    );
  }

  /**
   * Get Job Types
   * @param Authorization Access Token
   * @return Success
   */
  GetJobTypesAsyncResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<DropdownModelOutString>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/job/types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DropdownModelOutString>>;
      })
    );
  }
  /**
   * Get Job Types
   * @param Authorization Access Token
   * @return Success
   */
  GetJobTypesAsync(Authorization: string): __Observable<Array<DropdownModelOutString>> {
    return this.GetJobTypesAsyncResponse(Authorization).pipe(
      __map(_r => _r.body as Array<DropdownModelOutString>)
    );
  }

  /**
   * @param params The `JobService.GetJobDocumentsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `jobId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetJobDocumentsPagedAsyncResponse(params: JobService.GetJobDocumentsPagedAsyncParams): __Observable<__StrictHttpResponse<JobDocumentsPagedModelOut>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/job/${encodeURIComponent(params.jobId)}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobDocumentsPagedModelOut>;
      })
    );
  }
  /**
   * @param params The `JobService.GetJobDocumentsPagedAsyncParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * - `jobId`:
   *
   * - `Authorization`: Access Token
   *
   * @return Success
   */
  GetJobDocumentsPagedAsync(params: JobService.GetJobDocumentsPagedAsyncParams): __Observable<JobDocumentsPagedModelOut> {
    return this.GetJobDocumentsPagedAsyncResponse(params).pipe(
      __map(_r => _r.body as JobDocumentsPagedModelOut)
    );
  }

  /**
   * @param params The `JobService.AddDocumentToJobsParams` containing the following parameters:
   *
   * - `jobId`:
   *
   * - `Authorization`: Access Token
   *
   * - `supplier`:
   *
   * - `file0`:
   *
   * - `expiryDate`:
   *
   * - `description`:
   *
   * @return Success
   */
  AddDocumentToJobsResponse(params: JobService.AddDocumentToJobsParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.supplier != null) { __formData.append('supplier', params.supplier as string | Blob);}
    if (params.file0 != null) { __formData.append('file0', params.file0 as string | Blob);}
    if (params.expiryDate != null) { __formData.append('expiryDate', params.expiryDate as string | Blob);}
    if (params.description != null) { __formData.append('description', params.description as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/job/fileUpload/${encodeURIComponent(params.jobId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `JobService.AddDocumentToJobsParams` containing the following parameters:
   *
   * - `jobId`:
   *
   * - `Authorization`: Access Token
   *
   * - `supplier`:
   *
   * - `file0`:
   *
   * - `expiryDate`:
   *
   * - `description`:
   *
   * @return Success
   */
  AddDocumentToJobs(params: JobService.AddDocumentToJobsParams): __Observable<string> {
    return this.AddDocumentToJobsResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module JobService {

  /**
   * Parameters for GetJobsPagedAsync
   */
  export interface GetJobsPagedAsyncParams {
    pageSize: number;
    pageNo: number;

    /**
     * Access Token
     */
    Authorization: string;
    startDate?: string;
    propertyName?: string;
    projectRef?: string;
    keywords?: string;
    jobTypeRef?: string;
    jobStatusRef?: string;
    jobRef?: string;
    jobId?: string;
    endDate?: string;
    assets?: string;
    assetRef?: string;
  }

  /**
   * Parameters for GetJobByIdAsync
   */
  export interface GetJobByIdAsyncParams {
    jobId: number;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for GetJobByJobRefAsync
   */
  export interface GetJobByJobRefAsyncParams {

    /**
     * Access Token
     */
    Authorization: string;
    jobRef?: string;
  }

  /**
   * Parameters for GetJobDocumentsPagedAsync
   */
  export interface GetJobDocumentsPagedAsyncParams {
    pageSize: number;
    pageNo: number;
    jobId: number;

    /**
     * Access Token
     */
    Authorization: string;
  }

  /**
   * Parameters for AddDocumentToJobs
   */
  export interface AddDocumentToJobsParams {
    jobId: number;

    /**
     * Access Token
     */
    Authorization: string;
    supplier?: string;
    file0?: Blob;
    expiryDate?: string;
    description?: string;
  }
}

export { JobService }
