import { Component, OnInit, Input } from '@angular/core';
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../auth/services/auth.service';
import { SubContractorFilters } from './models/subDocsFilters';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { SubContractorService } from '../../../app/api/services/sub-contractor.service';
import * as FileSaver from 'file-saver';
import { PropertyFilterService } from '../../services/property-filter-service';

@Component({
  selector: 'app-subcontractor-documents',
  templateUrl: './subcontractor-documents.component.html',
  styleUrls: ['./subcontractor-documents.component.scss']
})
export class SubcontractorDocumentsComponent implements OnInit {
  @Input() documentId = 0;
  selectedAll: any;
  fileURL = 'attachment.url';
  subContractorDocuments = [];
  faFile = faFile;
  faDownload = faDownload;
  adminAccess = false;
  selectedDocuments = [];
  allChecked = false;
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 20;
  filters: SubContractorFilters = {
    documentName: '',
    subContractorName: '',
    clientName: null,
    propertyName: '',
    expiryDate: null
  };
  documentName = '';
  subContractorName = '';
  clientName = '';
  propertyName = '';
  expiryDate = '';

  constructor(
    private authService: AuthService,
    private subContractorDocumentService: SubContractorService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private propertyFiltersService: PropertyFilterService
  ) {
    this.adminAccess = this.authService.roleMatch(['Administrator', 'AllAccounts']);
  }

  ngOnInit() {
    this.spinner.show();
  }

  filter(filters: SubContractorFilters) {
    this.filters = filters;
    this.loadAttachments();
  }

  loadAttachments() {
    const params: SubContractorService.GetSubContractorDocumentsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      subContractorName: this.filters.subContractorName,
      clientName: this.filters.clientName,
      siteName: this.filters.propertyName,
      projectRef: this.propertyFiltersService.getPropertyRef,
      documentName: this.filters.documentName,
      expiryDate: this.filters.expiryDate != null ? this.filters.expiryDate.toDateString() : null
    };
    this.subContractorDocumentService.GetSubContractorDocumentsPagedAsync(params).subscribe(response => {
      this.totalRecordCount = response.totalRecordCount;
      this.subContractorDocuments = response.items;
      this.spinner.hide();
    });
  }

  pageChanged(page) {
    this.currentPage = page;
    this.loadAttachments();
  }

  isPDF(attachmentURL: string) {
    return attachmentURL.includes('.pdf');
  }


  selectAll() {
    for (let i = 0; i < this.subContractorDocuments.length; i++) {
      if (!this.subContractorDocuments[i].selected) {
        this.subContractorDocuments[i].selected = true;
        this.selectedDocuments.push(this.subContractorDocuments[i]);
      } else {
        this.subContractorDocuments[i].selected = false;
        const index = this.selectedDocuments.indexOf(this.subContractorDocuments[i]);
        this.selectedDocuments.splice(index, 1);
      }
    }
  }

  isChecked(attachment, event) {
    const index = this.selectedDocuments.indexOf(attachment);
    if (index === -1) {
      if (event.target.checked) {
        this.selectedDocuments.push(attachment);
      }
    } else {
      this.selectedDocuments.splice(index, 1);
    }
  }

  download() {
    this.selectedDocuments.forEach(function (doc) {
      if (doc.selected) {
        FileSaver.saveAs(doc.url, doc.name);
      }
    });
    this.selectedDocuments = [];
    this.allChecked = false;
    for (let i = 0; i < this.subContractorDocuments.length; i++) {
      this.subContractorDocuments[i].selected = false;
    }
    this.toastr.success('Download Successful');
  }
}
