import { AllsopBaseForm } from '../../common/allsopBaseForm';
import { DocumentFilters } from './models/documentFilters';

export class DocumentFiltersForm extends AllsopBaseForm {
    constructor() {
        super();

        this.setUpForm();
    }
    public setUpForm() {
        const model: DocumentFilters = {
            keywords: '',
            propertyName: '',
            dateOfUpload: null,
            supplier: '',
            documentName: '',
            description: '',
            expiryDate: null
        };

        this.setupForm(model);
    }

    public setupForm(value: DocumentFilters) {
        this.resetForm();
        this.formGroup = this.fb.group<DocumentFilters>({
            keywords: this.getControl(value.keywords, false),
            propertyName: this.getControl(value.propertyName, false),
            dateOfUpload: this.getControl(value.dateOfUpload, false),
            supplier: this.getControl(value.supplier, false),
            documentName: this.getControl(value.documentName, false),
            description: this.getControl(value.description, false),
            expiryDate: this.getControl(value.expiryDate, false),
        },
        );
    }
}


