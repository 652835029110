import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { faPlusSquare  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ticket-add-button',
  templateUrl: './ticket-add-button.component.html',
  styleUrls: ['./ticket-add-button.component.scss']
})
export class TicketAddButtonComponent implements OnInit {
  faPlusSquare = faPlusSquare;
  @Output() click: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
