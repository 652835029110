import { Injectable} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';

@Injectable({
    providedIn: 'root'
  })
  export class DeleteModalService {
    bsModalRef: BsModalRef;

    constructor(public modalService: BsModalService) { }

    deleteUser(userId: string) {
        const initialState = {
            userId: userId,
        };
       this.modalService.show(DeleteModalComponent, { initialState: initialState, class: 'modal-lg', ignoreBackdropClick: true });
      }
  }
