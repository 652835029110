import { AllsopBaseForm } from '../../../common/allsopBaseForm';
import { AccountService } from '../../../api/services/account.service';
import { RegisterModelIn } from '../../../api/models/register-model-in';

export class CreateUserForm extends AllsopBaseForm {

  constructor() {
    super();
    this.setUpForm();
}
  public setUpForm() {
    this.resetForm();

    this.formGroup = this.fb.group<RegisterModelIn>({
      firstName: this.getControl(''),
      lastName: this.getControl(''),
      roleId: this.getControl(''),
      analysisCode: this.getControl(null, false),
      username: this.getControl(''),
      password: this.getControl(''),
      confirmPassword: this.getControl(''),
      email: this.getEmailControl(''),
      reportGroupId: this.getControl('', false)
    });
  }

  getSaveModel(): AccountService.CreateUserAsyncParams {
    const value: RegisterModelIn = this.formGroup.value;
    return {
      Authorization: '',
      model: {
        firstName: value.firstName,
        lastName: value.lastName,
        roleId: value.roleId,
        analysisCode: value.analysisCode,
        username: value.username,
        password: value.password,
        confirmPassword: value.confirmPassword,
        email: value.email,
        reportGroupId: value.reportGroupId
      }
    };
  }
}
