import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-view-button',
  templateUrl: './table-view-button.component.html',
  styleUrls: ['./table-view-button.component.scss']
})
export class TableViewButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
