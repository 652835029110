import { Component, OnInit } from '@angular/core';
import { AssetModelOut } from '../../api/models/asset-model-out';
import { NavigationService } from '../../services/navigation.service';
import { AssetService } from '../../api/services/asset.service';
import { faFile, faCamera, faBriefcase, faCheck, faEdit, faTimes, faFlag } from '@fortawesome/free-solid-svg-icons';
import { AlertsService } from '../../services/alert.service';
import { AuthService } from '../../auth/services/auth.service';
import { AssetFilters } from './models/asset-filters';
import { AssetModalService } from '../assets/asset.modal.services';
import { AssetLifecycleModel } from './services/interfaces/assetLifecycleModel';
import * as FileSaver from 'file-saver';
import { PropertyFilterService } from '../../services/property-filter-service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {
  showPropertyRefFilter = true;
  assets: Array<AssetLifecycleModel>;
  faFlag = faFlag;
  totalRecordCount = 0;
  currentPage = 1;
  pageSize = 100;
  name = '';
  reference = '';
  hide = true;
  filters: AssetFilters = {
    keywords: '',
    projectRef: '',
    completedDate: null,
    assetCategory: '',
  };
  constructor(
    private assetService: AssetService,
    private alertsService: AlertsService,
    private modalService: AssetModalService,
    private propertyFilterService: PropertyFilterService
  ) { }

  ngOnInit(): void {
    this.hideGetAll();
  }
  resetFilters() {
    this.name = '';
    this.loadData();
    this.hideGetAll();
  }
  filter(filters: AssetFilters) {
    this.filters = filters;
    this.loadData();
    this.hideGetAll();
  }
  loadData() {
    this.pageSize = 100;
    const params: AssetService.GetAssetsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      keywords: this.filters.keywords,
      projectRef: this.propertyFilterService.getPropertyRef,
      completedDate: this.filters.completedDate != null ? this.filters.completedDate.toDateString() : null,
      assetCategory: this.filters.assetCategory,
    };
    this.assetService.GetAssetsPagedAsync(params).subscribe(response => {
      this.totalRecordCount = response.totalRecordCount;
      this.assets = response.items;
      for (let i = 0; i < this.assets.length; i++) {
        this.assets[i].selected = false;
      }
    });
  }

  hideGetAll() {
    if (this.propertyFilterService.getPropertyRef !== '' && this.propertyFilterService.getPropertyRef != null) {
      this.hide = false;
    }
  }

  getAll() {
    this.pageSize = 1000;
    const params: AssetService.GetAssetsPagedAsyncParams = {
      Authorization: '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      keywords: this.filters.keywords,
      projectRef: this.propertyFilterService.getPropertyRef,
      completedDate: this.filters.completedDate != null ? this.filters.completedDate.toDateString() : null,
      assetCategory: this.filters.assetCategory,
    };
    this.assetService.GetAssetsPagedAsync(params).subscribe(response => {
      this.totalRecordCount = response.totalRecordCount;
      this.assets = response.items;
      for (let i = 0; i < this.assets.length; i++) {
        this.assets[i].selected = false;
      }
    });
  }

  selectAll(event) {
    let assetsToExport = [];
    for (let i = 0; i < this.assets.length; i++) {
      if (event.target.checked) {
        this.assets[i].selected = true;
        assetsToExport.push(this.assets[i].id);
      } else {
        assetsToExport = [];
        this.assets[i].selected = false;
        const index = assetsToExport.indexOf(this.assets[i].id);
        assetsToExport.splice(index, 1);
      }
    }
  }

  populateCSVData() {
    const assetsToExport = [];
    this.assets.forEach(function (inv) {
      if (inv.selected) {
        assetsToExport.push(inv);
      }
    });

    if (assetsToExport.length === 0) {
      this.alertsService.Warning('Please select one or more assets');
    } else {
      this.giveCSVDetails(assetsToExport);
    }
  }

  giveCSVDetails(assetsToExport: Array<AssetModelOut>) {
    const assets: AssetService.GenerateCsvReportParams = {
      Authorization: '',
      models: assetsToExport
    };
    this.assetService.GenerateCsvReport(assets).subscribe(response => {
      const blob: any = new Blob([response],
        { type: 'text/plain; charset=utf-8' });
      const dateString = (new Date()).toISOString().replace(/[-T:Z.]/g, '').substring(0, 14);
      const fileName = 'AssetLifecycleReport' + dateString + '.csv';
      FileSaver.saveAs(blob, fileName);
      this.alertsService.success('Export Completed');
      this.loadData();
    });
  }

  viewAssetDetails(id: number) {
    this.modalService.viewAssetDetails(id);
  }
  pageChanged(page) {
    this.currentPage = page;
    this.loadData();
  }
}
