import { Injectable} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssetDetailsComponent } from './asset-details/asset-details.component';

@Injectable({
    providedIn: 'root'
  })
  export class AssetModalService {
    bsModalRef: BsModalRef;

    constructor(public modalService: BsModalService) { }

    viewAssetDetails(assetId: number) {
        const initialState = {
            assetId: assetId,
        };
       this.modalService.show(AssetDetailsComponent, { initialState: initialState, class: 'modal-lg', ignoreBackdropClick: true });
      }
  }
